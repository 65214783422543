import React from 'react';
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts';

export interface BarDataInterface {
    name?: string;
    patients?: number;
    Assigned?: number;
    Unassigned?: number;
    Breached?: number;
    Unacknowledged?: number
    Responded?: number;
    readings?: number;

}

export type CustomBarChartProps= {
children?: JSX.Element|JSX.Element[];
width?: number;
height?: number;
data: BarDataInterface[];
chart: string;  
view?: string;
}
export const StyledBarChart: React.FC<CustomBarChartProps> = ({data, chart, view})=>{
    let bar1 = <></>
    let bar2 = <></>
    if(chart === "overview"){
        if(view === 'breaches'){
            bar1 = <Bar dataKey="Breached" fill="#8884d8" />;
            bar2 = <Bar dataKey="Responded" fill="#82ca9d" />;
        }else if(view === 'admitionStatus'){
            bar1 = <Bar dataKey="Admited" fill="#8884d8" />;
            bar2 = <Bar dataKey="Remote" fill="#82ca9d" />;
        }
    } else if(chart === 'group'){
        bar1 = <Bar dataKey="patients" fill="#8884d8" />;
    } else if(chart === 'program'){
        bar1 = <Bar dataKey="patients" fill="#8884d8" />;
    } else if(chart === 'coordinator'){
        bar1 = <Bar dataKey="Breached" fill="#8884d8" />;
        bar2 = <Bar dataKey="Responded" fill="#82ca9d" />;
        // total unresolved/resloved breachs
    } else if(chart === 'patient'){
        if(view === 'breaches'){
            bar1 = <Bar dataKey="Breached" fill="#8884d8" />;
            bar2 = <Bar dataKey="Unacknowledged" fill="#82ca9d" />;
        }else if(view === 'readings'){
            bar1 = <Bar dataKey="readings" fill="#8884d8" />;
        }
    } else if(chart === 'devices'){
        bar1 = <Bar dataKey="Assigned" fill="#82ca9d" />;
        bar2 = <Bar dataKey="Unassigned" fill="#8884d8" />;
    }  
    return(
        <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {bar1}
        {bar2}
        </BarChart>
    )
};