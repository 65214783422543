import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import { StyledContainer, StyledHeader, StyledTextField, StyledStack, StyledButtonStack, StyledCheckBoxStack } from './Export.styles';
import HRcontainer from 'components/shared/HRcontainer/HRcontainer';
import { Alert, Checkbox, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import Button from 'components/shared/Button/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { useTranslation } from 'react-i18next';
import Session from "helpers/session";
import { isValidEmail } from "helpers/variables";
import { submitExport, includesInterface} from './Export.logic'

export default function Export(){
    const { t } = useTranslation();
    const startDate = new Date();
    const endDate = new Date();
    const [showSuccess, setSuccess] = useState<string>('');
    const [emailErr, setEmailErr] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);
    const [isAllRecords, setisAllRecords] =useState<boolean>(false);
    const [includes, setIncludes] = useState<includesInterface>({
        type: 'pdf',
        delivery: 'download',
        notifications: false,
        notes: false,
        encounters: false,
        bgl: false,
        bp: false,
        hrt: false,
        spo2: false,
        spir: false,
        temp: false,
        wt: false,
        oneCSV: false
    });

    const [startPickedDate, setStartDate] = React.useState<Date | number | null>(
        startDate.setDate(startDate.getDate() - 30)
    );
    const [endPickedDate, setEndDate] = React.useState<Date | number | null >(
       endDate
    );
    const handleExportChange = (event: any) => {
        if(event.target.value === 'allRecords'){
            const medRecords = {
                bgl: !isAllRecords,
                bp: !isAllRecords,
                hrt: !isAllRecords,
                spo2: !isAllRecords,
                spir: !isAllRecords,
                temp: !isAllRecords,
                wt: !isAllRecords,
            };
            setIncludes({...includes, ...medRecords});
            setisAllRecords(!isAllRecords)
        }else if(event.target.value === 'csv'){
            setIncludes({...includes, type: event.target.value})
        } else if(event.target.value === 'pdf'){
            setIncludes({...includes, type: event.target.value, oneCSV: false})
        } else if(event.target.value === 'download' || event.target.value === 'email'){
            setIncludes({...includes, delivery: event.target.value})
        } else {
            // @ts-ignore
            setIncludes({...includes, [event.target.value]: event.target.checked});
            setisAllRecords(false)
        }
        setChanged(false);
        setSuccess('')
    };

    const handleStartDateChange = (newValue: Date | null)=>{
        setStartDate(newValue);
        setSuccess('')
    };

    const handleEndDateChange = (newValue: Date | null)=>{
        setEndDate(newValue);
        setSuccess('')
    };

    const handleSubmit = ()=>{
        const emailD = (document.getElementById('emailDestination') as HTMLInputElement).value;
        console.log(emailD)
        if(isValidEmail(emailD) || includes.delivery === 'download') {
            submitExport({
                ...includes,
                emailDestination: emailD
            }, startPickedDate, endPickedDate, Session.crackCookieToken('coordinator'), memberID || '');
            setSuccess('success');
            setChanged(true);
            setEmailErr('');
        }else{
            setEmailErr('Please use a valid email address')
        }
    };

    const memberID = window.location.pathname.split('/').pop();

    return (
        <StyledContainer>
            <Grid container>
                <Grid item sm={.8} md={1.4} lg={0.6}/>
                <Grid item sm={11.2} md={10.4} lg={11.3}>
                    <StyledHeader><h2>{t('u-export')}</h2></StyledHeader>
                    <StyledStack spacing={1}>
                        <HRcontainer text={'Selection'}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <MobileDatePicker
                                    label={t('u-start-date')}
                                    inputFormat="MM/dd/yyyy"
                                    value={startPickedDate}
                                    onChange={handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <MobileDatePicker
                                    label={t('u-end-date')}
                                    inputFormat="MM/dd/yyyy"
                                    value={endPickedDate}
                                    onChange={handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <FormControlLabel value={'allRecords'} label={t('s-include-records')} control={<Checkbox onChange={handleExportChange} checked={isAllRecords}/>}/>
                            </Stack>
                        </LocalizationProvider>
                        </HRcontainer>
                        <HRcontainer text={`${t('u-encounters')}, Notes and Notifications`}>
                            <StyledCheckBoxStack direction={'row'}>
                                <FormControlLabel label={t('u-encounters')} value={'encounters'} control={<Checkbox onChange={handleExportChange} checked={ includes.encounters}/>}/>
                                <FormControlLabel label={'Notes'} value={'notes'} control={<Checkbox onChange={handleExportChange} checked={includes.notes}/>}/>
                                <FormControlLabel label={'Notifications'} value={'notifications'} control={<Checkbox onChange={handleExportChange} checked={includes.notifications}/>}/>
                            </StyledCheckBoxStack>
                        </HRcontainer>
                        <HRcontainer text={t('u-measurements')}>
                            <div>
                              <FormControlLabel label={t('u-blood-glucose')} value={"bgl"} control={<Checkbox onChange={handleExportChange} checked={includes.bgl}/>}/>
                              <FormControlLabel label={t('u-blood-pressure')} value={"bp"} control={<Checkbox onChange={handleExportChange} checked={includes.bp}/>}/>
                              <FormControlLabel label={t('u-heart-rate')} value={"hrt"} control={<Checkbox onChange={handleExportChange} checked={includes.hrt}/>}/>
                              <FormControlLabel label={t('u-oxygen-saturation')} value={"spo2"} control={<Checkbox onChange={handleExportChange} checked={includes.spo2}/>}/>
                              {/*<FormControlLabel label={t('u-respiration-rate')} value={"respirationRate"} control={<Checkbox onChange={handleExportChange} checked={includes.respirationRate}/>}/>*/}
                              <FormControlLabel label={t('u-spirometry')} value={"spir"} control={<Checkbox onChange={handleExportChange} checked={includes.spir}/>}/>
                              <FormControlLabel label={t('u-temperature')} value={"temp"} control={<Checkbox onChange={handleExportChange} checked={includes.temp}/>}/>
                              <FormControlLabel label={t('u-weight')} value={"wt"} control={<Checkbox onChange={handleExportChange} checked={includes.wt}/>}/>
                            </div>
                        </HRcontainer>
                        <HRcontainer text={'Format'}>
                            <StyledCheckBoxStack direction={'row'}>
                                <FormControlLabel value='pdf' label={'PDF'} control={<Checkbox onChange={handleExportChange} checked={includes.type === 'pdf'}/>}/>
                                <FormControlLabel value='csv' label={'CSV'} control={<Checkbox onChange={handleExportChange} checked={includes.type === 'csv'}/>}/>
                                <FormControlLabel value='oneCSV' disabled={includes.type !== 'csv'} label={t('s-export-csv')} control={<Checkbox onChange={handleExportChange} checked={includes.oneCSV}/>}/>
                            </StyledCheckBoxStack>
                        </HRcontainer>
                        <HRcontainer text={t('')}>
                            <div>
                                <FormControlLabel value='download' label={'Download'} control={<Checkbox onChange={handleExportChange} checked={includes.delivery === 'download'}/>}/>
                                <FormControlLabel value='email' label={'Email'} control={<Checkbox onChange={handleExportChange} checked={includes.delivery === 'email'}/>}/>
                                <StyledTextField id='emailDestination' disabled={includes.delivery !== 'email'} placeholder={t('u-email')} type='email' error={emailErr.length > 0} helperText={emailErr}/>
                            </div>
                        </HRcontainer>
                    </StyledStack>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
            <h5>{t('s-measurement-types')}</h5>
            <StyledButtonStack>
                <Button variant="success" type='submit' disabled={isDisabled} onClick={handleSubmit}>{t('u-get-report')}</Button>
                {showSuccess === 'success' ? <Alert severity="success">Your Report is being generated.</Alert> : ''}
            </StyledButtonStack>
        </StyledContainer>
    )
}
