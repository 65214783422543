import React, {useContext, useState} from 'react';
import { FormControl, Grid, InputAdornment, OutlinedInput, MenuItem, Alert } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import RadioGenerator from 'components/shared/RadioGenerator/RadioGenerator';
import Button from 'components/shared/Button/Button';
import LocalHospitalOutlinedIcon from '@mui/icons-material/HealthAndSafety';
import { dayArr, monthArr, yearArr } from 'helpers/variables';
import { StyledContainer,
         StyledHeader,
         StyledTextField,
         StyledStack,
         StyledAdornment,
         StyledFormControlLabel,
         StyledTypography,
         StyledHeadTypography,
         StyledMultiLineTypography,
         StyledOutlinedInput,
         StyledTypographyIcon,
         } from './Details.styles';
import {GlobalContext} from "context/Provider";
import API from "api";
import Session from "helpers/session";
import { useTranslation } from 'react-i18next';

interface State {
    inches: number;
    feet: number;
    general: string;
    glucose: string;
  }

  interface DetailProps {
      type: 'coordinator' | 'member';
      action: 'create' | 'edit';
  };

export default function Details(props: DetailProps){
    const { member, memberDispatch } = useContext(GlobalContext);
    const [showSuccess, setSuccess] = useState<string>('');
    const { t } = useTranslation();
    const [values, setValues] = React.useState<State>({
        inches: member?.current.height?.inches || 0,
        feet: member?.current.height?.feet || 0,
        general: member?.current.units?.general || '',
        glucose: member?.current.units?.glucose || ''
      });
    const [isDisabled, setChanged] =useState<boolean>(true);
    const [memberChanges, setMemberChanges] = useState({
        birthDate: member?.current.birthDate || '',
        gender: member?.current.gender || '',
        language: member?.current.language || '',
        condition: member?.current.condition || '',
        units: {
            general: member?.current.units?.general || '',
            glucose: member?.current.units?.glucose || '',
        },
        height: {
            feet: member?.current.height?.feet || '',
            inches: member?.current.height?.inches || '',
        },
        weight: member?.current.weight || 0,
        status: member?.current.status || '',

    });

    const updateMember = () => {
        const memberUpdates = {
            ...memberChanges,
            height: {feet: values.feet, inches: values.inches},
            units: {general: values.general, glucose: values.glucose},
            birthDate: `${year}${month}${day}`
        };
        //TODO: change to toggle between token once provider auth is completed
        let userCall = API.kore.members.upsert(props.action === 'create' ? 'create' : 'edit', memberUpdates, Session.crackCookieToken('coordinator'), member?.current.id);
        setSuccess('success');
        if(props.type === 'coordinator'){
            userCall = API.kore.coordinator.upsert(props.action === 'create' ? 'create' : 'edit', memberUpdates, Session.crackCookieToken('coordinator'), member?.current.id)
        }
        userCall.then(member=>{
            setChanged(true);
            if(props.type === 'member'){
                memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: member})
            }
        })
    };
    const handleMemberChange = (event: any) => {
        setChanged(false);
        setSuccess('');
        setMemberChanges({...memberChanges, [event.target.id]: event.target.value})
    };
    const handleInputChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setChanged(false);
        const value = event.target.value.indexOf('/') ? event.target.value.split('/')[0] : event.target.value;
        setValues({ ...values, [prop]: value });
    };
    const [year, setYear] = useState('Year');
    const handleYearChange = (event: SelectChangeEvent) => {
        setChanged(false);
        setYear(event.target.value as string);
    };

    const [month, setMonth] = useState('Month');
    const handleMonthChange = (event: SelectChangeEvent) => {
        setChanged(false);
        setMonth(event.target.value as string);
    };

    const [day, setDay] = useState('Day');
    const handleDayChange = (event: SelectChangeEvent) => {
        setChanged(false);
        setDay(event.target.value as string);
    };
    return (
        <StyledContainer>
            <StyledHeader><h2>{t('u-management-details')}</h2></StyledHeader>
            <Grid container>
                <Grid item  md={4} lg={3}>
                        <StyledHeadTypography>{t('u-date-of-birth')}</StyledHeadTypography>
                        <StyledTypography>{t('u-gender')}</StyledTypography>
                        <StyledTypography>{t('u-preferred-language')}</StyledTypography>
                        <StyledMultiLineTypography>{t('u-condition')}</StyledMultiLineTypography>
                        <StyledTypography>{t('u-units')}</StyledTypography>
                        <StyledTypography>{t('u-glucose')}</StyledTypography>
                        <StyledTypography>{t('u-height')}</StyledTypography>
                        <StyledTypography>Goal Weight</StyledTypography>
                        <StyledTypographyIcon><LocalHospitalOutlinedIcon/>{t('u-admission-status')}</StyledTypographyIcon>
                </Grid>
                <Grid item xs={6} >
                    <StyledStack spacing={1}>
                        <StyledStack spacing={1} direction="row" >
                            <Select
                                value={month}
                                label="Age"
                                onChange={handleMonthChange}
                                fullWidth
                            >
                                {monthArr.map(value=>{
                                    return <MenuItem key={`export-period-month-${value}`} value={value}>{value}</MenuItem>
                                })}
                            </Select>
                            <Select
                                value={day}
                                placeholder="Age"
                                onChange={handleDayChange}
                                fullWidth
                            >
                                {dayArr.map(value=>{
                                    return <MenuItem key={`export-period-day-${value}`} value={value}>{value}</MenuItem>
                                }).reverse()}
                            </Select>
                            <Select
                                value={year}
                                label="year"
                                onChange={handleYearChange}
                                fullWidth
                            >
                                {yearArr.map(value=>{
                                    return <MenuItem key={`export-period-year-${value}`} value={value}>{value}</MenuItem>
                                })}
                            </Select>
                        </StyledStack>
                        <StyledFormControlLabel
                            label=""
                            control={<RadioGenerator id="gender" inputArray={[t('u-female'), t('u-male'), t('u-other')]} value={memberChanges.gender} onChange={handleMemberChange}/>}
                            labelPlacement="start"
                        />
                        <StyledFormControlLabel
                            label=""
                            control={<StyledTextField id="language" placeholder={t('u-english')} value={memberChanges.language} onChange={handleMemberChange}/>}
                            labelPlacement="start"
                        />
                        <StyledFormControlLabel
                            label=""
                            control={<StyledTextField id="condition" placeholder={t('u-condition')} multiline minRows={4} maxRows={4} value={memberChanges.condition} onChange={handleMemberChange}/>}
                            labelPlacement="start"
                        />
                        <StyledFormControlLabel
                            label=""
                            control={<RadioGenerator id="general" inputArray={[t('u-metric'), t('u-imperial')]} value={values.general} onChange={handleInputChange('general')}/>}
                            labelPlacement="start"
                        />
                        <StyledFormControlLabel
                            label=""
                            control={<RadioGenerator id="glucose"  inputArray={['mg/dL', 'mmol/L']} value={values.glucose === 'mg' ? 'mg/dL' : 'mmol/L'} onChange={handleInputChange('glucose')}/>}
                            labelPlacement="start"
                        />
                        <StyledStack spacing={5} direction="row">
                            <OutlinedInput

                                value={values.feet}
                                type="number"
                                onChange={handleInputChange('feet')}
                                endAdornment={<InputAdornment position="end">ft.</InputAdornment>}
                            />
                            <OutlinedInput
                                value={values.inches}
                                type="number"
                                onChange={handleInputChange('inches')}
                                endAdornment={<InputAdornment position="end" variant="filled">in.</InputAdornment>}
                            />
                        </StyledStack>
                        <StyledOutlinedInput
                            id="weight"
                            value={memberChanges.weight}
                            type="number"
                            onChange={handleMemberChange}
                            endAdornment={<StyledAdornment position="end" >lbs.</StyledAdornment>}
                        />

                        <StyledFormControlLabel

                            label=""
                            control={<RadioGenerator id="status" inputArray={[t('u-none'), t('u-admitted')]} value={memberChanges.status}/>}
                            onChange={handleMemberChange}
                            labelPlacement="start"
                        />

                        <Button variant="success" disabled={isDisabled} onClick={updateMember}>{t('u-save')}</Button>
                        {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                    </StyledStack>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}
