import React, {createContext, useReducer} from 'react';
import { configContext, configInterface } from './initialState/Config'
import { memberContext, memberContextInterface } from './initialState/Member';
import { providerContext, providerContextInterface } from './initialState/Provider';
import { readingContext, ReadingContextInterface } from './initialState/Readings';
import { thresholdContext, ThresholdContextInterface } from './initialState/Threshold';
import configReducer from './reducers/configReducer';
import memberReducer from './reducers/memberReducer';
import readingsReducer from './reducers/readingsReducer';
import providerReducer from './reducers/providerReducer';
import thresholdReducer from "./reducers/thresholdReducer";

import notesReducer from './reducers/notesReducer';
import { notificationsContext, notificationsContextInterface } from './initialState/Notifications';
import notificationsReducer from './reducers/notificationsReducer';
import { EncounterContextInterface, encounterContext } from './initialState/Encounter';
import { notesContext, NotesContextInterface } from './initialState/Notes';
import encounterReducer from './reducers/encounterReducer';
import devicesReducer from './reducers/devicesReducer';
import { DeviceContextInterface, devicesContext, ItemsontextInterface } from './initialState/Devices';

export interface GlobalContextInterface {
    config?: configInterface;
    member?: memberContextInterface;
    provider?: providerContextInterface;
    readings?: ReadingContextInterface;
    threshold?: ThresholdContextInterface;
    notifications?: notificationsContextInterface;
    notes?: NotesContextInterface;
    encounters?: EncounterContextInterface;
    devices?: DeviceContextInterface;
    configDispatch?: any;
    memberDispatch?: any;
    providerDispatch?: any;
    readingsDispatch?: any;
    thresholdDispatch?: any;
    notificationsDispatch?: any;
    notesDispatch?: any;
    encounterDispatch?: any;
    devicesDispatch?: any;
}

interface GlobalProviderProps {
    children: JSX.Element
}

export const GlobalContext = createContext<GlobalContextInterface>({});

const GlobalProvider: React.FC<GlobalProviderProps> = ({children}) => {
    const [config, configDispatch] = useReducer(configReducer, configContext);
    const [member, memberDispatch] = useReducer(memberReducer, memberContext);
    const [readings, readingsDispatch] = useReducer(readingsReducer, readingContext);
    const [provider, providerDispatch] = useReducer(providerReducer, providerContext);
    const [threshold, thresholdDispatch] = useReducer(thresholdReducer, thresholdContext);
    const [notifications, notificationsDispatch] = useReducer(notificationsReducer, notificationsContext);
    const [notes, notesDispatch] = useReducer(notesReducer, notesContext);
    const [encounters, encounterDispatch] = useReducer(encounterReducer, encounterContext);
    const [devices, devicesDispatch] = useReducer( devicesReducer, devicesContext)

    return <GlobalContext.Provider value={{
        config,
        member,
        provider,
        readings,
        threshold,
        notifications,
        notes,
        encounters,
        devices,
        configDispatch,
        memberDispatch,
        providerDispatch,
        readingsDispatch,
        thresholdDispatch,
        notificationsDispatch,
        notesDispatch,
        devicesDispatch
    }}>{children}</GlobalContext.Provider>
};

export default GlobalProvider;
