import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    justifyContent: 'left',
}));

export const StyledHeader = styled('div')(({theme})=>({
    width: '35%'
}));




