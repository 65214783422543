import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface RadioGenProps {
    id?: string;
    inputArray: string[];
    value?: string;
    name?: string;
    onChange?: (event: any)=> void;
    required?: boolean;
}

export default function RadioGenerator(props: RadioGenProps){
    const id = props.id || undefined;
    return (
        <RadioGroup row style={{paddingLeft: '16px'}} value={props.value?.toLowerCase()} name={props.name} onChange={props.onChange}>
            {props.inputArray.map((input)=>{
                return <FormControlLabel control={<Radio id={id} required={props.required}/>} label={input} value={input.toLowerCase()}/>
            })}
        </RadioGroup>
    )
};
