import React from 'react';
import {styled, Palette} from '@mui/material/styles';
import {ButtonBase} from "@mui/material";

type CustomButtonProps= {
    id?: string;
    dataId?: string;
    height?: string;
    width?: string;
    variant: 'primary' | 'secondary' | 'info' | 'warning' | 'success';
    color?: string;
    onClick?: (event: any)=>void
    disabled?: boolean
    children: JSX.Element | string;
    type?: 'submit' | 'button' | 'reset'
    margin?: number;
}

 const Button: React.FC<CustomButtonProps> = ({ color, disabled, height, variant, width, children, onClick, type, dataId, margin })=>{
    const StyledButton = styled('button')(({theme})=>({
        background: theme.palette[variant].light,
        color: color || 'white',
        height: height || theme.spacing(4),
        width: width,
        padding: theme.spacing(1),
        margin: theme.spacing(margin ? margin : 0),
        border: `solid 1px ${theme.palette[variant].light}`,
        cursor: 'pointer',
        '&:disabled': {
            background: theme.palette.common.button.disabled,
            cursor: 'not-allowed',
            borderColor: theme.palette.common.button.disabled,
        }
    }));

    return(
        <StyledButton onClick={onClick} disabled={disabled} type={type} data-id={dataId}>{children}</StyledButton>
    )
};

export default Button;
