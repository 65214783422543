import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import { Box, Grid, MenuItem, Stack } from '@mui/material';
import { StyledButton, StyledContainer, StyledDropdown, StyledLogo, StyledBadge } from './NavBar.styles';
import Button from '../shared/Button/Button';
import logo from '../../assets/L365.png';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from "../../context/Provider";

export default function NavBar (){
    const [language, setLanguage] = useState<string>(`${localStorage.getItem('i18nextLng')}`);
    const { t } = useTranslation();
    const { config, configDispatch, notifications } = useContext(GlobalContext);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setLanguage(event.target.value);
        localStorage.setItem('i18nextLng', `${event.target.value}`);
        window.location.reload();
    };

    const handleLogout = ()=>{
        const name = window.location.pathname.split('/')[1] === 'provider' ? 'life365_provider' : 'life365_session';
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.pathname = '/';
    };

    const location = window.location.pathname.split('/')[1];

    useEffect(()=>{
        const decodedCookie = decodeURIComponent(document.cookie);
        configDispatch({type: 'COORD_IN', payload: decodedCookie.includes('life365_session')});
        configDispatch({type: 'PROVIDER_IN', payload: decodedCookie.includes('life365_provider')});
    }, []);

    return (
        <StyledContainer container>
            <Grid item  xs={1} sm={1}/>
            <Grid item xs={6} sm={6}>
                <StyledLogo src={logo}/>
                <br/>
                {location !== 'provider' && location !== 'admin' && config?.coordLogin && (
                    <div>
                        <StyledButton to="/">{t('u-members')}</StyledButton>
                        <StyledButton to="/notifications">
                            <StyledBadge badgeContent={notifications?.list ? notifications?.list.length : 0} color={"warning"}>
                                {t("u-notifications")}
                            </StyledBadge>
                        </StyledButton>
                    </div>
                    )}
                {location === 'provider' && config?.providerLogin && (
                    <div>
                        <StyledButton to="/provider/coordinators">{t('u-users')}</StyledButton>
                        <StyledButton to="/provider/managers">{t('u-managers')}</StyledButton>
                        <StyledButton to="/provider/reports">{t('u-reports')}</StyledButton>
                        <StyledButton to="/provider/configurations">{t('u-configurations')}</StyledButton>
                        {config?.orderOptions.newMember !== 'none' ? <StyledButton to="/provider/orders">{t('u-orders')}</StyledButton> : ''}
                    </div>
                )}
            </Grid>
            <Grid item sm={1.5} lg={2.5}></Grid>
            <Grid item sm={2.5} lg={1.5}>
                <Stack spacing={2}>
                    <Box pt={1}>
                        <Button variant="primary" onClick={handleLogout}>{t('u-logout')}</Button>
                    </Box>
                    <StyledDropdown
                        value={language}
                        // @ts-ignore
                        onChange={handleChange}
                        displayEmpty
                        size={'small'}
                        variant={'filled'}
                    >
                        <MenuItem value={'en'}>English</MenuItem>
                        <MenuItem value={'es'}>Español</MenuItem>
                        <MenuItem value={'fr'}>Français</MenuItem>
                    </StyledDropdown>
                </Stack>
            </Grid>
            <Grid item sm={1}></Grid>
        </StyledContainer>
    )
}
