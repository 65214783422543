import { t } from "i18next";
import { StyledDiv } from "views/Admin/subviews/FooterLinks/FooterLinks.styles";
import { useTranslation } from 'react-i18next';

export default function Privacy(){
    const { t } = useTranslation();
    // Text has been coded for automatic translation using i18nexus. Nameing conventions for this will be as follows:
    // u-(text)-(text) is for words that all start with a capital letter ie. u-happy-feelings = Happy Feelings
    // s-(text)-(text)-(number) is for a sentence or paragraph that needs indexing. On this page the naming convention is different because there are multiple paragraphs one after another that need an index.
    // The naming convention can be read as follows: s(sentence/paragraph)-h(heading) or l(list item) or p(paragraph)-pp(privacy policy)-1(index)

    return(
        <StyledDiv>
            <h1>{t('s-h-pp-1')}</h1>
            <p>{t('s-p-pp-1')}</p>
            <h2>{t('s-h-pp-2')}</h2>
            <p>
            {t('s-p-pp-2')}
            <ul>
            <li>{t('s-l-pp-1')}</li>
            <li>{t('s-l-pp-2')}</li>
            </ul>
            </p>
            <h2>{t('s-h-pp-3')}</h2>
            <p>
            {t('s-p-pp-3')}
            <ul>
            <li>{t('s-l-pp-3')}</li>
            <li>{t('s-l-pp-4')}</li>
            </ul>
            </p>
            <h2>{t('s-h-pp-4')}</h2>
            <p>{t('s-p-pp-4')}</p>
            <h2>{t('s-h-pp-5')}</h2>
            <p>{t('s-p-pp-5')}</p>
            <h2>{t('s-h-pp-6')}</h2>
            <p>{t('s-p-pp-6')}</p>
            <h2>{t('s-h-pp-7')}</h2>
            <p>
            {t('s-p-pp-7')} <a href="mailto:support@life365.health">support@life365.health</a>{t('s-p-pp-8')}
            </p>
            
        </StyledDiv>
    )
}
