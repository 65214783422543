import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles'
export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  // backgroundColor: 'grey',
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  background: theme.palette.common.background.main,
}));
export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  // background: "grey"
}));
export const StyledTextDetailsLabel = styled("p")(({ theme }) => ({
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  }));
export const StyledTextTimeStamp = styled("p")(({ theme }) => ({
paddingLeft: theme.spacing(15),
margin: theme.spacing(0),
}));
export const StyledTextCoordinatorName = styled("p")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  margin: theme.spacing(0),
}));
