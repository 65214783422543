import React, { useState } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import Button from '../Button/Button';
import theme from 'helpers/mui-theme.helper';
import { exportGenerator } from 'helpers/variables';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { StyledSelectRadio } from 'views/Provider/Subviews/Reports/Reports.styled';

type CustomButtonProps= {
    option: string,
    content: string,
    name: string,
    startDate: Date,
    endDate: Date,
    startChange: any,
    endChange: any,
    updateDate?: any,
    tabChange: any,
}

const ExportSelect: React.FC<CustomButtonProps> = ({ option, content, name, startDate, endDate, startChange, endChange, updateDate, tabChange })=>{
    const { t } = useTranslation();
    return(
    <Stack direction="row">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <MobileDatePicker
                    label={t('u-start-date')}
                    inputFormat="MM/dd/yyyy"
                    value={startDate}
                    onChange={startChange}
                    renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                    label={t('u-end-date')}
                    inputFormat="MM/dd/yyyy"
                    value={endDate}
                    onChange={endChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
        </LocalizationProvider>
        <Button variant='info' margin={1} width={theme.spacing(17)} height={theme.spacing(6.25)} onClick={updateDate}>Update Date Range</Button>
        <FormControl >
            <RadioGroup row >
                <Stack direction='row'>
                    <StyledSelectRadio onChange={tabChange}>
                        <FormControlLabel value="toPDF" control={<Radio />} label="PDF" checked={ option === 'toPDF'}/>
                        <FormControlLabel value="toCSV" control={<Radio />} label="CSV" checked={ option === 'toCSV'}/>
                    </StyledSelectRadio>
                </Stack>
            </RadioGroup>
        </FormControl>
        <Button variant='info' margin={1} width={theme.spacing(15)} height={theme.spacing(6.25)} onClick={() => {exportGenerator(option, content, name)}}>Export</Button>
    </Stack>
    )
};

export default ExportSelect;