import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import moment from 'moment';
import { StyledContainer, StyledHeader } from './History.styles';
import { Grid } from '@mui/material';
import Timeline, { MeasurementProp } from "components/shared/Timeline/Timeline";
import Measurements from "./Measurements/Measurements";
import API from "api";
import Session from "helpers/session";
import { formatReading } from 'helpers/variables';
import { GlobalContext } from "context/Provider";
import { ReadingContextInterface } from "context/initialState/Readings";
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import { useTranslation } from 'react-i18next';

const formatTimeline = (rawReadings: MeasurementProp[])=>{
    let timelineObj: any = {};
    const timelineArray: any[] = [];
    rawReadings.map((reading: MeasurementProp)=>{
        if(timelineObj[moment(`${reading.date.split(',')[0]}, ${reading.year} ${reading.date.split(',')[1]}`).format('MM/DD/YYYY')]) {
            timelineObj[moment(`${reading.date.split(',')[0]}, ${reading.year} ${reading.date.split(',')[1]}`).format('MM/DD/YYYY')].push(reading)
        }else{
            timelineObj[moment(`${reading.date.split(',')[0]}, ${reading.year} ${reading.date.split(',')[1]}`).format('MM/DD/YYYY')] = [reading];
        }
    });
    for(let line in timelineObj){
        timelineArray.push(timelineObj[line])
    }
    return timelineArray
};

export default function History(){
    const [ timeline, setTimeline ] = useState(formatTimeline([]));
    const { memberDispatch } = useContext(GlobalContext);
    const memberID = window.location.pathname.split('/').pop();
    const { t } = useTranslation();

    useEffect(()=>{
        API.kore.readings.getForOne(memberID || '', Session.crackCookieToken('coordinator'))
            .then(readings=>{
                memberDispatch({type: 'UPDATE_CID', payload: readings.cid});
                let processedReadings: MeasurementProp[] = [];
                readings.items.map((line: ReadingContextInterface)=>{
                    processedReadings.push(formatReading(line))
                });
                const sortedReadings = formatTimeline(processedReadings).sort((a, b)=>{return moment(`${a[0].date.split(',')[0]}, ${a[0].year} ${a[0].date.split(',')[1]}`).unix() - moment(`${b[0].date.split(',')[0]}, ${b[0].year} ${b[0].date.split(',')[1]}`).unix()});
                setTimeline(sortedReadings.reverse())
            })
    }, [memberDispatch]);
    const TimeLineView = () => {
        return (
            <StyledContainer>
                <StyledHeader><h2>{t('u-timeline')}</h2></StyledHeader>
                <Grid container>
                        <Grid item  xs={.2}/>
                        <Grid item  xs={11.4}>
                            <EncounterTimer></EncounterTimer>
                        </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1}/>
                    <Grid item xs={10}>
                        {timeline.map(line=>{
                            return <Timeline key={`timeline-component-${line[0].date}`} date={line[0].date} measurements={line} year={line[0].year}/>
                        })}
                    </Grid>
                    <Grid item xs={1}/>
                </Grid>
            </StyledContainer>
        )
    };
    return (
            <Routes>
                <Route path="timeline/:memberId" element={<TimeLineView/>}/>
                <Route path="bloodpressure/*" element={<Measurements type='Blood Pressure'/>}/>
                <Route path="bloodglucose/*" element={<Measurements type='Blood Glucose'/>}/>
                <Route path="heartrate/*" element={<Measurements type='Heart Rate'/>}/>
                <Route path="oxygensaturation/*" element={<Measurements type='Oxygen Saturation'/>}/>
                <Route path="temperature/*" element={<Measurements type='Temperature'/>}/>
                <Route path="weight/*" element={<Measurements type='Weight'/>}/>
                <Route path="spirometry/*" element={<Measurements type='Spirometry'/>}/>
                <Route path="respirationrate/*" element={<Measurements type='Respiration Rate'/>}/>
                <Route path="membernote/*" element={<Measurements type='Member Note'/>}/>
            </Routes>
    )
}
