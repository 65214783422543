import axios, { AxiosResponse } from "axios";
import {mel_url} from './index';
import {
    basicOptionsInterface,
    brandingOptionsInterface,
    configInterface,
    orderingOptionsInterface,
    regOptionsInterface
} from 'context/initialState/Config';

export default {
    adminLogin: (password: string)=>{
        const config = {
            method: 'POST',
            url: `${mel_url}/api/configure/admin/login`,
            data: {password: password},
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config).then(isAuthenticated=>{
            return isAuthenticated
        }).catch(err=>{throw err});
    },
    getConfigurations: ()=>{
        const config = {
            method: 'GET',
            url: `${mel_url}/api/configure`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((configResponse: AxiosResponse)=>{
                return configResponse.data;
            }).catch(err=>{throw err});
    },
    updateConfigurations: (configChanges: configInterface)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/configure`,
            data: configChanges,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((configResponse: AxiosResponse)=>{
                return configResponse.data;
            }).catch(err=>{throw err});
    },
    updateBasicConfig: (basicChanges: basicOptionsInterface)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/configure/basic`,
            data: basicChanges,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((basics: AxiosResponse)=>{
                return JSON.parse(basics.config.data)
            }).catch(err=>{throw err})
    },
    updateBrandingConfig: (basicChanges: brandingOptionsInterface)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/configure/branding`,
            data: basicChanges,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((branding: AxiosResponse)=>{
                return JSON.parse(branding.config.data)
            }).catch(err=>{throw err})
    },
    updateOrderingConfig: (basicChanges: orderingOptionsInterface)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/configure/ordering`,
            data: basicChanges,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((orders: AxiosResponse)=>{
                return JSON.parse(orders.config.data)
            }).catch(err=>{throw err})
    },
    updateRegConfig: (regChanges: regOptionsInterface)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/configure/registrations`,
            data: regChanges,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((regs: AxiosResponse)=>{
                return JSON.parse(regs.config.data)
            }).catch(err=>{throw err})
    }
}
