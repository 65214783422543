import React, { useContext, useEffect, useState } from 'react';
import { ButtonGroup, TextField, Stack, Grid } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Button from "components/shared/Button/Button";
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { GlobalContext } from "context/Provider";
import API from "api";
import Session from "helpers/session";
import { associateReadingType } from 'helpers/variables';
import { ReadingContextInterface } from 'context/initialState/Readings';
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import { useTranslation } from 'react-i18next';
import { StyledResponsiveContainer } from './Chart.styles';

interface ChartProps {
    type: string;
}

interface GraphInterface {
    measuredAt: string | undefined;
    systolic?: number | undefined;
    diastolic?: number | undefined;
    glucose?: number | undefined;
    spo2?: number | undefined;
    pulse?: number | undefined;
    temp?: number | undefined;
    weight?: number | undefined;
}

const formatChart = (readings: ReadingContextInterface[], type: string): GraphInterface[]=>{
    let graphData: GraphInterface[] = [];
    readings.map(reading=>{
        switch(type){
            case 'Blood Pressure':
                graphData.push({measuredAt: reading.measuredAt, systolic: reading.metrics?.systolic, diastolic: reading.metrics?.diastolic});
                break;
            case 'Blood Glucose':
                graphData.push({measuredAt: reading.measuredAt, glucose: reading.metrics?.glucose});
                break;
            case 'Oxygen Saturation':
                graphData.push({measuredAt: reading.measuredAt, spo2: reading.metrics?.spo2});
                break;
            case 'Temperature':
                graphData.push({measuredAt: reading.measuredAt, temp: reading.metrics?.temp});
                break;
            case 'Weight':
                graphData.push({measuredAt: reading.measuredAt, weight: reading.metrics?.weight});
                break;
            case 'Spirometry':
                graphData.push({measuredAt: reading.measuredAt, systolic: reading.metrics?.systolic, diastolic: reading.metrics?.diastolic});
                break;
        }
    });
    return graphData;
};

const formatLine = (type: string)=>{
    switch(type){
        case 'Blood Pressure':
            return (
                <>
                    <Line type="monotone" dataKey="systolic" stroke="#8884d8" />
                    <Line type="monotone" dataKey="diastolic" stroke="#8884d8" activeDot={{ r: 8 }} />
                </>
            );
        case 'Blood Glucose':
            return <Line type="monotone" dataKey="glucose" stroke="#8884d8" />;
        case 'Oxygen Saturation':
            return <Line type="monotone" dataKey="spo2" stroke="#8884d8" />;
        case 'Temperature':
            return <Line type="monotone" dataKey="temp" stroke="#8884d8" />;
        case 'Weight':
            return <Line type="monotone" dataKey="weight" stroke="#8884d8" />;
        case 'Heart Rate':
            return <Line type="monotone" dataKey="pulse" stroke="#8884d8" />;
        case 'Spirometry':
            return <Line type="monotone" dataKey="spir" stroke="#8884d8" />;
    }
}

export default function Chart(props: ChartProps){
    const [pickedDate, setDate] = React.useState<Date | null>(
        new Date(),
    );
    const [graphData, setGraphData] = useState<GraphInterface[]>([]);
    const { member } = useContext(GlobalContext);
    const { t } = useTranslation();
    const width = window.innerWidth * 0.62;

    useEffect(()=>{
        API.kore.readings.getForOne(member?.current.id || '', Session.crackCookieToken('coordinator'), associateReadingType(props.type))
            .then(readings=>{
                setGraphData(formatChart(readings.items, props.type))
            })
    }, [props.type]);

    const handleDateChange = (newValue: Date | null)=>{
        setDate(newValue);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container>
                <Grid item xs={.6}/>
                <Grid item xs={11}>
                    <EncounterTimer></EncounterTimer>
                </Grid>
            </Grid>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                <ButtonGroup>
                    <Button variant="info">{t('u-year')}</Button>
                    <Button variant="info">{t('u-month')}</Button>
                    <Button variant="info">{t('u-week')}</Button>
                    <Button variant="info">{t('u-days')}</Button>
                </ButtonGroup>
                <MobileDatePicker
                    label={t('u-median-date')}
                    inputFormat="MM/dd/yyyy"
                    value={pickedDate}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Stack>
            <StyledResponsiveContainer width={width} height={400}>
                <LineChart data={graphData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="measuredAt" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {formatLine(props.type)}
                </LineChart>
            </StyledResponsiveContainer>
        </LocalizationProvider>
    )
}
