import axios, { AxiosResponse } from "axios";
import {mel_url} from './index';

interface shipmentDetailsInterface {
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
}

export interface orderInterface {
    _id?: string;
    type?: string;
    fulfilled?: boolean;
    issuedOn?: string;
    shippedOn?: string;
    organization: string;
    delivery: string;
    issuedBy: string;
    issuedEmail: string;
    sendersAddress: shipmentDetailsInterface,
    recipient: shipmentDetailsInterface,
    manifest: {
        devices?: {
            bpm?: number;
            bgm?: number;
            scale?: number;
            pulseOx?: number;
            thermometer?: number;
            spirometer?: number;
        },
        hubs?: {
            stel?: number;
            smart?: number;
        },
        accessories?: [string],
        documentation?: [string],
        totalItems?: number
    }
}

export default {
    getAll: (param?: string)=>{
        const paginationParameters = ()=>{
            let paginationQuery = `${param ? `?${param}` : ''}`;
            return paginationQuery
        };
        const config = {
            method: 'GET',
            url: `${mel_url}/api/order${paginationParameters()}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config).then((retAll: AxiosResponse)=>{
            return retAll.data
        }).catch(err=>{throw err});
    },
    getOne: (orderId: string)=>{
        const config = {
            method: 'GET',
            url: `${mel_url}/api/order/${orderId}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config).then((retOne: AxiosResponse)=>{
            return retOne
        }).catch(err=>{throw err});
    },
    create: (order: orderInterface, isBulk?: boolean)=>{
        if(isBulk){
            order.type = 'bulk';
        }
        const config = {
            method: 'POST',
            url: `${mel_url}/api/order`,
            data: order,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config).then((created: AxiosResponse)=>{
            return created
        }).catch(err=>{throw err});
    },
    update: (updates: object, orderId: string)=>{
        const config = {
            method: 'POST',
            url: `${mel_url}/api/order/${orderId}`,
            data: updates,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config).then((updated: AxiosResponse)=>{
            return updated
        }).catch(err=>{throw err});
    }
}
