function providerReducer(state: any, {type, payload}: any){
    switch (type) {
        case 'UPDATE_COORD_LIST':
            return {...state, coordinatorList: payload};
        case 'UPDATE_MEMBER_LIST':
            return {...state, memberList: payload};
        default:
            return state;
    }
}

export default providerReducer;
