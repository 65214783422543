import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {GlobalContext} from "context/Provider";
import Session from "helpers/session";
import API from 'api';
import {useNavigate} from "react-router";
import { Grid } from '@mui/material';
import SimpleGrid, { CellProps, BodyProps} from "components/shared/SimpleGrid/SimpleGrid";
import { StyledSimpleGridContainer } from "../List/List.styles";
import {ReadingContextInterface} from "context/initialState/Readings";
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import { useTranslation } from 'react-i18next';
import { ContactPageSharp } from '@mui/icons-material';
import { toLocalTime, associateReadingType, tempConversion } from 'helpers/variables';


interface ListProps {
    type: string;
}

interface HeadersInterface {
    'Blood Pressure': CellProps[];
    'Blood Glucose': CellProps[];
    'Heart Rate': CellProps[];
    'Oxygen Saturation': CellProps[];
    'Spirometry': CellProps[];
    'Temperature': CellProps[];
    'Weight': CellProps[];
    'Respiration Rate': CellProps[];
    'Member Note': CellProps[];
}



const formatTableBody = (readingsArray: ReadingContextInterface[], action: (event: ChangeEvent<HTMLElement>)=>void, units: string | undefined)=>{
    let tableBody: BodyProps[] = [];
    readingsArray.sort((a, b)=>{return moment(a.measuredAt).unix() - moment(b.measuredAt).unix()}).reverse().map(reading=>{
        switch(reading.type){
            case 'bp':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.systolic + '', align: 'center'}, {content: reading.metrics?.diastolic + '', align: 'center'}, {content: reading.metrics?.pulse + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'bgl':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.glucose + '', align: 'center'}, {content: reading.metrics?.meal + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'hr':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.pulse + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'spo2':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.spo2 + '', align: 'center'}, {content: reading.metrics?.pulse + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'spir':
                // tableBody.push({cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.systolic + '', align: 'center'}, {content: reading.metrics?.diastolic + '', align: 'center'}, {content: reading.metrics?.pulse + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'temp':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: tempConversion(reading.metrics?.temp || 0, units) + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
            case 'wt':
                tableBody.push({id: reading.id, cells:[{content: toLocalTime(reading.measuredAt), align: 'center'},{content: reading.metrics?.weight + '', align: 'center'}, {content: "•", align: 'center'}], action: action});
                break;
        }
    });
    return tableBody;
};

export default function List(props: ListProps){
    const { t } = useTranslation();
    const memberID = window.location.pathname.split('/').pop();
    const [readingList, setReadingList]=useState<BodyProps[]>([]);
    const { config, readingsDispatch } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [totalReadingList, setTotalReadingList]=useState<number>(0);

    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{
        API.kore.readings.getForOne(memberID || '', Session.crackCookieToken('coordinator'), associateReadingType(props.type), `&limit=${itemsPerPage}&page=${currentPage}&order=desc&sort_by=measured_at`)
        .then(readings=>{
            setReadingList(formatTableBody(readings.items, selectReading, config?.basicOptions.units))
        });
    };

    const headers: HeadersInterface = {
        'Blood Pressure':    [{content: t('u-date'), align: 'center'}, {content: `${t('u-systolic')}, mmHg`, align: 'center'}, {content: `${t('u-diastolic')}, mmHg`, align: 'center'}, {content: `${t('u-pulse')}, bpm`, align: 'center'}, {content: t('u-status'), align: 'center'}],
        'Blood Glucose':     [{content: t('u-date'), align: 'center'}, {content: `${t('u-glucose')}, mg/dL`, align: 'center'}, {content: t('u-meal'), align: 'center'},                 {content: t('u-status'), align: 'center'}],
        'Heart Rate':        [{content: t('u-date'), align: 'center'}, {content: `${t('u-pulse')}, bpm`, align: 'center'},     {content: t('u-status'), align: 'center'}],
        'Oxygen Saturation': [{content: t('u-date'), align: 'center'}, {content: t('u-oxygen-saturation'), align: 'center'},   {content: `${t('pulse')}, bpm`, align: 'center'},        {content: t('u-status'), align: 'center'}],
        'Spirometry':        [{content: t('u-date'), align: 'center'}, {content: 'FVC,L', align: 'center'},                    {content: 'FEV1,L', align: 'center'},                    {content: 'PEF, L/sec', align: 'center'},           {content: 'PEF, L/min', align: 'center'}, {content: 'POST/PRE', align: 'center'}, {content: t('u-status'), align: 'center'}],
        'Temperature':       [{content: t('u-date'), align: 'center'}, {content: `${t('u-temperature')}, F`, align: 'center'}, {content: t('u-status'), align: 'center'}],
        'Weight':            [{content: t('u-date'), align: 'center'}, {content: `${t('u-weight')}, lbs`, align: 'center'},    {content: t('u-status'), align: 'center'}],
        'Respiration Rate':  [{content: t('u-date'), align: 'center'}, {content: t('u-breathing'), align: 'center'}],
        'Member Note':       [{content: t('u-date'), align: 'center'}, {content: t('u-coordinator'), align: 'center'},         {content: t('u-note'), align: 'center'}]
    };

    const selectReading = (event: ChangeEvent<HTMLElement>)=>{
        //TODO: utilize Mel call to avoid improper tier access on readings
        API.kore.readings.getSingleReading(event.target.dataset.id || '' ,Session.crackCookieToken('coordinator'))
            .then(reading=>{
                //TODO: ensure all necessary data is present for reading details
                readingsDispatch({type: 'UPDATE_READING', payload: reading});
                const currentPath = window.location.pathname;
                navigate(currentPath.replace('list', 'details'))
            });
    };

    useEffect(()=>{
        switch(props.type){
            case 'Member Note':
                //TODO: API call for Notes
                setReadingList([]);
                break;
            case 'ECG':
                //TODO: API call for ECG if necessary
                setReadingList([]);
                break;
            default:
                //TODO: switch with count API
                API.kore.readings.getCount(memberID || '', Session.crackCookieToken('coordinator'), associateReadingType(props.type))
                    .then(readings=>{
                        setTotalReadingList(readings.count);
                        paginationAction(5, 0)

                    })
        };
    }, [props.type]);
    return (
        <Grid container>
            <Grid item xs={0.5} lg={0.7}/>
            <Grid item xs={11.2} lg={11.1}>
                <EncounterTimer></EncounterTimer>
                <StyledSimpleGridContainer>
                    <SimpleGrid headers={headers[props.type as keyof HeadersInterface]} body={readingList} paginationAction={paginationAction} totalList={totalReadingList}/>
                </StyledSimpleGridContainer>
            </Grid>
        </Grid>
    )
}
