import { styled } from '@mui/material/styles';
import { Stack, TextField } from '@mui/material';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    justifyContent: 'left',
}));

export const StyledHeader = styled('div')(({theme})=>({
    justifyContent: 'left',
    width: '10%',    
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    width: '30%',
    paddingLeft: theme.spacing(1)
}));

export const StyledStack = styled(Stack)(({theme})=>({
    justifyContent: 'left',
    width: '100%'
}));

export const StyledButtonStack = styled(Stack)(({theme})=>({
    justifyContent: 'left',
    width: '83%',
    marginLeft: theme.spacing(8), 
}));

export const StyledCheckBoxStack = styled(Stack)(({theme})=>({
    justifyContent: 'center',

}));
export default { StyledContainer, StyledHeader, StyledTextField, StyledStack, StyledButtonStack, StyledCheckBoxStack };
