import { styled } from '@mui/material/styles';
import { ArrowRightAlt } from '@mui/icons-material';

export const StyledArrow = styled(ArrowRightAlt)(({theme})=>({
    root: {
        transform: 'rtl'
    }
}));

export const StyledBubble = styled('div')(({theme})=>({
    border: `1px solid black`,
    borderRadius: theme.spacing(2),
    width: '70%',
    backgroundColor: theme.palette.common.background.light
}));

export const StyledDateTitle = styled('h4')(({theme})=>({
    textAlign: 'left'
}));

export const StyledType = styled('p')(({theme})=>({
    width: theme.spacing(20),
    textAlign: 'left'
}));
