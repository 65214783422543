const salt = 'fK4.,ErrsTO3Pasdf.?4FSapkd03H5,2EfD?0aleiPOLE8<ffdQK?@b,xjTbns4*7vkjhVCD3458Oplmnb?0&jg#fcloiUBkhdsD7*(*&^mvcjHV?0&jg#fcloieiPOLE8<ffdQKdf.?4FE8<ffdQK?@b,xjTbns4*7vkjhVCD3458Oplmnb?0&jg#fclokjhVCD3458Oplmn^mvcjHV?0&jg#fcloieiPOLE8<fEfD?0aleiPOLE8458Oplmn^mvcb?0&jg#f#fcloieiPOLE8<ffdQKdf.?4FE8<ff58OplmnbhdObXu8@ZF5?im4Kwc3QSnDdZJCf1yVfJeh<H.IilOCNcPb1fihI#';

const encrypt = (value: string)=>{
    let divide = [];
    let i = 0;
    let final = [];
    for(let l=0; l<value.length; l++){
        final.push(value[l]);
        final.push(salt[l]);
    }
    while(i < 1) {
        divide.push(final.join("").slice(0, final.length / 2));
        divide.push(final.join("").slice(final.length / 2, final.length));
        final = [];
        for(let k=0; k<divide[0].length; k++){
            final.push(divide[0][k]);
            final.push(divide[1][k]);
        }
        i++
    }
    return final.reverse().join('');
};

const decrypt = (value: string)=>{
    let i =0;
    let final = [];
    let temp = value.split("").reverse();
    while(i < 1){
        let arr1 = [];
        let arr2 = [];
        for(let l = 0; l<temp.length; l++){
            arr1.push(temp[l]);
            arr2.push(temp[l+1]);
            l++
        }
        temp = arr1.concat(arr2);
        i++
    }
    for(let r=0; r < temp.length; r++){
        final.push(temp[r]);
        r++
    }
    return final.join("");
};

const crackCookieToken = (type: 'coordinator' | 'provider')=>{
    const decodedCookie = decodeURIComponent(document.cookie);
    let token = '';
    let coordinatorCookie = 'life365_session=0';
    let providerCookie = 'life365_provider=0';
    decodedCookie.split('; ').map(cookie=>{
        if(cookie.split('=')[0] === 'life365_session'){
            coordinatorCookie = cookie
        }else if(cookie.split('=')[0] === 'life365_provider'){
            providerCookie = cookie
        }
    });
    switch(type){
        case 'coordinator':
            token = JSON.parse(decrypt(coordinatorCookie.split('=')[1])).token;
            break;
        case 'provider':
            token = JSON.parse(decrypt(providerCookie.split('=')[1])).token;
            break;
        default:
            break;
    }
    return token;
};

export default { encrypt, decrypt, crackCookieToken }
