function memberReducer(state: any, {type, payload}: any){
    switch (type) {
        case 'LOGIN':
            return {...state, ...payload};
        case 'CHANGE_TARGET_MEMBER':
            return {...state, current: payload};
        case 'UPDATE_LIST':
            return {...state, list: payload};
        case 'UPDATE_CID':
            return {...state, cid: payload};
        case 'UPDATE_SECONDS':
            return {...state, secondsCounter: payload};
        case 'UPDATE_START':
            return {...state, encounterStart: payload};
        default:
            return state;
    }
}

export default memberReducer;
