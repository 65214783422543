import React, {useContext, useState} from 'react';
import {GlobalContext} from "context/Provider";

export default function Branding(){
    const { config } = useContext(GlobalContext);
    const [showSuccess, setSuccess] = useState<string>('');

    const handleSubmission = (event: any) => {
        const { expiration, locale, general_units, support_url, push_url, logo_file, favicon_file, terms, privacy, contact_info } = event.target;
        event.preventDefault();
        setSuccess('success');
        const formBody = {
            expiration: expiration,
            locale: locale,
            generalUnits: general_units,
            supportURL: support_url,
            pushURL: push_url,
            logoFile: logo_file,
            faviconFile: favicon_file,
            terms: terms,
            privacy: privacy,
            contactInfo: contact_info
        };
        // TODO: add api call that updates config DB
    };
    return (
        <div>
            <h3>Branding</h3>
            <p>{config?.brandingOptions.clientLogo}</p>
        </div>
    )
}
