import { styled } from '@mui/material/styles';
import { Badge, Grid, Select } from '@mui/material';
import { Link } from 'react-router-dom';

export const StyledLogo = styled('img')(({theme})=>({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    width: theme.spacing(25),
}));

export const StyledContainer = styled(Grid)(({theme})=>({
    marginBottom: theme.spacing(4),
    background: theme.palette.common.background.main,
    textAlign: 'left'
}));

export const StyledDropdown = styled(Select)(({theme})=>({
    padding: theme.spacing(1),
    background: theme.palette.common.background.main,
    marginTop: theme.spacing(6)
}));

export const StyledButton = styled(Link)(({theme})=>({
    background: theme.palette.primary.light,
    color: 'white',
    textDecoration: 'none',
    height: theme.spacing(4),
    width: theme.spacing(12),
    border: `solid 1px ${theme.palette.primary.light}`,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    '&:hover': {
        cursor: 'pointer'
    }
}));

export const StyledBadge = styled(Badge)(({theme})=>({
    padding: theme.spacing(0.5)
}));

export default { StyledButton, StyledDropdown, StyledLogo, StyledContainer, StyledBadge };
