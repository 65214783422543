import React, {ChangeEvent, useContext, useState} from 'react';
import {Alert, FormControlLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField} from "@mui/material";
import Button from "components/shared/Button/Button";
import {StyledTextField} from "views/Admin/Admin.styled";
import {GlobalContext} from "context/Provider";
import API from "api";
import {regOptionsInterface} from "context/initialState/Config";
import {usStates} from "../../../helpers/variables";

interface bulkDestinationInterface {
    name?: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    phone?: string,
    email?: string
};

export default function Configurations(){
    const { config, configDispatch } = useContext(GlobalContext);
    const [showSuccess, setSuccess] = useState<string>('');
    const [isChange, setIsChange] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>('');
    const [groups, setGroups] = useState<string[]>(config?.regOptions.groups || []);
    const [bulkAddress, setBulkAddress] = useState<bulkDestinationInterface>({
        address: config?.orderOptions.bulkDestination.address || '',
        city: config?.orderOptions.bulkDestination.city || '',
        state: config?.orderOptions.bulkDestination.state || '',
        zip: config?.orderOptions.bulkDestination.zip || '',
        phone: config?.orderOptions.bulkDestination.phone || '',
        email: config?.orderOptions.bulkDestination.email || ''
    });

    const addGroup = ()=>{
        setGroups(groups.concat([groupName]));
        setGroupName('');
        setIsChange(true)
    };

    const updateGroupName = (event: ChangeEvent<HTMLInputElement>)=>{
        setGroupName(event.target.value)
    };

    const handleSelectChange = (event: SelectChangeEvent)=>{
        setBulkAddress({...bulkAddress, state: event.target.value});
        setIsChange(true)
    };

    const handleAddressChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setBulkAddress({...bulkAddress, [event.target.name]: event.target.value});
        setIsChange(true)
    };

    const handleSubmit = ()=>{
        API.kore.configurations.updateRegConfig({programs: config?.regOptions.programs || [], groups: groups} as regOptionsInterface)
            .then(regConf=>{
                configDispatch({type: 'UPDATE_REGS', payload: {programs: config?.regOptions.programs || [], groups: groups}})
            }).catch(regErr=>console.log(regErr));
        // @ts-ignore
        API.kore.configurations.updateOrderingConfig({...config?.orderOptions, bulkDestination: bulkAddress})
            .then(configs=>{
                configDispatch({type: 'UPDATE_ORDERS', payload: {...config?.orderOptions, bulkDestination: bulkAddress}});
                setSuccess('success');
                setIsChange(false)
            }).catch(err=>console.log(err))
    };

    return (
        <div>
            <h3>Site Configurations</h3>
            <Stack alignItems="flex-start" spacing={2} ml={15}>
                <h4>Groups list:</h4>
                {groups.map(group=>{
                    return <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label={group} control={<></>}/><Button variant={'warning'} onClick={addGroup}>-</Button></Stack>
                })}
                <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label="Add Group" control={<StyledTextField onChange={updateGroupName} value={groupName} type="text" placeholder="Group name" size="small"/>}/><Button variant={'primary'} onClick={addGroup}>+</Button></Stack>
                <br/>
                <br/>
                <h4>Bulk Orders Address:</h4>
                <TextField name="address" label="address" type="text" value={bulkAddress.address} onChange={handleAddressChange}/>
                <TextField name="city" label="city" type="text" value={bulkAddress.city} onChange={handleAddressChange}/>
                {/*<TextField name="state" label="state" type="text"/>*/}
                <Select name="state" label="State" sx={{height: '55px'}} onChange={handleSelectChange} value={bulkAddress.state} variant="outlined" required>
                    {usStates.map(S=>{
                        return <MenuItem value={S}>{S}</MenuItem>
                    })}
                </Select>
                <TextField name="zip" label="zip" type="text" value={bulkAddress.zip} onChange={handleAddressChange}/>
                <TextField name="phone" label="phone" type="text" value={bulkAddress.phone} onChange={handleAddressChange}/>
                <TextField name="email" label="notification email" type="text" value={bulkAddress.email} onChange={handleAddressChange}/>
            </Stack>
            <Button variant="success" onClick={handleSubmit} margin={2} disabled={!isChange}>Apply</Button>
            <br/>
            {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
        </div>
    )
}
