import Contact from 'assets/html/Contact';
import Privacy from 'assets/html/Privacy';
import Terms from 'assets/html/Terms';
import React, { useContext } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { GlobalContext } from 'context/Provider';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FooterLinks() {
  const {configDispatch, config} = useContext(GlobalContext)
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    configDispatch({type: 'FOOTER_LINK', payload: newValue});
  };

  return (
    <Grid container>
      <Grid xs={11}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={config?.footerTab} onChange={handleChange}>
              <Tab label={t('u-terms')} {...a11yProps(0)} />
              <Tab label={t('u-contact')} {...a11yProps(1)} />
              <Tab label={t('u-privacy')} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={config?.footerTab || 0} index={0}>
            <Terms/>
          </TabPanel>
          <TabPanel value={config?.footerTab || 0} index={1}>
            <Contact/>
          </TabPanel>
          <TabPanel value={config?.footerTab || 0} index={2}>
            <Privacy/>
          </TabPanel>
        </Box>        
      </Grid>
    </Grid>
  );
}               