import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Button from 'components/shared/Button/Button';
import { StyledTextField } from './Notes.styles';
import {StyledContainer, StyledHeader} from '../Notes/Notes.styles';
import { Alert, FormControl, FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import { StyledRadio } from 'components/shared/SharedStyles/SharedStyles';
import SimpleGrid, { CellProps, BodyProps} from "components/shared/SimpleGrid/SimpleGrid";
import { useTranslation } from 'react-i18next';
import { GlobalContext } from 'context/Provider';
import { NotesObject } from 'context/initialState/Notes';
import API from 'api';
import Session from "helpers/session";
import CustomizedDialogs from 'components/shared/DialogModal/DialogModal';
import { toLocalTime } from 'helpers/variables';

export default function Notes(){
    const { notes, notesDispatch, member } = useContext(GlobalContext);
    const [note, setNote] = useState(notes?.current.content);
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();
    const notesTableHeaders: CellProps[] = [{content: "Details", align: 'left'},{content: t('u-note'), align: 'center'},];
    const [notesTableBody, setNotesTableBody] = useState();
    const [totalNotesList, setTotalNotesList]=useState<number>(0)
    const [showSuccess, setSuccess] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);

    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{
        API.kore.notes.getList(Session.crackCookieToken('coordinator'), `?limit=${itemsPerPage}&page=${currentPage}`)
        .then(notesList=>{
            console.log(notesList)
            setNotesTableBody(notesList)
            
        })
        setTimeout(()=>{
            setLoading(false)
        }, 2000)
    }
   
    const generateList = (rawList: NotesObject[]): BodyProps[]=>{
        
        return rawList.map(item=>{
            return { cells: [
                {content: <CustomizedDialogs
                        coordinatorName={item.authorName}
                        coordinatorId={item.authorId}
                        patientName={`${member?.current.firstName} ${member?.current.lastName}`}
                        patientId={item.memberId}
                        date={toLocalTime(item.date)}
                        content={item.content}
                        isNote={false}
                    /> , align: 'left'},
                {content: <CustomizedDialogs
                    coordinatorName={item.authorName}
                    coordinatorId={item.authorId}
                    patientName={`${member?.current.firstName} ${member?.current.lastName}`}
                    patientId={item.memberId}
                    date={toLocalTime(item.date)}
                    content={item.content}
                    isNote={true}
                />, align: 'left'}]}
        })
    };

    const submitNote = ()=>{
        const noteBody: NotesObject = {
            memberId: member?.current.id || '',
            authorId: '3ee168121905f19916ae31f3',
            authorName: 'Rory Thomas',
            date: '',
            content: note || '',
            type: 'staff'
        };
        console.log(noteBody);
        setSuccess('success');
        setChanged(true);
        API.kore.notes.create(noteBody, Session.crackCookieToken('coordinator'))
        .then(issued=>{
            console.log(issued)
        }).catch(err=>console.log(err));
        setNote('');
    };

    useEffect(()=>{
        API.kore.notes.getList(Session.crackCookieToken('coordinator'))
        .then(noteList=>{
            console.log(noteList)
            notesDispatch({type: 'UPDATE_LIST', payload: noteList})
            setTotalNotesList(noteList.length)
            paginationAction(5, 0)
        })
    }, []);
    
    const handleChange = (event: ChangeEvent<HTMLElement>)=>{
        setSuccess('')
        setChanged(false);
    };
    return (
        <StyledContainer>
            <StyledHeader><h2>{t('u-staff-notes')}</h2></StyledHeader>
            <Grid container>
                <Grid item xs={1.5}/>
                <Grid item xs={10}>
                {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                    <StyledTextField
                        value={note}
                        onChange={(e) => {
                            setNote(e.target.value);
                            handleChange(e)
                        }}
                        placeholder={t('u-staff-notes')}
                        multiline
                        maxRows={2}
                        minRows={2}
                    />
                    <br/>
                    <br/>
                    <Grid container>
                        <Button variant="success" height="48px" type="submit" onClick={()=>{submitNote()}} disabled={isDisabled}>{t('u-save')}</Button>
                        
                        <Grid item  xs={1} md={3} lg={5.5}/>
                        <Button variant="success" height="48px" type="submit">{t('u-get-report')}</Button>
                        <Grid item xs={ 0.2}/>
                        <FormControl>
                            <RadioGroup row >
                                <StyledRadio>
                                    <FormControlLabel value="CSV" control={<Radio />} label="CSV" />
                                </StyledRadio>
                                <StyledRadio>
                                    <FormControlLabel value="PDF" control={<Radio />} label="PDF" />
                                </StyledRadio>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <br/>
                    <SimpleGrid headers={notesTableHeaders} body={generateList(notesTableBody || [])} paginationAction={paginationAction} totalList={totalNotesList}/>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}
// 
// notesTableBody