import React, {useContext, useState} from 'react';
import {Alert, Autocomplete, FormControlLabel, Grid, SelectChangeEvent, TextField} from '@mui/material';
import { StyledContainer, StyledHeader, StyledTextField, StyledStack, StyledTagField, FormTagLabel } from './Profile.styles';
import Button from "components/shared/Button/Button";
import API from 'api';
import { GlobalContext } from 'context/Provider';
import Session from "helpers/session";
import { useTranslation } from 'react-i18next';

interface ProfileProps {
    type: 'coordinator' | 'member';
    action: 'create' | 'edit';
}

export default function Profile(props: ProfileProps){
    const { t } = useTranslation();
    const { member, memberDispatch, config } = useContext(GlobalContext);
    const availablePrograms = config?.regOptions.programs || [];
    const availableGroups = config?.regOptions.groups || [];
    const [isDisabled, setChanged] =useState<boolean>(true);
    const [showSuccess, setSuccess] = useState<string>('');
    const [memberChanges, setMemberChanges] = useState({
        email: member?.current.email || '',
        firstName: member?.current.firstName || '',
        lastName: member?.current.lastName || '',
        phone: member?.current.phone,
        program: member?.current.program,
        site: member?.current.site,
        externId: member?.current.externId || '',
        group: member?.current.group,
    });

    const updateMember = () => {
        //TODO: change to toggle between token once provider auth is completed
       let userCall = API.kore.members.upsert(props.action === 'create' ? 'create' : 'edit', memberChanges, Session.crackCookieToken('coordinator') || Session.crackCookieToken('provider'), member?.current.id);
       setSuccess('success');
       if(props.type === 'coordinator'){
           userCall = API.kore.coordinator.upsert(props.action === 'create' ? 'create' : 'edit', memberChanges, Session.crackCookieToken('coordinator') || Session.crackCookieToken('provider'), member?.current.id)
       }
           userCall.then(member=>{
            setChanged(true);
           if(props.type === 'member'){
               memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: member})
           }
        })
    };
    const handleMemberChange = (event: any) => {
        setChanged(false);
        setSuccess('');
        setMemberChanges({...memberChanges, [event.target.id]: event.target.value})
    };

    const handleSelection = (event: any, value: any)=>{
        setChanged(false);
        setSuccess('');
        setMemberChanges({...memberChanges, [event.target.id]: value})
    };

    // @ts-ignore
    function handleSelecetedTags(items) {
        console.log(items);
    }

    return (
        <StyledContainer>
            <StyledHeader><h2>{t(`u-${props.type.toLowerCase().replace(' ', '-')}s`)} {t(`u-profile`)}</h2></StyledHeader>
            <Grid container>
                <Grid item xs={2} md={1.3}/>
                <Grid item sm={11} md={8}>
                    <StyledStack spacing={1}>
                        <br/>
                        <FormControlLabel
                            label={t('u-email')}
                            control={<StyledTextField id="email" placeholder={t('u-email')} onChange={handleMemberChange} value={memberChanges.email}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t("u-first-name")}
                            control={<StyledTextField id="firstName" placeholder={t("u-first-name")} onChange={handleMemberChange} value={memberChanges.firstName}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t("u-last-name")}
                            control={<StyledTextField id="lastName" placeholder={t("u-last-name")} onChange={handleMemberChange} value={memberChanges.lastName}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t('u-phone')}
                            control={<StyledTextField id="phone" placeholder={t('u-phone')} onChange={handleMemberChange} value={memberChanges.phone}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t('u-site')}
                            control={<StyledTextField id="site" placeholder={t('u-site')} onChange={handleMemberChange} value={memberChanges.site}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label="MRN"
                            control={<StyledTextField id="externId" placeholder="MRN" onChange={handleMemberChange} value={memberChanges.externId}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t('u-group')}
                            control={<Autocomplete
                                id="group"
                                renderInput={(params)=> <TextField {...params} name="group" placeholder="group-1" sx={{width: '86%', float: 'right'}}/>}
                                options={availableGroups}
                                onChange={handleSelection}
                                fullWidth
                            />}
                            // control={<StyledTextField id="group" placeholder={t('u-group')} onChange={handleMemberChange} value={memberChanges.group}/>}
                            labelPlacement="start"
                        />
                        <FormControlLabel
                            label={t('u-program')}
                            control={<Autocomplete
                                id="program"
                                renderInput={(params)=> <TextField {...params} name="program" placeholder="Cardiac" sx={{width: '88%', float: 'right'}}/>}
                                options={availablePrograms.map(name=>{return name.name})}
                                onChange={handleSelection}
                                fullWidth
                            />}
                            // control={<StyledTextField id="group" placeholder={t('u-group')} onChange={handleMemberChange} value={memberChanges.group}/>}
                            labelPlacement="start"
                        />
                        {/*<FormTagLabel*/}
                        {/*<FormControlLabel*/}
                        {/*    label={t('u-program')}*/}
                        {/*    control={<StyledTextField id="program" placeholder={t('u-program')} onChange={handleMemberChange} value={memberChanges.program}/>}*/}
                        {/*    // control={<StyledTagField*/}
                        {/*    //     selectedTags={handleSelecetedTags}*/}
                        {/*    //     variant="outlined"*/}
                        {/*    //     id="tags"*/}
                        {/*    //     name="tags"*/}
                        {/*    //     placeholder="add Tags"*/}
                        {/*    // />}*/}
                        {/*    labelPlacement="start"*/}
                        {/*/>*/}
                    </StyledStack>
                    <br/>
                    <Button variant="success" onClick={updateMember} disabled={isDisabled}>{t('u-save')}</Button>
                    {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                </Grid>
            </Grid>
        </StyledContainer>
    )
}
