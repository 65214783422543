import React, {ReactNode, useContext, useEffect, useState} from 'react';
import { StyledContainer, StyledHeader, StyledHeaderText } from './PatientBoard.styles';
import API from 'api';
import { Box, Grid } from '@mui/material';
import { Routes, Route } from "react-router-dom";
import moment from 'moment';
import SideNavBar from './microViews/SideNavBar/SideNavBar';
import Vitals from './microViews/Vitals/Vitals';
import Profile from './microViews/Profile/Profile';
import Details from './microViews/Details/Details';
import Export from './microViews/Export/Export';
import Notes from './microViews/Notes/Notes';
import History from './microViews/History/History';
import Notifications from './microViews/Notifications/Notifications';
import Encounters from './microViews/Encounters/Encounters';
import Monitoring from './microViews/Monitoring/Monitoring';
import Options from './microViews/Options/Options';
import {GlobalContext} from "../../context/Provider";
import Session from "helpers/session";
import Surveys from "./microViews/Surveys/Surveys";
import Devices from "./microViews/Devices/Devices";
import SmartDevices from "./microViews/Devices/SmartDevices";
import SharedDrawer from 'components/shared/Drawer/Drawer';
import Messaging from "./microViews/Messaging/Messaging";
import Combined from "./microViews/Devices/Combined";

export default function PatientBoard(){
    const { member, memberDispatch } = useContext(GlobalContext);
    const { config, configDispatch } = useContext(GlobalContext);
    let devicePath = <></>;

    useEffect(()=>{
        if(!member?.current.email){
            const memberId = window.location.pathname.split('/').pop();
            API.kore.members.getOne(memberId || '', Session.crackCookieToken('coordinator'))
                .then(member=>{
                    memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: member})
                })
        }
        API.kore.configurations.getConfigurations()
            .then(configs=>{
                configDispatch({type: 'OPTIONS_UPLOAD', payload: configs})
            }).catch(err=>console.log(err));
    }, []);

    function deviceRoute(){
        if(config?.basicOptions.cellDevices === true && config?.basicOptions.smartDevices === true){
            return devicePath = <Combined/>
        }else if(config?.basicOptions.cellDevices === true){
            return devicePath = <Devices/>
        }else{
           return devicePath = <SmartDevices/>
        }
    }

    deviceRoute();
    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <StyledContainer>
                    <StyledHeader><StyledHeaderText>{`${member?.current.firstName} ${member?.current.lastName}, ${(parseInt(moment().format('YYYY')) - parseInt(moment(member?.current.birthDate).format('YYYY'))) || ''}`}</StyledHeaderText></StyledHeader>
                    <Grid container>
                        <Grid item xs={0} md={2}>
                            <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                                <SideNavBar/>
                            </Box>
                        </Grid>
                        <Grid item xs={10}>
                        <Box sx={{ display: { xs: 'block', md: 'none'}}}>
                                <SharedDrawer/>
                            </Box>
                            <Routes>
                                <Route path="profile/:memberId" element={<Profile type="member" action="edit"/>}/>
                                <Route path="details/:memberId" element={<Details type="member" action="edit"/>}/>
                                <Route path="notes/:memberId" element={<Notes/>}/>
                                <Route path="vitals/:memberId" element={<Vitals/>}/>
                                <Route path="history/*" element={<History/>}/>
                                <Route path="surveys/:memberId" element={<Surveys/>}/>
                                <Route path="export/:memberId" element={<Export/>}/>
                                <Route path="notifications/:memberId" element={<Notifications/>}/>
                                <Route path="encounters/:memberId" element={<Encounters/>}/>
                                <Route path="monitoring/:memberId" element={<Monitoring/>}/>
                                <Route path="messaging/:memberId" element={<Messaging/>}/>
                                <Route path="devices/:memberId" element={devicePath}/>
                                <Route path="options/:memberId" element={<Options/>}/>
                            </Routes>
                        </Grid>
                    </Grid>
                </StyledContainer>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    )
}
