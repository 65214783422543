import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import { Tab, Stack } from "@mui/material";
import { Routes, Route, useNavigate } from 'react-router-dom';
import { StyledBox, StyledTabs, StyledHeader } from './Measurements.styled';
import List from "../List/List";
import Thresholds from "../Thresholds/Thresholds";
import Chart from "../Chart/Chart";
import Attributes from "../Attributes/Attributes";
import {GlobalContext} from "context/Provider";
import { useTranslation } from 'react-i18next';

interface MeasurementProps {
    type: string;
}

export default function Measurements (props: MeasurementProps){
    const { type } = props;
    const memberID = window.location.pathname.split('/').pop();
    const [tabValue, setTabValue] = useState('list/' + memberID);
    const { member } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const changeTab = (event: SyntheticEvent, newValue: string) => {
        let currentPath = window.location.pathname.split('/');
        if(currentPath.length > 5){
            // remove memberId & current tab before navigating to the next
            currentPath.pop();
            currentPath.pop();
        }
        navigate(`${currentPath.join('/')}/${newValue}`);
        setTabValue(newValue);
    };

    useEffect(()=>{
        setTabValue(window.location.pathname.split('/')[4] + '/' + memberID)
    }, [window.location.pathname.split('/')[3]]);
    return (
        <Stack>
        <StyledBox>
            <StyledHeader>{t(`u-${type.toLowerCase().replace(" ","-")}`)}</StyledHeader>
            <StyledTabs value={tabValue} onChange={changeTab}>
                <Tab value={`list/${member?.current.id}`} label={t('u-list')}/>
                <Tab value={`chart/${member?.current.id}`} label={t('u-chart')}/>
                <Tab value={`thresholds/${member?.current.id}`} label={t('u-thresholds')}/>
            </StyledTabs>
        </StyledBox>
        <Routes>

            <Route path="details/:readingId" element={<Attributes type={type}/>}/>
            <Route path="list/:memberId" element={<List type={type}/>}/>
            <Route path="chart/:memberId" element={<Chart type={type}/>}/>
            <Route path="thresholds/:memberId" element={<Thresholds type={type}/>}/>
        </Routes>
        </Stack>
    )
}
