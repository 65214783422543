import React, {ChangeEvent, ReactFragment} from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  TablePagination,
  TableFooter } from '@mui/material';
import { StyledRow, StyledHeaderRow, StyledErrorContainer } from './SimpleGrid.styles';
import { ImportantDevices } from '@mui/icons-material';
import theme from 'helpers/mui-theme.helper';
interface SimpleGridProps {
    headers: CellProps[];
    body: BodyProps[];
    paginationAction?: ( currentPage?: number, itemsPerPage?: number)=> void;
    totalList?: number;
    hidePagination?: boolean;
}
export interface CellProps {
    content: ReactFragment | JSX.Element | number;
    align?: 'inherit' | 'left' | 'right' | 'center'
}
export interface BodyProps {
    cells: CellProps[];
    id?: string;
    action?: (event: any)=> void;
}
interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
  }

export default function SimpleGrid(props: SimpleGridProps){
    const { headers, body, paginationAction, totalList } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    let rowCount = 0;
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowCount) : 0;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    paginationAction!(rowsPerPage, newPage)
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    paginationAction!(parseInt(event.target.value), 0)
    setPage(0);
  };
    return (
        <div>
        <TableContainer component={Paper}>
           {body.length > 0 ? (
                <Table>
                    <TableHead>
                        <StyledHeaderRow>
                            {headers.map(header=>{
                                return <TableCell align={header.align}>{header.content}</TableCell>
                            })}
                        </StyledHeaderRow>
                    </TableHead>

                    <TableBody sx={body.length > 0 ? {} : {textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                        {body.map(row=>{
                            rowCount++;
                            return (
                            <StyledRow key={`notifications-entry-${rowCount}`} onClick={row.action} hover>
                                {row.cells.map(cell=>{
                                    return <TableCell data-id={row.id} align={cell.align} >{cell.content}</TableCell>
                                })}
                            </StyledRow>)
                        })}
                    </TableBody>
        <TableFooter>
          <TableRow>
            {!props.hidePagination && (<TablePagination
              rowsPerPageOptions={[25, 10, 5, 3]}
              colSpan={3}
              count={totalList || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />)}
          </TableRow>
        </TableFooter>
                </Table>
            ):(
                <StyledErrorContainer>
                    <ImportantDevices fontSize={'large'}/>
                    <h3>No Data Available</h3>
                </StyledErrorContainer>
            )}
        </TableContainer>
        </div>
    )
}
