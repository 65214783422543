import axios, { AxiosResponse } from "axios";
import {kore_url, mel_url} from './index';
import Session from 'helpers/session';

interface authProps {
    email: string;
    password: string;
    type: 'provider' | 'coordinator' | 'patient'
}

interface passwordRequestObject {
    userId: string;
    expiration: string;
    type: 'provider' | 'coordinator' | 'patient'
    url: string;
}

export default {
    login: (login: authProps)=>{
        const config = {
            method: 'POST',
            url: `${mel_url}/api/coordinator/login`,
            data: login,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((loginResponse: AxiosResponse)=>{
                const session={
                    token: loginResponse.data.access_token,
                    expiration: loginResponse.data.expires_in,
                    // granting_host: kore_url.replace('https://', '').split('.')[0]
                };
                const d = new Date();
                d.setTime(d.getTime() + loginResponse.data.expires_in);
                let expires = 'expires='+ d.toUTCString();
                document.cookie = `life365_session=${Session.encrypt(JSON.stringify(session))}`;
                return loginResponse.data.access_token
            }).catch(err=>{throw err});
    },
    providerAccess: (login: authProps)=>{
        const config = {
            method: 'POST',
            url: `${mel_url}/api/coordinator/login`,
            // url: `${mel_url}/api/provider/login`,
            data: login,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((loginResponse: AxiosResponse)=>{
                const session={
                    token: loginResponse.data.access_token,
                    expiration: loginResponse.data.expires_in,
                    // granting_host: kore_url.replace('https://', '').split('.')[0]
                };
                const d = new Date();
                d.setTime(d.getTime() + loginResponse.data.expires_in);
                let expires = 'expires='+ d.toUTCString();
                document.cookie = `life365_provider=${Session.encrypt(JSON.stringify(session))}`;
                return loginResponse.data.access_token
            }).catch(err=>{throw err});
    },
    getFullCoordList: (limit?: string, select?: string, filter?: string)=>{
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const limiter = ()=>{
            let limitQuery = '';
            if (!filter){
                limitQuery = limit ? `?limit=${limit}` : '';
            } else {
                limitQuery = limit ? `&limit=${limit}`: '';
            };
            return limitQuery
        };
        const config = {
            method: 'GET',
            url: `${mel_url}/api/coordinator/list${queries()}${limiter()}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((coordinators: AxiosResponse)=>{

                return JSON.parse(coordinators.data.text).items
            })
    },
    getSingle: (coordinatorID: string, token: string)=>{
        const config = {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
            // url: `${mel_url}/api/coordinators/${coordinatorID}`,
            url: `${kore_url}/api/clients/${coordinatorID}`
        };
        return axios(config)
            .then((coordinators: AxiosResponse)=>{
                return coordinators.data
            })
    },
    getFullMemberList: (select?: string, limit?: string, filter?: string)=>{
        const selector = ()=>{
            return select ? `?select=${select}` : ''
        };
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const limiter = ()=>{
            let limitQuery = '';
            if (!filter){
                limitQuery = limit ? `?limit=${limit}` : '';
            } else {
                limitQuery = limit ? `&limit=${limit}`: '';
            };
            return limitQuery
        };
        const config = {
            method: 'GET',
            url: `${mel_url}/api/coordinator/members/list${selector()}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((coordinators: AxiosResponse)=>{
                return JSON.parse(coordinators.data.text)
            })
    },
    upsert: (action: string, body: object, token: string, coordinatorID?: string)=> {
        const config = {
            method: action === 'create' ? 'POST' : 'PUT',
            url: `${mel_url}/api/coordinator${action === 'create' ? '' : `/${coordinatorID}`}?token=${token}`,
            data: body,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((newCoordinator: AxiosResponse)=>{
                return newCoordinator.data
            })
    },
    remove: (userId: string, token: string)=>{
        const config = {
            method: 'DELETE',
            url: `${mel_url}/api/coordinator/${userId}?token=${token}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return axios(config)
            .then((newCoordinator: AxiosResponse)=>{
                return newCoordinator.data
            })
    },
    submitPassword: (password: string, grantId:string)=>{
        const config = {
            method: 'PUT',
            url: `${mel_url}/api/grant/${grantId}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            data: {password: password}
        };
        return axios(config)
            .then((passResponse: AxiosResponse)=>{
                return passResponse.data
            })
    },
    passwordChangeRequest: (data: passwordRequestObject)=>{
        const config = {
            method: 'POST',
            url: `${mel_url}/api/grant`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            },
            data: data
        };
        return axios(config)
            .then((passResponse: AxiosResponse)=>{
                return passResponse.data
            })
    },
}
