import React, {ChangeEvent, useState} from 'react';
import { TextField, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import API from 'api';
import Button from "components/shared/Button/Button";

export default function Password(){
    const [newPass, setNew] = useState<string>('');
    const [confirmPass, setConfirm] = useState<string>('');
    const [message, setMessage] = useState<string>();
    const grantId = window.location.pathname.split('/').pop();
    const navigate = useNavigate();

    const handleSubmission = ()=>{
        if(newPass === confirmPass){
            API.kore.coordinator.submitPassword(newPass, grantId || '').then(()=>{
                navigate('/')
            }).catch(err=>{
                setMessage(err.text)
            })
        }else{
            setMessage('Passwords did not match')
        }
    };

    return (
        <div>
            <Stack spacing={2} sx={{ mx: '20%', mt: '5%', mb: '10%' }}>
                <h4>Password Reset</h4>
                <TextField label={'New Password'} value={newPass} onChange={(e: ChangeEvent<HTMLInputElement>)=>{setNew(e.target.value)}}/>
                <TextField label={'Confirm Password'} value={confirmPass} onChange={(e: ChangeEvent<HTMLInputElement>)=>{setConfirm(e.target.value)}}/>
                {message ? <h4 style={{color: 'red'}}>Error: {message}</h4> : ''}
                <Button variant="primary" onClick={handleSubmission}>Change Password</Button>
            </Stack>
        </div>
    )
}
