import { styled } from '@mui/material/styles'

export const StyledHeader = styled('div')(({theme})=>({
    height: theme.spacing(5),
    width: '100%',
    background: theme.palette.primary.light,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingTop: theme.spacing(0.5)
}));

export const StyledContainer = styled('div')(({theme})=>({
    border: `1px solid grey`,
    minWidth: theme.spacing(72),
}));

export const StyledHeaderText = styled('span')(({theme})=>({
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(3.25)
}));

export default { StyledContainer, StyledHeader, };
