import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    justifyContent: 'left',
}));

export const StyledHeader = styled('div')(({theme})=>({
    justifyContent: 'left',
    width: '100%'
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    width: '100%'
}));
