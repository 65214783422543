import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
    Box,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography
    } from "@mui/material";
import { GlobalContext } from 'context/Provider';
import { memberContext, memberContextInterface, memberObject } from 'context/initialState/Member';
import { providerContext, providerContextInterface, RowInterface } from 'context/initialState/Provider';
import providerReducer from 'context/reducers/providerReducer';
import memberReducer from 'context/reducers/memberReducer';
import {
    StyledButton,
    StyledReportContainer,
    StyledSideBar,
    StyledChartTabContainer,
    } from './Reports.styled';
import { useTranslation } from 'react-i18next';
import ExportSelect from 'components/shared/ExportSelect/ExportSelect';
import SimpleGrid, { BodyProps, CellProps } from 'components/shared/SimpleGrid/SimpleGrid';
import API from 'api';
import Session from "helpers/session";
import { SignalCellular0Bar } from '@mui/icons-material';
import { LineGraphInterface, StyledLineGraph } from './Charts/LineGraph';
import { BarDataInterface, StyledBarChart } from './Charts/BarChart';
import { PieChartInterface, StyledPieChart } from './Charts/PieChart';
import notifications from 'api/kore/notifications';
import { associateReadingType } from 'helpers/variables';
import moment from 'moment';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface CoordinatorObject {
    email: string;
    id: string;
    locked: boolean;
    redirectUris: string[];
    roles: string;
    sources: string[];
    userRoles: string;
    username: string;
}
interface breachesObject {
    bp: breachesStatusObject;
    wt: breachesStatusObject;
    temp: breachesStatusObject;
    hrt: breachesStatusObject;
    bgl: breachesStatusObject;
    spo2: breachesStatusObject;
    spir: breachesStatusObject;
    activity: breachesStatusObject;
}
interface breachesStatusObject {
    acknowledged: number;
    unacknowledged: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Reports(){
    const startDate = new Date();
    const endDate = new Date();
    const [startPickedDate, setStartDate] = React.useState<any>(moment(startDate.setDate(startDate.getDate() - 30)).format('YYYY-MM-DD'));
    const [endPickedDate, setEndDate] = React.useState<any>(moment(endDate).format('YYYY-MM-DD'));
    const {configDispatch, config} = useContext(GlobalContext)
    const {providerDispatch, provider, devicesDispatch, devices, notifications, notificationsDispatch } = useContext(GlobalContext)
    const [member, memberDispatch] = useReducer(memberReducer, memberContext);
    const [exportType, setExportTypes] = useState<string>('toPDF');
    // const [coordinatorData, setCoordinatorData] = useState<CoordinatorObject[]>();
    const [breachesState, setBreachesState] = useState<BarDataInterface[]>([]);
    const [patientReadings, setpatientReadings] = useState<any>();
    const [targetPatient, setTargetPatient] = useState<any>();
    const { t } = useTranslation();
    // const [] = useReducer(providerReducer, providerContext);
    const handleStartDateChange = (newValue: Date | null)=>{
        // console.log(moment(newValue).format('YYYY-MM-DD'))
        setStartDate(moment(newValue).format('YYYY-MM-DD'))};
    const handleEndDateChange = (newValue: Date | null)=>{
        // console.log(moment(newValue).format('YYYY-MM-DD'))
        setEndDate(moment(newValue).format('YYYY-MM-DD'))};

    const TableHeaders: CellProps[] = [{content: t('u-month'), align: 'center'}, {content: `${t('u-monitoring')} ${t('u-days')}`, align: 'center'}, {content: `RPM ${t('u-time')}`, align: 'center'}];
    const testTableBody: BodyProps[] = [
        { cells: [{content: 'June 2022', align: 'center'}, {content: '3', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'May 2022', align: 'center'}, {content: '1', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'April 2022', align: 'center'}, {content: '1', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'March 2022', align: 'center'}, {content: '14', align: 'center'}, {content: '00:00:00', align: 'center'}]},
    ];

    let programs: any = []
    provider?.memberList?.forEach((c) =>{ if(!programs.includes(c.program)){programs.push(c.program)}})
    programs.splice(programs.indexOf(""), 1)
    programs.splice(0, 1)

    let sites: any = []
    provider?.memberList?.forEach((c) =>{ if(!sites.includes(c.site)){sites.push(c.site)}})
    sites.splice(sites.indexOf(""), 1)
    sites.splice(0, 1)

    let groups: any = []
    provider?.memberList?.forEach((res) =>{ if(!groups.includes(res.group)){groups.push(res.group)}})
    groups.splice(0,1)

    let genders: any = []
    provider?.memberList?.forEach((c) =>{ if(!genders.includes(c.gender)){genders.push(c.gender)}})
    genders.splice(0, 1)

    let languages: any = []
    provider?.memberList?.forEach((c) =>{ if(!languages.includes(c.language)){languages.push(c.language)}})
    languages.splice(sites.indexOf(""), 1)
    languages.splice(0, 1)

    let hubArr: any = []
    devices?.items?.forEach((c) =>{ if(!hubArr.includes(c.type)){hubArr.push(c.type)}})


// Bar Charts
    let GroupData: BarDataInterface[]  = groups.map((res: any)=>{return({ "name": res, "patients": provider?.memberList?.filter((data: any)=>{ return data.group === res }).length || 0 })})
    GroupData.push({ "name": "Not Assigned", "patients": provider?.memberList?.filter((data: any)=>{ return !data.groups || data.groups === '' }).length })

    let PatientReadingData: BarDataInterface[]  = [
        { "name": "Blood Pressure", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'bp' }).length || 0 },
        { "name": "Weight", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'wt' }).length || 0 },
        { "name": "Heart Rate", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'hr' }).length || 0 },
        { "name": "Blood Glucose", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'bgl' }).length || 0 },
        { "name": "Temperature", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'temp'}).length || 0 },
        { "name": "Blood Oxygen", "readings": patientReadings?.items?.filter((data: any)=>{ return data.type === 'spo2'}).length || 0 }
    ] || null;
    const PatientReadingDataHeaders: CellProps[] = [ {content: "Measurement Type", align: 'center'}, {content: "Readings", align: 'center'} ];
    const PatientReadingDataTableBody: BodyProps[] = PatientReadingData.map((res: any)=>{return({ cells: [{content: res.name.toUpperCase(), align: 'center'}, {content: res.readings , align: 'center'}]})})

    let ProgramData: BarDataInterface[]  = programs.map((res: any)=>{return({ "name": res.toUpperCase(), "patients":  provider?.memberList?.filter((data: any)=>{ return data.program === res }).length || 0 })})
    ProgramData.push({ "name": "Not Assigned", "patients": provider?.memberList?.filter((data: any)=>{ return !data.program || data.program === '' }).length })

    let ActiveHubs: any =  hubArr.map((c: string)=>{return ({ "name": `${c} hub`, "Assigned": devices?.items?.filter((char: any)=>{ return char.assigned && char.type === c }).length, "Unassigned": devices?.items?.filter((char: any)=>{ return char.assigned === false && char.type === c}).length })})
    ActiveHubs.splice(ActiveHubs.indexOf('bluetooth', 1))
    const ActiveHubsHeaders: CellProps[] = [ {content: "Device Type", align: 'center'}, {content: "Assigned", align: 'center'}, {content: "Unassigned", align: 'center'}  ];
    const ActiveHubsTableBody: BodyProps[] = [
        { cells: [{content: 'Cellular Hubs', align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.assigned && char.type === 'cellular'}).length}`, align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.assigned === false && char.type === 'cellular'}).length}`, align: 'center'}]},
        { cells: [{content: 'Smart Hubs', align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.assigned && char.type === 'smart'}) .length}`, align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.assigned === false && char.type === 'smart'}).length}`, align: 'center'}]},
    ];

    let totalBreach: any  = []
    notifications?.items?.forEach((res)=>{if(!totalBreach.includes(res.username)){totalBreach.push(res.username)}})
    const overviewBreachList = totalBreach.map((res: any)=>{return({ "name": res, "Breached": notifications?.items?.filter((data: any)=>{ return data.username === res }).length || 0, "Responded": notifications?.items?.filter((data: any)=>{ return data.username === res && data.acknowledged }).length || 0 })})
    const totalBreachTableHeaders: CellProps[] = [ {content: "Patient Name", align: 'center'}, {content: "Breached", align: 'center'}, {content: "Responded", align: 'center'}  ];
    const totalBreachTableBody: BodyProps[] = totalBreach.map((res: any)=>{return({cells: [{content: res, align: 'center'}, {content: notifications?.items?.filter((data: any)=>{ return data.username === res }).length || 0, align: 'center'},{content: notifications?.items?.filter((data: any)=>{ return data.username === res && data.acknowledged }).length || 0 , align: 'center'}]})})


    let PatientBreach: BarDataInterface[] | undefined = breachesState;

    const AdmitedPatientLineData: any = [{ "name": "Status", "Admited": provider?.memberList.filter((res)=>{return(res.status === "admitted")}).length, "Remote": provider?.memberList.filter((res)=>{return(res.status !== "admitted")}).length }]
    const AdmitedPatientLineDataHeaders: CellProps[] = [  {content: "Status", align: 'center'}, {content: "Patients", align: 'center'} ];
    const AdmitedPatientLineDataTableBody: BodyProps[] = [
        { cells: [{content: 'Admited', align: 'center'}, {content: `${provider?.memberList.filter((res)=>{return(res.status === "admitted")}).length}`, align: 'center'}]},
        { cells: [{content: 'Remote', align: 'center'}, {content: `${provider?.memberList.filter((res)=>{return(res.status !== "admitted")}).length}`, align: 'center'}]}
    ];

    // // Pie Chart Context
    let GroupPieData = groups.map((res: string) => {return({ "name": res.toUpperCase(), "value": provider?.memberList?.filter((data: any)=>{ return data.group === res }).length })})
    GroupPieData.push({ "name": "Not Assigned", "value": provider?.memberList?.filter((data: any)=>{ return !data.group || data.group === '' }).length })
    const GroupDataHeaders: CellProps[] = [ {content: "Group", align: 'center'}, {content: "Patients", align: 'center'} ];
    const GroupDataTableBody: BodyProps[] = groups.map((res: any)=>{return({ cells: [{content: res.toUpperCase(), align: 'center'}, {content: provider?.memberList?.filter((data: any)=>{ return data.group === res }).length || 0 , align: 'center'}]})})
    GroupDataTableBody.push({cells: [{content: "Not Assigned", align: 'center'}, {content: `${provider?.memberList?.filter((data: any)=>{ return !data.group || data.group === '' }).length}`, align: 'center'}]})

    let DevicePieData = hubArr.map((res: string) => {return({ "name": res.toUpperCase(), "value": devices?.items?.filter((data: any)=>{ return data.type === res }).length })})
    const DevicePieDataHeaders: CellProps[] = [ {content: "Device Types", align: 'center'}, {content: "Device Stock", align: 'center'}] ;
    const DevicePieDataTableBody: BodyProps[] = [
        { cells: [{content: 'Cellular Hubs', align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.type === 'cellular' }).length}`, align: 'center'}]},
        { cells: [{content: 'Smart Hubs', align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.type === 'smart' }).length}`, align: 'center'}]},
        { cells: [{content: 'Bluetooth Devices', align: 'center'}, {content: `${devices?.items?.filter((char: any)=>{ return char.type === 'bluetooth' }).length}`, align: 'center'}]}
    ];

    let ProgramsPieData = programs.map((res: string) => {return({ "name": res.toUpperCase(), "value": provider?.memberList?.filter((data: any)=>{ return data.program === res }).length })})
    ProgramsPieData.push({ "name": "Not Assigned", "value": provider?.memberList?.filter((data: any)=>{ return !data.program || data.program === '' }).length })
    const ProgramsPieDataHeaders: CellProps[] = [ {content: "Program", align: 'center'}, {content: "Number of Patients", align: 'center'} ];
    const ProgramsPieDataTableBody: BodyProps[] = programs.map((res: any)=>{return({ cells: [{content: res.toUpperCase(), align: 'center'}, {content: provider?.memberList?.filter((data: any)=>{ return data.program === res }).length || 0 , align: 'center'}]})})
    ProgramsPieDataTableBody.push({cells: [{content: "Not Assigned", align: 'center'}, {content: `${provider?.memberList?.filter((data: any)=>{ return !data.program || data.program === 'not applicable' || data.program === 'other' || '' }).length}`, align: 'center'}]})

    let SitePieData = sites.map((res: string) => {return({ "name": res.toUpperCase(), "value": provider?.memberList?.filter((data: any)=>{ return data.site === res }).length })})
    SitePieData.push({ "name": "Not Assigned", "value": provider?.memberList?.filter((data: any)=>{ return !data.site || data.site === '' }).length })
    const SitePieDataHeaders: CellProps[] = [ {content: "Site", align: 'center'}, {content: "Number of Patients", align: 'center'} ];
    const SitePieDataTableBody: BodyProps[] = sites.map((res: any)=>{return({ cells: [{content: res.toUpperCase(), align: 'center'}, {content: provider?.memberList?.filter((data: any)=>{ return data.site === res }).length || 0 , align: 'center'}]})})
    SitePieDataTableBody.push({cells: [{content: "Not Assigned", align: 'center'}, {content: `${provider?.memberList?.filter((data: any)=>{ return !data.site || data.site === 'not applicable' || data.site === 'other' || '' }).length}`, align: 'center'}]})

    let LanguagePieData = languages.map((res: string) => {return({ "name": res.toUpperCase(), "value": provider?.memberList?.filter((data: any)=>{ return data.language === res }).length })})
    LanguagePieData.push({ "name": "Not Assigned", "value": provider?.memberList?.filter((data: any)=>{ return !data.language || data.language === '' }).length })
    const LanguagePieDataHeaders: CellProps[] = [ {content: "Languages", align: 'center'}, {content: "Patients", align: 'center'} ];
    const LanguagePieDataTableBody: BodyProps[] = languages.map((res: any)=>{return({ cells: [{content: res.toUpperCase(), align: 'center'}, {content: provider?.memberList?.filter((data: any)=>{ return data.language === res }).length || 0 , align: 'center'}]})})
    LanguagePieDataTableBody.push({cells: [{content: "Not Assigned", align: 'center'}, {content: `${provider?.memberList?.filter((data: any)=>{ return !data.language || data.language === 'not applicable' || data.language === 'other' || '' }).length}`, align: 'center'}]})

    let GenderPieData = genders.map((res: string) => {return({ "name": res.toUpperCase(), "value": provider?.memberList?.filter((data: any)=>{ return data.gender === res }).length })})
    GenderPieData.push({ "name": "Not Assigned", "value": provider?.memberList?.filter((data: any)=>{ return data.gender === 'not applicable' || data.gender === 'other' }).length })
    const GenderPieHeaders: CellProps[] = [ {content: "Patient Name", align: 'center'}, {content: "Gender", align: 'center'} ];
    const GenderPieTableBody: BodyProps[] = genders.map((res: any)=>{return({ cells: [{content: res.toUpperCase(), align: 'center'}, {content: provider?.memberList?.filter((data: any)=>{ return data.gender === res }).length || 0 , align: 'center'}]})})
    GenderPieTableBody.push({cells: [{content: "Not Assigned", align: 'center'}, {content: `${provider?.memberList?.filter((data: any)=>{ return data.gender === 'not applicable' || data.gender === 'other' }).length}`, align: 'center'}]})

    const ActivityPieData = [
       { "name": "Blood Pressure", "value": 25 },
       { "name": "Pulse Oxygen", "value": 28 },
       { "name": "Temperature", "value": 15 },
       { "name": "Weight", "value": 33 },
       { "name": "Blood Glucose", "value": 40 },
    ];
    // console.log('Dates', moment(startPickedDate).diff(endPickedDate, 'days'));
    // console.log('today',moment().format('YYYY-MM-DD'));

    const patientsArr: any = [];
    patientReadings?.items.forEach((res: any)=>{if(!patientsArr.includes(moment(res.measuredAt).format('YYYY-MM-DD'))){patientsArr.push(moment(res.measuredAt).format('YYYY-MM-DD'))}})
    const PatientActivityPieData = [
       { "name": "Days With Reading", "value": patientsArr.length},
       { "name": "Days Missed", "value": moment(moment().format('YYYY-MM-DD')).diff(startPickedDate, 'days') - patientsArr.length },
       { "name": "Days Left", "value": moment(endPickedDate).diff(moment().format('YYYY-MM-DD'), 'days') },

    ];



    const updateChartInformation = (dataKey: string, tab: string)=>{

        if(tab === 'groups'){

            return console.log(`${dataKey}`)
        }else if(tab === 'programs'){
            return console.log(`${dataKey}`)
        }else if(tab === 'coordinator'){
            return console.log(`${dataKey}`)
        }else if(tab === 'patient'){

        API.kore.readings.getForOne(dataKey, '', '', `&sort_by=measured_at&measured_between=${startPickedDate},${endPickedDate}`)
        .then(readings=>{
        setpatientReadings(readings)
        })
        API.kore.notifications.getList(Session.crackCookieToken('provider'), `memberId:${dataKey}`)
        //`sort_by=recieved_between=${startPickedDate},${endPickedDate}`
        .then(notifications=>{
            let breaches: breachesObject=
                {
                    bp:{acknowledged: 0, unacknowledged: 0},
                    wt:{acknowledged: 0, unacknowledged: 0},
                    temp:{acknowledged: 0, unacknowledged: 0},
                    hrt:{acknowledged: 0, unacknowledged: 0},
                    bgl:{acknowledged: 0, unacknowledged: 0},
                    spo2:{acknowledged: 0, unacknowledged: 0},
                    spir:{acknowledged: 0, unacknowledged: 0},
                    activity:{acknowledged: 0, unacknowledged: 0
                }}
            ;
            notifications.items.forEach((c: any) =>{
                if(c.readingType === 'bp'){
                    c.acknowledged ? breaches.bp.acknowledged++ : breaches.bp.unacknowledged++;
                }else if(c.readingType === 'wt'){
                    c.acknowledged ? breaches.wt.acknowledged++ : breaches.wt.unacknowledged++;
                }else if(c.readingType === 'temp'){
                    c.acknowledged ? breaches.temp.acknowledged++ : breaches.temp.unacknowledged++;
                }else if(c.readingType === 'hrt'){
                    c.acknowledged ? breaches.hrt.acknowledged++ : breaches.hrt.unacknowledged++;
                }else if(c.readingType === 'bgl'){
                    c.acknowledged ? breaches.bgl.acknowledged++ : breaches.bgl.unacknowledged++;
                }else if(c.readingType === 'spo2'){
                    c.acknowledged ? breaches.spo2!.acknowledged++ : breaches.spo2!.unacknowledged++;
                }else if(c.readingType === 'activity'){
                    c.acknowledged ? breaches.activity!.acknowledged++ : breaches.activity!.unacknowledged++;
                }
            })

                let breachAdj: any = []
                for(let reading in breaches){
                    if(breaches[reading as keyof breachesObject].acknowledged > 0 || breaches[reading as keyof breachesObject].unacknowledged > 0){
                        breachAdj.push({'name': reading ?  associateReadingType(reading) : '', 'Breached': breaches[reading as keyof breachesObject].acknowledged + breaches[reading as keyof breachesObject].unacknowledged, 'Unacknowledged': breaches[reading as keyof breachesObject].unacknowledged})
                    }
                }
            setTargetPatient(dataKey)
            setBreachesState(breachAdj)
        })
        }
    }

    const patientReadingsHeaders: CellProps[] = [ {content: "Breach Type", align: 'center'}, {content: "Breached", align: 'center'}, {content: "Unacknowledged", align: 'center'} ];
    const patientReadingsTablebody: BodyProps[] = breachesState!.map((res: any)=>{ return({ cells: [{content: res.name.toUpperCase(), align: 'center'}, {content: res.Breached , align: 'center'}, {content: res.Unacknowledged, align: 'center'}]})})


    const handleTabViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExportTypes(event.target.value);
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    configDispatch({type: 'FOOTER_LINK', payload: newValue});
    };

    const tabGenerator = (tab: string) => {
        if (tab === "overview"){

            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="overview" direction="row">
                    <StyledBarChart data={overviewBreachList} chart="overview" view="breaches"/>
                    <StyledPieChart data={GroupPieData} label='Patients By group'/>
                    <StyledPieChart data={GenderPieData} label="Patients BY Gender"/>
                    <StyledPieChart data={ProgramsPieData} label="Patients By Program"/>
                    <StyledPieChart data={SitePieData} label="Patients By Site"/>
                    <StyledPieChart data={LanguagePieData} label="Patients by Language"/>
                    {/* <StyledLineGraph data={ActivePatientLineData} chart="overview" view="activePatients"/>, */}
                    <StyledBarChart data={AdmitedPatientLineData} chart="overview" view="admitionStatus"/>,
                    {/* <StyledLineGraph data={RPMLineData} chart="overview" view="timeRPM"/> */}
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="overview" direction="row" spacing={5}>
                    <SimpleGrid headers={GroupDataHeaders} body={GroupDataTableBody} hidePagination={true}/>
                    <SimpleGrid headers={ProgramsPieDataHeaders} body={ProgramsPieDataTableBody} hidePagination={true}/>
                    <SimpleGrid headers={GenderPieHeaders} body={GenderPieTableBody} hidePagination={true}/>
                    <SimpleGrid headers={SitePieDataHeaders} body={SitePieDataTableBody} hidePagination={true}/>
                    {/*<SimpleGrid headers={totalBreachTableHeaders} body={totalBreachTableBody} hidePagination={true}/>*/}
                    <SimpleGrid headers={LanguagePieDataHeaders} body={LanguagePieDataTableBody} hidePagination={true}/>
                    <SimpleGrid headers={AdmitedPatientLineDataHeaders} body={AdmitedPatientLineDataTableBody} hidePagination={true}/>
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }else if (tab === "group"){
            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="group">
                    {/* <StyledLineGraph data={RPMLineData} chart="group"/> */}
                    <StyledBarChart data={GroupData} chart="group"/>
                    {/* <StyledPieChart data={ActivityPieData} label="Reading Activity"/> */}
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="group" direction="row" spacing={5}>
                    <SimpleGrid headers={GroupDataHeaders} body={GroupDataTableBody} hidePagination={true}/>
                    {/* <SimpleGrid headers={TableHeaders} body={testTableBody}/> */}
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }else if (tab === "program"){
            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="program" direction="row">
                    <StyledBarChart data={ProgramData} chart="program"/>
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="program" direction="row" spacing={5}>
                    <SimpleGrid headers={ProgramsPieDataHeaders} body={ProgramsPieDataTableBody} hidePagination={true}/>
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }else if (tab === "coordinator"){
            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="coordinator">
                    {/* <StyledLineGraph data={RPMLineData} chart="coordinator"/> */}
                    <StyledBarChart data={totalBreach} chart="coordinator"/>
                    <StyledPieChart data={ActivityPieData} label="Reading Activity"/>
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="coordinator" direction="row" spacing={5}>
                    <SimpleGrid headers={TableHeaders} body={testTableBody} hidePagination={true}/>
                    <SimpleGrid headers={TableHeaders} body={testTableBody} hidePagination={true}/>
                    <SimpleGrid headers={TableHeaders} body={testTableBody} hidePagination={true}/>
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }else if (tab === "patient"){
            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="patient">
                    {/* <StyledLineGraph data={RPMLineData} chart="patient"/> */}
                    <StyledBarChart data={PatientBreach!} chart="patient" view='breaches'/>
                    <StyledBarChart data={PatientReadingData} chart="patient" view='readings'/>
                    <StyledPieChart data={PatientActivityPieData} label="Activity Complience"/>
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="patient" direction="row" spacing={5}>
                    <SimpleGrid headers={patientReadingsHeaders} body={patientReadingsTablebody} hidePagination={true}/>
                    <SimpleGrid headers={PatientReadingDataHeaders} body={PatientReadingDataTableBody} hidePagination={true}/>
                    <SimpleGrid headers={TableHeaders} body={testTableBody} hidePagination={true}/>
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }else if (tab === "devices"){
            if(exportType === 'toPDF'){
                return(
                <StyledReportContainer id="devices" direction="row" spacing={5}>
                    <StyledBarChart data={ActiveHubs} chart="devices"/>
                    <StyledPieChart data={DevicePieData} label="Devices"/>
                </StyledReportContainer>)
            }else if(exportType === 'toCSV'){
                return(
                <StyledReportContainer id="devices" direction="row" spacing={5}>
                    <SimpleGrid headers={ActiveHubsHeaders} body={ActiveHubsTableBody} hidePagination={true}/>
                    <SimpleGrid headers={DevicePieDataHeaders} body={DevicePieDataTableBody} hidePagination={true}/>
                </StyledReportContainer>)
            }else{ console.log('Error when generating tab') }
        }
    };

    const handleDateUpdate = () => {
        API.kore.readings.getForOne(targetPatient, '', '', `&sort_by=measured_at&measured_between=${startPickedDate},${endPickedDate}`)
        .then(readings=>{
        console.log('Readings for patient', readings);
        setpatientReadings(readings)
        })
    // API.kore.devices.getAll(Session.crackCookieToken('coordinator'), '-1')
    // .then(devices=>{
    //     console.log('API device list', devices)
    // })
        API.kore.notifications.getList(Session.crackCookieToken('coordinator'))
            .then(notifications=>{
            console.log('Full list of notifications', notifications)
        })
    };
    return (
        <Grid container>
            <Grid xs={12}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={config?.footerTab} onChange={handleChange}>
                            <Tab label={t('u-overview')} {...a11yProps(0)} />
                            <Tab label={t('u-group')} {...a11yProps(1)} />
                            <Tab label={t('u-program')} {...a11yProps(2)} />
                            <Tab label={t('u-patient')} {...a11yProps(3)} />
                            <Tab label={t('u-devices')} {...a11yProps(4)} />
                            {/* <Tab label={t('u-coordinator')} {...a11yProps(5)} /> */}
                        </Tabs>
                    </Box>
                    {/* Overview */}
                    <TabPanel value={config?.footerTab || 0} index={0}>
                        <StyledChartTabContainer>
                            <Stack direction={'row'}>
                                <ExportSelect option={exportType} content='overview' name='OverviewExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange}
                                />
                            </Stack>
                            {tabGenerator("overview")}
                        </StyledChartTabContainer>
                    </TabPanel>
                    {/* Group */}
                    <TabPanel value={config?.footerTab || 0} index={1}>
                        <Stack direction="row">
                            <StyledSideBar>
                                {groups.map((res: string) => {return(<StyledButton onClick={()=>{}}>{res}</StyledButton>)})}
                            </StyledSideBar>
                            <Stack>
                                <Stack direction="row">
                                    <ExportSelect option={exportType} content='group' name='GroupExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange}/>
                                </Stack>
                                {tabGenerator("group")}
                            </Stack>
                        </Stack>
                    </TabPanel>
                    {/* Program */}
                    <TabPanel value={config?.footerTab || 0} index={2}>
                        <Stack direction="row">
                            <StyledSideBar>
                                {programs.map((res: string) => {return(<StyledButton onClick={()=>{}}>{res}</StyledButton>)})}
                            </StyledSideBar>
                            <Stack>
                                <Stack direction="row">
                                    <ExportSelect option={exportType} content='program' name='ProgramExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange}/>
                                </Stack>
                                {tabGenerator("program")}
                            </Stack>
                        </Stack>
                    </TabPanel>
                    {/* Patient */}
                    <TabPanel value={config?.footerTab || 0} index={3}>
                        <Stack direction="row">
                            <StyledSideBar>
                                {provider?.memberList.map((res: RowInterface) => {return(<StyledButton onClick={()=>{updateChartInformation(res.id, 'patient')}}>{`${res.name}`}</StyledButton>)})}
                            </StyledSideBar>
                            <Stack>
                                <Stack direction={'row'}>
                                    <ExportSelect option={exportType} content='patient' name='PatientExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange} updateDate={handleDateUpdate}/>
                                </Stack>
                                {tabGenerator("patient")}
                            </Stack>
                        </Stack>
                    </TabPanel>
                    {/* Devices */}
                    <TabPanel value={config?.footerTab || 0} index={4}>
                    <StyledChartTabContainer>
                        <Stack direction={'row'}>
                            <ExportSelect option={exportType} content='devices' name='DevicesExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange}/>
                        </Stack>
                        <Stack direction="row">
                            {tabGenerator("devices")}
                        </Stack>
                    </StyledChartTabContainer>
                    </TabPanel>
                     {/* Coordinator */}
                     <TabPanel value={config?.footerTab || 0} index={5}>
                        <Stack direction="row">
                            <StyledSideBar>
                                {/* {coordinatorData?.map((res: CoordinatorObject) => {return(<StyledButton onClick={()=>{updateChartInformation(res.username, 'coordinator')}}>{res.username}</StyledButton>)})} */}
                            </StyledSideBar>
                            <Stack>
                                <Stack direction={'row'}>
                                    <ExportSelect option={exportType} content='coordinator' name='CoordinatorExport' startDate={startPickedDate} endDate={endPickedDate} startChange={handleStartDateChange} endChange={handleEndDateChange} tabChange={handleTabViewChange}/>
                                </Stack>
                                {tabGenerator("coordinator")}
                            </Stack>
                        </Stack>
                    </TabPanel>
                </Box>
            </Grid>
        </Grid>
    )
}
