import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import { Grid, Stack, FormControlLabel, Checkbox, Alert } from "@mui/material";
import Button from "components/shared/Button/Button";
import SimpleGrid, { CellProps, BodyProps} from "components/shared/SimpleGrid/SimpleGrid";
import { BasicText,
         ThresholdContainer,
         ThresholdHeaderText,
         StyledFormGroup,
         StyledButtonDiv,
         YellowAlertIcon,
         RedAlertIcon,
         StyledGrid,
    } from '../Thresholds/Thresholds.styles';
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import API from 'api';
import {useParams} from "react-router";
import Session from "helpers/session";
import { associateReadingType } from "helpers/variables";
import ThresholdLine from "./ThresholdLine";
import {GlobalContext} from "context/Provider";
import {ThresholdInterface} from 'context/initialState/Threshold'
import { useTranslation } from 'react-i18next';

interface ThresholdProps {
    type: string
}

export default function Thresholds(props: ThresholdProps){
    const { memberId } = useParams();
    const { threshold, thresholdDispatch } = useContext(GlobalContext);
    const [highThreshold, setHighThreshold] = useState<ThresholdInterface>();
    const [moderateThreshold, setModerateThreshold] = useState<ThresholdInterface>();
    const [applyDisabled,  setApply] = useState<boolean>(true);
    const [highCheck, setHigh] = useState<boolean>(false);
    const [lowCheck, setLow] = useState<boolean>(false);
    const { t } = useTranslation();
    const [thresholdList, setThresholdList]=useState([]);
    const [totalThresholdList, setTotalThresholdList]=useState<number>(0)
    const [showSuccess, setSuccess] = useState<string>('');

    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{
        API.kore.readings.getThreshold(memberId || '', associateReadingType(props.type), Session.crackCookieToken('coordinator'), `limit=${itemsPerPage}&page=${currentPage}`)
        .then(thresholds=>{
            setThresholdList(thresholds);
        })
    };

    const generateInputs = (type: string | undefined, alertLevel: 'moderate' | 'high', disabled: boolean) => {
        switch(type){
            case 'bp':
                return (
                    <Stack spacing={2}>
                        <ThresholdLine type="Systolic" alertLevel={alertLevel} units="mmHg" isDisabled={disabled}/>
                        <ThresholdLine type="Diastolic" alertLevel={alertLevel} units="mmHg" isDisabled={disabled}/>
                        <ThresholdLine type="Pulse" alertLevel={alertLevel} units="bpm" isDisabled={disabled}/>
                    </Stack>
                );
            case 'bgl':
                return (<ThresholdLine type="Glucose" alertLevel={alertLevel} units="mg/dL" isDisabled={disabled}/>);
            case 'spo2':
                return (
                    <Stack spacing={2}>
                        <ThresholdLine type="Oxygen Saturation" alertLevel={alertLevel} units="%" isDisabled={disabled}/>
                        <ThresholdLine type="Pulse" alertLevel={alertLevel} units="bpm" isDisabled={disabled}/>
                    </Stack>
                );
            case 'temp':
                return (<ThresholdLine type="Temperature" alertLevel={alertLevel} units="F" isDisabled={disabled}/>);
            case 'wt':
                return (<ThresholdLine type="Weight" alertLevel={alertLevel} units="LBS" isDisabled={disabled}/>);
            case 'hr':
                return (<ThresholdLine type="Pulse" alertLevel={alertLevel} units="bpm" isDisabled={disabled}/>);
        }
    };

    useEffect(()=>{
        thresholdDispatch({type: 'UPDATE', payload: {
            high: {
                systolic: {low: 0, high: 0},
                diastolic: {low: 0, high: 0},
                pulse: {low: 0, high: 0},
                glucose: {low: 0, high: 0},
                spo2: {low: 0, high: 0},
                weight: {low: 0, high: 0},
                temp: {low: 0, high: 0}
            },
            moderate:{
                systolic: {low: 0, high: 0},
                diastolic: {low: 0, high: 0},
                pulse: {low: 0, high: 0},
                glucose: {low: 0, high: 0},
                spo2: {low: 0, high: 0},
                weight: {low: 0, high: 0},
                temp: {low: 0, high: 0}
            }
            }});
        API.kore.readings.getThreshold(memberId || '', associateReadingType(props.type), Session.crackCookieToken('coordinator'))
            .then((thresholds)=>{
                setTotalThresholdList(thresholds.length)
                paginationAction(5, 0)

                if(thresholds.length > 0){
                    const high = thresholds.filter((T: ThresholdInterface)=>{ return T.breachType === 'high'});
                    const low = thresholds.filter((T: ThresholdInterface)=>{ return T.breachType === 'moderate'});
                    setHigh(high.length > 0);
                    setLow(low.length > 0);
                    setHighThreshold(high[0]);
                    setModerateThreshold(low[0]);
                    if(high.length > 0){
                        setHigh(true);
                        thresholdDispatch({type: 'UPDATE', payload: { high: high[0].value}})
                    }
                    if(low.length > 0){
                        setLow(true);
                        thresholdDispatch({type: 'UPDATE', payload: { moderate: low[0].value}})
                    }
                }
            })
    }, [props.type]);

    const submitThresholds = ()=>{
        if(lowCheck) {
            const sendModerate: any = {
                ...moderateThreshold,
                value: threshold?.moderate
            };
            if(!moderateThreshold){
                sendModerate.memberId = memberId;
                sendModerate.coordinator = "6671039a0483b88d7a9943c2";
                sendModerate.breachType = 'moderate';
                sendModerate.readingType = associateReadingType(props.type);
            }
            API.kore.readings.setThreshold(memberId || '', sendModerate, Session.crackCookieToken('coordinator'))
                .then(()=>{
                    setApply(true)
                    setSuccess('success');
                })
        };
        if(highCheck) {
            const sendHigh: any = {
                ...highThreshold,
                value: threshold?.high
            };
            if(!highThreshold){
                sendHigh.memberId = memberId;
                sendHigh.coordinator = "6671039a0483b88d7a9943c2";
                sendHigh.breachType = 'high';
                sendHigh.readingType = associateReadingType(props.type);
            }
            API.kore.readings.setThreshold(memberId || '', sendHigh, Session.crackCookieToken('coordinator'))
                .then(()=>{
                    setApply(true)
                    setSuccess('success');
                })
        };
        if(!lowCheck && moderateThreshold){
            API.kore.readings.removeThreshold(moderateThreshold._id || '', Session.crackCookieToken('coordinator'))
                .then(()=>{
                    setApply(true)
                    setSuccess('success');
                })
        };
        if(!highCheck && highThreshold){
            API.kore.readings.removeThreshold(highThreshold._id || '', Session.crackCookieToken('coordinator'))
                .then(()=>{
                    setApply(true)
                    setSuccess('success');
                })
        };
        
    };
    
    let thresholdTableHeaders: CellProps[] = []
    switch(associateReadingType(props.type)){
        case 'bp':
            thresholdTableHeaders.push( {content: `${t('u-systolic')} ${t('u-low')}`, align: 'center'}, {content: `${t('u-systolic')} ${t('u-high')}`, align: 'center'}, {content: `${t('u-diastolic')} ${t('u-low')}`, align: 'center'}, {content: `${t('u-diastolic')} ${t('u-high')}`, align: 'center'}, {content: `${t('u-pulse')} ${t('u-low')}`, align: 'center'}, {content: `${t('u-pulse')} ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
            break;
        case 'bgl':
            thresholdTableHeaders.push( {content: `Blood Sugar ${t('u-low')}`, align: 'center'}, {content: `Blood Sugar ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
            break;
        case 'spo2':
            thresholdTableHeaders.push( {content: `Blood Oxygen ${t('u-low')}`, align: 'center'}, {content: `Blood Oxygen ${t('u-high')}`, align: 'center'}, {content: `${t('u-pulse')} ${t('u-low')}`, align: 'center'}, {content: `${t('u-pulse')} ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
        break;
        case 'temp':
            thresholdTableHeaders.push( {content: `Temperature ${t('u-low')}`, align: 'center'}, {content: `Temperature ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
        break;
        case 'wt':
            thresholdTableHeaders.push( {content: `Weight ${t('u-low')}`, align: 'center'}, {content: `Weight ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
        break;
        case 'hr':
            thresholdTableHeaders.push( {content: `Pulse Rate ${t('u-low')}`, align: 'center'}, {content: `Pulse Rate ${t('u-high')}`, align: 'center'}, {content: t('u-status'), align: 'center'})
        break;
    }

    const generateList = (rawList: ThresholdInterface[]): BodyProps[]=>{
        return rawList.map(item=>{
            let breachIcon = <></>;
            let ajustedTableBody: CellProps[] = [];

            if (item.breachType === "high"){
                breachIcon = <RedAlertIcon/>;
            } else if (item.breachType === "moderate") {
                breachIcon = <YellowAlertIcon/>;
            };
            switch(item.readingType){
                case 'bp':
                    ajustedTableBody.push({content: `${item.value.systolic?.low}`, align: 'center'}, {content: `${item.value.systolic?.high}`, align: 'center'}, {content: `${item.value.diastolic?.low}`, align: 'center'}, {content: `${item.value.diastolic?.high}`, align: 'center'}, {content: `${item.value.pulse?.low}`, align: 'center'}, {content: `${item.value.pulse?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                    break;
                case 'bgl':
                    ajustedTableBody.push({content: `${item.value.glucose?.low}`, align: 'center'}, {content: `${item.value.glucose?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                    break;
                case 'spo2':
                    ajustedTableBody.push({content: `${item.value.spo2?.low}`, align: 'center'}, {content: `${item.value.spo2?.high}`, align: 'center'}, {content: `${item.value.pulse?.low}`, align: 'center'}, {content: `${item.value.pulse?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                break;
                case 'temp':
                    ajustedTableBody.push({content: `${item.value.temp?.low}`, align: 'center'}, {content: `${item.value.temp?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                break;
                case 'wt':
                    ajustedTableBody.push({content: `${item.value.weight?.low}`, align: 'center'}, {content: `${item.value.weight?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                break;
                case 'hr':
                    ajustedTableBody.push({content: `${item.value.pulse?.low}`, align: 'center'}, {content: `${item.value.pulse?.high}`, align: 'center'}, {content: breachIcon, align: 'center'})
                break;
            }
            return { cells: ajustedTableBody}
        })
    };

    const toggleLow = () => {
        setApply(false);
        setLow(!lowCheck)
    };
    const toggleHigh = () => {
        setApply(false);
        setHigh(!highCheck)
    };

    const handleChange = (event: ChangeEvent<HTMLElement>)=>{
        setSuccess('')
        setApply(false);
    };

    return (
        <Grid container>
            <Grid item xs={0.4} sm={1} md={1.5} lg={0.5}/>
            <Grid item xs={10.8} sm={11} md={10.2} lg={11.4}>
                <EncounterTimer></EncounterTimer>
                <BasicText>{t('s-threshold-text')}</BasicText>
                <ThresholdContainer>
                    <ThresholdHeaderText>{t('u-absolute-threshold')}</ThresholdHeaderText>
                    <StyledGrid container>
                        <Grid item lg={6}>
                            <StyledFormGroup>
                                <FormControlLabel control={<Checkbox checked={lowCheck} onChange={(e) => {toggleLow(); handleChange(e)}}/>} label={t('u-moderate-threshold')} />
                            </StyledFormGroup>
                            {generateInputs(associateReadingType(props.type), 'moderate', !lowCheck)}
                        </Grid>
                        <Grid item md={6.1} lg={6}>
                            <StyledFormGroup>
                                <FormControlLabel control={<Checkbox checked={highCheck} onChange={(e) => {toggleHigh(); handleChange(e)}}/>} label={t('u-high-threshold')} />
                            </StyledFormGroup>
                            {generateInputs(associateReadingType(props.type), 'high', !highCheck)}
                        </Grid>
                        <StyledButtonDiv>
                            <Button variant='success' onClick={submitThresholds} disabled={applyDisabled}>Apply</Button>
                        </StyledButtonDiv>
                    </StyledGrid>
                </ThresholdContainer>
                {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                <SimpleGrid headers={thresholdTableHeaders} body={generateList(thresholdList)} paginationAction={paginationAction} totalList={totalThresholdList}/>
            </Grid>
        </Grid>
    )
}
