import React, { useState } from 'react';
import { FormControlLabel, Grid, Link, InputAdornment, IconButton,  } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Button/Button';
import { StyledCheckbox, StyledLoginBox, StyledHeader, StyledInput } from './Login.styles';
import API from 'api/kore';
import { useTranslation } from 'react-i18next';

interface loginFormInterface {
    email: string;
    password: string;
    type: 'provider' | 'coordinator' | 'patient';
}

interface loginProps {
    target: 'provider' | 'coordinator' | 'patient'
}

export default function Login(props: loginProps){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [loginForm, setLoginForm] = useState<loginFormInterface>({email:'', password:'', type: props.target});
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleNameChange = (event: any)=>{
        setLoginForm({...loginForm, email: event.target.value})
    };

    const handlePasswordChange = (event: any)=>{
        setLoginForm({...loginForm, password: event.target.value})
    };

    const handleSubmit = (event: any)=>{
        event.preventDefault();
        const accessCall = props.target === 'provider' ? API.coordinator.providerAccess : API.coordinator.login;
        accessCall({...loginForm})
            .then(access=>{
                console.log('access: ', access);
                let loginDestination = '/';
                switch(props.target){
                    case 'provider':
                        loginDestination = '/provider/coordinators';
                        break;
                    case 'patient':
                        loginDestination = `/patient/vitals/2ee168121905f19916ae31f9`;
                        break;
                    default:
                        loginDestination = '/'
                }
                window.location.href = loginDestination
            }).catch(err=>{
                alert(err)
            });
    };

    return (
        <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
                <StyledLoginBox>
                    <StyledHeader>{props.target.slice(0, 1).toUpperCase()}{props.target.slice(1)} {t('u-portal')}</StyledHeader>
                    <Grid container>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                        <form onSubmit={handleSubmit}>
                            {/*<FormControlLabel control={<StyledInput size={'small'} focused/>} label={'Email'} labelPlacement={'start'}/>*/}
                            <StyledInput name="email" placeholder={t('u-email')} size={'small'} value={loginForm.email} onChange={handleNameChange}/>
                            <br/>
                            {/*<FormControlLabel control={<StyledInput size={'small'} type={'password'} focused/>} label={'Password'} labelPlacement={'start'}/>*/}
                            <StyledInput
                                name="password"
                                placeholder={t('u-password')}
                                size={'small'}
                                type={showPassword ? 'text' : 'password'}
                                value={loginForm.password}
                                onChange={handlePasswordChange}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <br/>
                            <Link href={'#'} underline={'none'}>{t('s-forgot-password')}</Link>
                            <br/>
                            <FormControlLabel control={<StyledCheckbox/>} label={t("u-remember-me")}/>
                            <br/>
                            <Button variant="success" type="submit">{t('u-sign-in')}</Button>
                        </form>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    </Grid>
                </StyledLoginBox>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    )
}
