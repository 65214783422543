export interface NotesObject {
memberId: string;
authorId: string;
readingId?: string;
authorName: string;
date?: string;
content: string;
type: string;
}

export interface NotesContextInterface {
    current: NotesObject;
    list: NotesObject[];
}

export const notesContext = {
    current: {
        memberId: '',
        authorID: '',
        authorName: '',
        date: '',
        content: '',
    },
    list: [],
}
