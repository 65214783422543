import { notificationsBodyInterface } from '../initialState/Notifications'

function notificationsReducer(state: any, {type, payload}: any){
    switch (type) {
        case 'UPDATE_NOTIFICATIONS':
            return {...state, list: payload};
        case 'UPDATE_BREACHES':
            return {...state, ...payload};
        case 'UPDATE_CURRENT':
            return {...state, currentNotification: payload};
        case 'FILTER_ACKNOWLEDGED':
            const newList = state.list.filter((notice: notificationsBodyInterface)=> notice._id !== payload);
            return {list: newList};
        case 'CLEAR_CURRENT':
            return {list: state.list};
        default:
            return state;
    }
}

export default notificationsReducer;
