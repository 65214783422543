import { Grid, FormGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import CircleIcon from '@mui/icons-material/Circle';
import RemoveIcon from '@mui/icons-material/Remove';

export const  ThresholdContainer = styled('div')(({theme})=>({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
    border: `1px solid lightGrey`,
    background: theme.palette.common.background.light,
    [theme.breakpoints.up('lg')]: {
        height: theme.spacing(38),
    },
    [theme.breakpoints.down('lg')]: {
        height: theme.spacing(65),
    },
}));

export const  ThresholdLineGrid = styled(Grid)(({theme})=>({
    paddingLeft: theme.spacing(3),
    width: theme.spacing(50),
    height: theme.spacing(6),
}));

export const  ThresholdLineItem = styled('p')(({theme})=>({
    border: `1px solid grey`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    textAlign: 'left',
    background: theme.palette.common.background.main,
}));

export const  LeftThresholdText = styled('p')(({theme})=>({
    textAlign: 'right',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
}));

export const  RightThresholdText = styled('p')(({theme})=>({
    textAlign: 'left',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
}));

export const  BasicText = styled('p')(({theme})=>({
    margin: theme.spacing(0),
    fontSize: theme.spacing(1.7),
    textAlign: 'left',
}));

export const  ThresholdHeaderText = styled('h4')(({theme})=>({
    margin: theme.spacing(0),
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
}));

export const  StyledFormGroup = styled(FormGroup)(({theme})=>({
    paddingLeft: theme.spacing(5),

}));

export const  StyledButtonDiv = styled('div')(({theme})=>({
    marginTop: theme.spacing(5),
    marginLeft: '75%'
}));

export const  ThresholdBreachContainer = styled('div')(({theme})=>({
    borderTop: `1px solid lightGrey`,
    borderLeft: `1px solid lightGrey`,
    marginBottom: theme.spacing(8),
}));

export const  YellowAlertIcon = styled(CircleIcon)(({theme})=>({
    color: theme.palette.common.background.yellow,
}));

export const  RedAlertIcon = styled(CircleIcon)(({theme})=>({
    color: theme.palette.common.background.red,
}));

export const  StyledDash = styled(RemoveIcon)(({theme})=>({
    marginTop: theme.spacing(3),
}));

export const  StyledGrid = styled(Grid)(({theme})=>({
    [theme.breakpoints.down('sm')]: {
        marginLeft: '0%',
    },
    [theme.breakpoints.up('sm')]: {
        marginLeft: '4%',
    },
    [theme.breakpoints.up('md')]: {
        marginLeft: '12%',
    },
    [theme.breakpoints.up('lg')]: {
        marginLeft: '0%',
    },
}));