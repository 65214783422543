export interface ActionsInterface {
    timestamp: string,
    type: 'note' | 'message' | 'intervention' | 'readings review' | 'profile change';
    noteContent?: string;
}

export interface EventInterface {
    memberId: string;
    memberName?: string;
    coordinatorId: string;
    coordinatorName?: string;
    duration: string;
    actions: ActionsInterface;
 }

export interface EntryInterface {
    referenceId: string;
    referenceType: 'coordinator' | 'member';
    totalDuration: string;
    escalatedDuration: string;
    interactions: EventInterface;
}

export interface EncounterInterface {
    day: string;
    touchPoints: EntryInterface;
}

 export interface EncounterContextInterface {
    current: EncounterInterface;
    list: EncounterInterface[];
}

export const encounterContext = {
    current: '',
    list: [],
}
