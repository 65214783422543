import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import { Grid } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { StyledContainer, StyledInnerContainer, StyledHeader, StyledHeaderText } from "./Notifications.styles";
import SimpleGrid, { CellProps, BodyProps} from "components/shared/SimpleGrid/SimpleGrid";
import API from 'api';
import Session from "helpers/session";
import { associateReadingType } from 'helpers/variables';
import { useTranslation } from 'react-i18next';
import {GlobalContext} from "context/Provider";
import { notificationsBodyInterface } from 'context/initialState/Notifications';

export default function Notifications(){
    const navigate = useNavigate();
    const { readingsDispatch, notifications, notificationsDispatch, memberDispatch } = useContext(GlobalContext);
    const { t } = useTranslation();

    const actionClicked = (id:string, type: string, readingId: string, memberId: string)=>{
        API.kore.readings.getSingleReading(readingId || '' , Session.crackCookieToken('coordinator'))
            .then(reading=>{
                readingsDispatch({type: 'UPDATE_READING', payload: reading});
                navigate(`/patient/history/${associateReadingType(type || 'kk').toLowerCase().replace(' ', '')}/details/${memberId}`)
            });
        API.kore.notifications.getSingle(id, Session.crackCookieToken('coordinator'))
            .then(noticeRet=>{
                notificationsDispatch({type: 'UPDATE_CURRENT', payload: noticeRet})
            });
        API.kore.members.getOne(memberId || '', Session.crackCookieToken('coordinator'))
            .then(member=>{
                memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: member})
            });
    };

    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{
        API.kore.notifications.getList(Session.crackCookieToken('coordinator'), 'acknowledged:false', `limit=${itemsPerPage}&page=${currentPage}` )
        .then(notices=>{
            console.log(notices);
            // notificationsDispatch({type: 'UPDATE_NOTIFICATIONS', payload: {cid: notices.cid, pages: notices.pages, currentPage: notices.page}})
            setNoticeList(generateList(notices.items))
        });
    };

    const generateList = (rawList: notificationsBodyInterface[]): BodyProps[]=>{
        return rawList.map(item=>{
            return { cells: [{content: <span style={{color: 'red', fontSize: '14px', fontWeight: 'bold'}}>{item.username}</span>, align: 'left'}, {content: moment(item.receivedAt).format('MMM DD, YYYY HH:mm'), align: 'left'}, {content: `${item.readingType?.toUpperCase()} ${t('u-breach')}`, align: 'left'}], action: ()=>{actionClicked(item._id,item.readingType || 'kk', item.linkedReading, item.memberId)}}
        })
       };

    const [noticeList, setNoticeList] = useState<BodyProps[]>(generateList(notifications?.list || []));
    const testTableHeaders: CellProps[] = [{content: t('u-name'), align: 'left'}, {content: t('u-received-at'), align: 'left'}, {content: t('u-details'), align: 'left'}];

    useEffect(()=>{

        if(!notifications || notifications.list.length < 1){
            API.kore.notifications.getList(Session.crackCookieToken('coordinator'), 'acknowledged:false')
                .then(notices=>{
                    notificationsDispatch({type: 'UPDATE_NOTIFICATIONS', payload: notices.items})
                });
        }
        paginationAction(5, 0)
    }, [notifications]);

    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <StyledContainer>
                    <StyledHeader><StyledHeaderText>{t('u-notifications')}</StyledHeaderText></StyledHeader>
                    <StyledInnerContainer>
                        <SimpleGrid headers={testTableHeaders} body={noticeList} paginationAction={paginationAction} totalList={notifications?.list.length || 0}/>
                    </StyledInnerContainer>
                </StyledContainer>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    )
}
