import { styled } from '@mui/material/styles'
import { Grid, TextField } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
// import { hideNote } from './EncounterTimer';


export const  EncounterTimerContainer = styled('div')(({theme})=>({
    border: `1px dashed black`,
    width: '100%',
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
}));

export const  EncounterGrid = styled(Grid)(({theme})=>({
    padding: theme.spacing(1.5),
}));

export const  EncounterText = styled('p')(({theme})=>({
    margin: theme.spacing(0),
    fontSize: theme.spacing(1.6),
}));

export const  EncounterTitleText = styled('p')(({theme})=>({
    textAlign: 'left',
    margin: theme.spacing(0),
    fontSize: theme.spacing(3),
}));
// TODO: remove icons if verbige is chosen instead
export const  StyledPlayIcon = styled(PlayArrowIcon)(({theme})=>({
    fontSize: theme.spacing(2),
}));

export const  StyledPauseIcon = styled(PauseIcon)(({theme})=>({
    fontSize: theme.spacing(2),
}));

export const  EncounterTextField = styled(TextField)(({theme})=>({
    paddingTop: theme.spacing(2),
}));
