function readingsReducer(state: any, {type, payload}: any){
    switch (type) {
        case 'UPDATE_READING':
            return {...state, ...payload};
        default:
            return state;
    }
}

export default readingsReducer;
