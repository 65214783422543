import request, {AxiosResponse} from 'axios';
import { kore_url } from './index';
import { NotesObject } from 'context/initialState/Notes';

export default {
    getList: (token: string, param?: string)=>{
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/notes${param ? param : ''}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`
            }
        };

        return request(config)
            .then((notes: AxiosResponse)=>{
                return notes.data.items
            }).catch(notesErr=>{throw notesErr})
    },
    getCSV: (token: string, filter?: string, userEmail?: string)=>{
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/notes${queries()}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`
            }
        };

        return request(config)
            .then((notes: AxiosResponse)=>{
                return notes.data
            }).catch(notesErr=>{throw notesErr})
    },
    getSingle: (id: string, token: string)=>{
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/notes/${id}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`
            }
        };
        return request(config)
            .then((encounters: AxiosResponse)=>{
                return encounters
            }).catch(encounterErr=>{throw encounterErr})
    },
    create: (note: NotesObject, token: string)=>{
        const config = {
            method: 'POST',
            url: `${kore_url}/api/v4/notes`,
            data: note,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`
            }
        };
        return request(config)
            .then((encounters: AxiosResponse)=>{
                return encounters
            }).catch(encounterErr=>{throw encounterErr})
    },
    update: (id: string, note: NotesObject, token: string)=>{
        const config = {
            method: 'PUT',
            url: `${kore_url}/api/v4/notes/${id}`,
            data: note,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`
            }
        };
        return request(config)
            .then((encounters: AxiosResponse)=>{
                return encounters
            }).catch(encounterErr=>{throw encounterErr})
    }
}
