import React, {ChangeEvent, MouseEventHandler, useContext} from 'react';
import moment from 'moment';
import { Stack } from "@mui/material";
import { StyledArrow, StyledBubble, StyledDateTitle, StyledType } from './Timeline.styles';
import API from "../../../api";
import Session from "../../../helpers/session";
import {GlobalContext} from "../../../context/Provider";
import {useNavigate} from "react-router";
import { useTranslation } from 'react-i18next';

export interface MeasurementProp {
    id: string;
    date: string;
    year?: string;
    type: string;
    value: string;
}

interface TimelineProps {
    date: string;
    measurements: MeasurementProp[];
    year: string;
}

export default function Timeline(props: TimelineProps){
    const { t } = useTranslation();
    const { date, measurements, year } = props;
    const { readingsDispatch } = useContext(GlobalContext);
    const navigate = useNavigate();
    measurements.sort((a: MeasurementProp, b: MeasurementProp)=>{
        return moment(a.date).unix() - moment(b.date).unix()
    });

    // const selectReading = (event: ChangeEvent<HTMLElement>)=>{
    const selectReading = (event: any)=>{
        API.kore.readings.getSingleReading(event.target.dataset.id || '' ,Session.crackCookieToken('coordinator'))
            .then(reading=>{
                //TODO: ensure all necessary data is present for reading details
                readingsDispatch({type: 'UPDATE_READING', payload: reading});
                const currentPath = window.location.pathname;
                navigate(currentPath.replace( 'timeline', event.target.dataset.type + '/details'))
            });
    };

    return (
        <div>
            <StyledDateTitle>{`${moment(date).format('MMM Do')}, ${year}`}</StyledDateTitle>
            {measurements.map(measurement=>{
                return (
                    <div key={`timeline-item-${measurement.id}`} data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')} onClick={selectReading}>
                        <Stack ml={4} mb={1} direction="row" alignItems="center" data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>
                            <span data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>
                                {moment(measurement.date).format('HH:mm')}
                            </span>
                            <StyledArrow data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}/>
                            <StyledBubble data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>
                                <Stack spacing={8} direction="row" justifyContent="flex-start" alignItems="center" ml={3} data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>
                                    <StyledType data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>{t(`u-${measurement.type.toLowerCase().replace(' ', '-')}`)}</StyledType>
                                    <p data-id={measurement.id} data-type={measurement.type.toLowerCase().replace(' ', '')}>{measurement.value}</p>
                                </Stack>
                            </StyledBubble>
                        </Stack>
                    </div>
                )
            })}
        </div>
    )
}
