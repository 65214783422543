import { styled } from '@mui/material/styles';
import {Box, Tabs} from "@mui/material";

export const StyledBox = styled(Box)(({theme})=>({
    width: '100%'
}));

export const StyledTabs = styled(Tabs)(({theme})=>({
    '&:last-child': {
        float: 'right'
    }
}));

export const StyledHeader = styled('div')(({theme})=>({
    position: 'relative',
    width: '100%',
    height: theme.spacing(4.5),
    backgroundColor: theme.palette.common.background.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // zIndex: '10'
}));
