import React from 'react';
import {styled} from "@mui/material";

type HRprops = {
    height?: string;
    width?: string;
    fontFamily?: string;
    fontWeight?: string;
    text: string;
    children: JSX.Element;
}

const HRcontainer: React.FC<HRprops> = ({ children, height, fontFamily, fontWeight, text, width })=>{
    const StyledContainer = styled('div')(({theme})=>({
        height: height,
        width: width || '100%'
    }));

    const StyledText = styled('p')(({theme})=>({
        fontFamily: fontFamily,
        fontWeight: fontWeight,
        textAlign: 'left'
    }));

    return (
        <StyledContainer>
            <StyledText>{text}</StyledText>
            <hr/>
            {children}
        </StyledContainer>
    )
}

export default HRcontainer;
