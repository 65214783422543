export interface RowInterface {
    id: string;
    name: string;
    email: string;
    phone: string;
    lastActivity: string;
    registerdat: string;
    actions: string;
    role?: string;
    program: string
    gender: string,
    language: string,
    group: string,
    site: string,
    status: string
}

export interface providerContextInterface {
    memberList: RowInterface[];
    coordinatorList: RowInterface[];
}

export const providerContext = {
    memberList: [],
    coordinatorList: []
};
