import StatisticsGrid, { BodyProps, HeadProps } from "components/shared/StatisticsGrid/StatisticsGrid";
import { StatisticsContainer,
         StatisticsHeaderText, 
         StyledStack, 
 } from "./Statistics.styles";
 import { useTranslation } from 'react-i18next';



export default function Statistics(){
    const { t } = useTranslation();
    const registrationsHeader: HeadProps = {title: t('u-registrations'), content: t('s-registrations-tooltip')};
    const registrationsBody: BodyProps = {total: 128, year: 29, month: 0, week: 0, day: 0 };
    const activeUserHeader: HeadProps = {title: t('u-active-users'), content: t('s-active-users-tooltip')};
    const activeUserBody: BodyProps = {total: 106, year: 36, month: 15, week: 6, day: 3 };
    const measurementsHeader: HeadProps = {title: t('u-measurements'), content: t('s-measurements-tooltip')};
    const measurementsBody: BodyProps = {total: 27847, year: 20237, month: 1305, week: 35, day: 8 };

    return(
        <StatisticsContainer>
            <StatisticsHeaderText>{t('u-statistics')}</StatisticsHeaderText>
            <StyledStack direction={'row'}>
                <StatisticsGrid header={registrationsHeader} body={registrationsBody}/>
                <StatisticsGrid header={activeUserHeader} body={activeUserBody}/>
                <StatisticsGrid header={measurementsHeader} body={measurementsBody}/>
            </StyledStack>
        </StatisticsContainer>
    )
}