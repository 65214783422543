import { StyledRadio, StyledHelpIcon } from "../../../../../components/shared/SharedStyles/SharedStyles";
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Grid, Stack, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import BloodPressure from 'assets/reading icons/Reading - Blood Pressure.svg';
import Glucose from 'assets/reading icons/Reading - Glucose.svg';
import Oxygen from 'assets/reading icons/Reading - Oxygen.svg';
import Temperature from 'assets/reading icons/Reading - Temperature.svg';
import Weight from 'assets/reading icons/Reading - Weight.svg';
import Button from "components/shared/Button/Button";
import Tooltip from '@mui/material/Tooltip';
import React, {ChangeEvent, useContext, useState, useEffect} from "react";
import {GlobalContext} from "../../../../../context/Provider";
import {ReadingContextInterface} from "../../../../../context/initialState/Readings";
import { useTranslation } from 'react-i18next';
import EncounterTimer from "components/shared/EncounterTimer/EncounterTimer";
import { memberContextInterface, memberObject } from "context/initialState/Member";
import API from 'api';
import { useNavigate } from 'react-router-dom';
import { toLocalTime } from 'helpers/variables';
import {
         MeasurementHead,
         BoldText,
         BasicDetails,
         BasicText,
         StyledText,
         UniqueDetails,
         StyledLeftGrid,
         StyledRightGrid,
         BoldDetailsText,
         StyledFormControlLabel,
         StyledTextField,
         BMIContainer,
         BMISliderContainer,
         BMISliderLabel,
         BMISliderBarBlue,
         BMISliderBarGreen,
         BMISliderBarYellow,
         BMISliderBarOrange,
         BMISliderBarPink,
         BMISliderBarRed,
         BMISliderSelectorStack,
         BMISliderSpacer,
         BMIGrid,
         FlagGrid,
         FlaggedContainer,
         StyledFlagOutlinedIcon,
         IconImage,
         } from "./Attributes.styles";
import Session from "../../../../../helpers/session";

interface AttributeProps {
    type: string;
}

const getIcon = (type: string)=>{
    switch(type){
        case 'Blood Pressure':
            return BloodPressure;
        case 'Blood Glucose':
            return Glucose;
        case 'Oxygen Saturation':
            return Oxygen;
        case 'Temperature':
            return Temperature;
        case 'Weight':
            return Weight;
    }
};

export default function Attributes (props: AttributeProps){
    const { member, readings, notifications, notificationsDispatch } = useContext(GlobalContext);
    const [flagSelect, setFlagSelect] = useState<boolean>();
    const [note, setNote] = useState<string>('');
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleRadioSelect = (event: ChangeEvent<HTMLInputElement>)=>{
        if(event.target.id === 'rad-yes'){
            setFlagSelect(true)
        }else{
            setFlagSelect(false)
        }
    };

    const handleNoteChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setNote(event.target.value)
    };

    const handleNoteSubmission = ()=>{
        //TODO: link to logged in coordinator
        const newNote = {
            memberId: member?.current.id || '',
            authorId: '6671039a0483b88d7a9943c2',
            readingId: readings?.id || '',
            noticeId: notifications?.currentNotification?._id || '',
            authorName: 'Kyle Thomas',
            content: note,
            type: 'action'
        };
        API.kore.notes.create(newNote, Session.crackCookieToken('coordinator')).then(noteRet=>{
            notificationsDispatch({type: 'FILTER_ACKNOWLEDGED', payload: notifications?.currentNotification?._id || ''});
            navigate('/notifications')
        }).catch(err=>console.log(err))
    };

    const calculatedBMI = (weight: number | undefined, member: memberContextInterface | undefined)=>{
    let pounds = weight || 0;
    let feet = member?.current.height?.feet || 0;
    let inches = member?.current?.height?.inches || 0;
    let mass = pounds / 2.205; // in kelograms
    let size = ((feet*12) + inches) / 39.37; // in meters
        return mass / (size * size);
    };
    const calculatedWidth = (weight: number | undefined, member: memberContextInterface | undefined)=>{
        const res = (calculatedBMI(weight,member) - 15) / 30 - 0.0533;
        return res * 100;
    };
    const labelBMI = ()=>{
        const BMI = calculatedBMI(readings?.metrics?.weight,member).toFixed(2);
        let label = "";
        if (parseInt(BMI) === 0){
            label = "No Data"
        }else if (parseInt(BMI) < 18.5){
            label = "Under Weight";
        }else if (parseInt(BMI) < 25){
            label = "Healthy Weight";
        }else if (parseInt(BMI) < 30){
            label = "Pre-Obese";
        }else if (parseInt(BMI) < 200){
            label = "Obese";
        }
        return label;
    }
    const dressedReading = (reading: ReadingContextInterface | undefined)=>{
        console.log(readings?.metrics)
        switch(reading?.type){
            case 'bp':
                return <><BasicText>{reading?.metrics?.systolic}/{reading?.metrics?.diastolic}</BasicText><StyledText>mmHg</StyledText><BasicText>({reading?.metrics?.pulse} </BasicText><StyledText>bpm</StyledText>)</>;
            case 'bgl':
                return <><BasicText>{reading?.metrics?.glucose}</BasicText><StyledText>mg/dL</StyledText></>;
            case 'spo2':
                return <><BasicText>{reading?.metrics?.spo2}</BasicText><StyledText>%</StyledText><BasicText>({reading?.metrics?.pulse} </BasicText><StyledText>bpm</StyledText>)</>;
            case 'Temp':
                return <><BasicText>{reading?.metrics?.temp}</BasicText><StyledText>{reading?.metrics?.unit}</StyledText></>;
            case 'spir':
                return Temperature;
            case 'hr':
                return <><BasicText>({reading?.metrics?.pulse} </BasicText><StyledText>bpm</StyledText>)</>;
            case 'wt':
                return <><BasicText>{reading?.metrics?.weight}</BasicText><StyledText>LBS</StyledText></>;
            default:
                return <div>{t('u-no-data')}</div>
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    
    return (
        <Grid container>
            <Grid item md={2} lg={0.7}/>
            <Grid item md={9} lg={11}>
                <EncounterTimer></EncounterTimer>
                <Stack spacing={1} direction={'row'}>
                    <IconImage src={getIcon(props.type)}/>
                    <MeasurementHead>{`${t(`u-${props.type.toLowerCase().replace(' ', '-')}`)} ${t('u-measurement-details')} ${toLocalTime(readings?.measuredAt)}`}</MeasurementHead>
                </Stack>
                <BasicDetails>
                    <Stack spacing={1} direction="row">
                        {dressedReading(readings)}
                    </Stack>
                </BasicDetails>
                <UniqueDetails>
                    <Grid container>
                        <StyledLeftGrid item md={12} lg={12}>
                            <Grid container>
                                <Grid item lg={2.5}/>
                                <Grid item md={4} lg={3}>
                                    <BoldDetailsText>{t('u-member-details')}</BoldDetailsText>
                                    <br/>
                                    <BoldDetailsText>{`${t('u-phone')}:`}</BoldDetailsText>
                                    <BoldDetailsText>{`${t('u-email-address')}`}</BoldDetailsText>
                                    <BoldDetailsText>{`${t('u-preferred-language')}:`}</BoldDetailsText>
                                    <BoldDetailsText>{`${t('u-site')} ${t('u-location')}:`}</BoldDetailsText>
                                    <br/>
                                    <BoldDetailsText>{t('u-device-details')}</BoldDetailsText>
                                    <br/>
                                    <BoldDetailsText>{`${t('u-name')}:`}</BoldDetailsText>
                                    <BoldDetailsText>{`${t('u-serial')}:`}</BoldDetailsText>
                                    <BoldDetailsText>{`${t('u-address')}:`}</BoldDetailsText>
                                </Grid>
                                <Grid item md={8} lg={6.5}>
                                    <br/>
                                    <br/>
                                    <BasicText>{member?.current.phone || '-'}</BasicText>
                                    <BasicText>{member?.current.email || '-'}</BasicText>
                                    <BasicText>{member?.current.language || '-'}</BasicText>
                                    <BasicText>{member?.current.site || '-'}</BasicText>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <BasicText>{readings?.metrics?.description || '-'}</BasicText>
                                    <BasicText>{readings?.metrics?.imei || '-'}</BasicText>
                                    <BasicText>{readings?.metrics?.macAddress || '-'}</BasicText>
                                </Grid>
                            </Grid>
                        </StyledLeftGrid>
                    </Grid>
                </UniqueDetails>
                {props.type === 'Weight' && (<BMIContainer>
                    <BoldDetailsText>{`BMI ${t('u-chart')}:`}</BoldDetailsText>
                    <BasicText>{t('s-enter-height')}</BasicText>
                    <BMIGrid container>
                        <Grid item xs={2}/>
                        <Grid item xs={6}>
                            <BMISliderContainer>
                                <BMISliderSelectorStack direction={'row'}>
                                    <BMISliderSpacer sx={{width: `${calculatedWidth(readings?.metrics?.weight,member)}%`}}/>
                                    <LocationOnIcon fontSize="large"/>
                                </BMISliderSelectorStack>
                            </BMISliderContainer>
                            <BMISliderContainer>
                                <Stack direction={'row'}>
                                    <BMISliderBarBlue></BMISliderBarBlue>
                                    <BMISliderBarGreen></BMISliderBarGreen>
                                    <BMISliderBarYellow></BMISliderBarYellow>
                                    <BMISliderBarOrange></BMISliderBarOrange>
                                    <BMISliderBarPink></BMISliderBarPink>
                                    <BMISliderBarRed></BMISliderBarRed>
                                </Stack>
                            </BMISliderContainer>
                            <BMISliderContainer>
                                <Stack direction={'row'}>
                                    <BMISliderLabel>20</BMISliderLabel>
                                    <BMISliderLabel>25</BMISliderLabel>
                                    <BMISliderLabel>30</BMISliderLabel>
                                    <BMISliderLabel>35</BMISliderLabel>
                                    <BMISliderLabel>40</BMISliderLabel>
                                </Stack>
                            </BMISliderContainer>
                        </Grid>
                        <Grid item xs={1.5}/>
                        <Grid item xs={2.5}>
                        <BoldDetailsText>{`BMI ${calculatedBMI(readings?.metrics?.weight,member).toFixed(2)}, ${labelBMI()}`}</BoldDetailsText>
                        </Grid>
                    </BMIGrid>
                </BMIContainer>)}
                <BasicDetails>
                    <Stack spacing={2} direction="row">
                        <BoldText>{`${t('u-cuff-dressed')}:`}</BoldText><BasicText>{t('u-ok')}</BasicText>
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <BoldText>{`${t('u-irregular-pulse')}:`}</BoldText><BasicText>{t('u-not-detected')}</BasicText>
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <BoldText>{`${t('u-movement-during-measurement')}:`}</BoldText><BasicText>{t('u-not-detected')}</BasicText>
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <BoldText>{`${t('u-pulse-range-inclusion')}:`}</BoldText><BasicText>{t('u-within')}</BasicText>
                    </Stack>
                </BasicDetails>
                <StyledFormControlLabel
                    label={t('s-add-new-comment')}
                    control={<StyledTextField placeholder={t('s-add-new-comment')} multiline minRows={4} maxRows={4} onChange={handleNoteChange} value={note}/>}
                    labelPlacement="top"
                />
                <Grid container>
                    <Grid item lg={7}/>
                    <Tooltip title={t('s-flagged-tooltip')} arrow>
                        <IconButton>
                            <StyledHelpIcon fontSize="medium"/>
                        </IconButton>
                    </Tooltip>
                    <Grid item lg={0.1}/>
                    <FlagGrid item lg={.7}>
                            <FlaggedContainer>
                                <StyledFlagOutlinedIcon></StyledFlagOutlinedIcon>
                            </FlaggedContainer>
                    </FlagGrid>
                    <FormControl>
                        <RadioGroup
                        row
                        >
                            <StyledRadio>
                            <FormControlLabel value="Yes" control={<Radio id="rad-yes" onChange={handleRadioSelect} checked={flagSelect}/>} label={t('u-yes')} />
                            </StyledRadio>
                            <StyledRadio>
                            <FormControlLabel value="No" control={<Radio id="rad-no" onChange={handleRadioSelect} checked={!flagSelect}/>} label={t('u-no')} />
                            </StyledRadio>
                        </RadioGroup>
                    </FormControl>
                    <Grid item lg={0.3}/>
                    <Button variant="success" height="48px" onClick={handleNoteSubmission}>{t('u-save')}</Button>
                </Grid>
            </Grid>
            <Grid item md={1} lg={0.3}/>
        </Grid>
    )
}
