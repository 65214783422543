import React, { MouseEventHandler, useContext, useEffect } from 'react';
import {ButtonProps, Grid, Stack} from '@mui/material';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { StyledContainer, StyledHeader, StyledButtonConatiner } from './Provider.styles';
import Button from "components/shared/Button/Button";
import Users from "./Subviews/Users";
import Managers from "./Subviews/Managers";
import API from 'api';
import { GlobalContext } from "context/Provider";
import Reports from 'views/Provider/Subviews/Reports/Reports';
import Statistics from 'views/Admin/subviews/Statistics/Statistics';
import Session from 'helpers/session';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Upsert from "./Subviews/Upsert";
import Orders from "../Orders/Orders";
import Configurations from "./Subviews/Configurations";
import notifications from 'api/kore/notifications';

interface KoreListInterface {
    cid: string;
    pages: number;
    page: number;
    items: any[]
}


export const ButtonArray = ()=>{
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userType = window.location.pathname.split('/').pop();

    const handleFilterClick = (e: MouseEventHandler<HTMLButtonElement>)=>{
        console.log(e);
        // let targetBTN: React.FC<CustomButtonProps> = document.getElementById('btn-all')!
        // targetBTN.disabled = false;
    };

    return (
        <StyledButtonConatiner container>
            <Grid item xs={1.5}><Button variant="primary" onClick={()=>{navigate(`${window.location.pathname}/new`)}}>{userType === 'members' ? t('u-new-member') : t('u-new-coordinator')}</Button></Grid>
            <Grid item xs={3}/>
            <Grid item xs={2}><Stack direction="row" spacing={1}><Button variant="info" onClick={()=>{navigate('/provider/coordinators')}}>{t('u-coordinators')}</Button><Button variant="primary" onClick={()=>{navigate('/provider/members')}}>{t('u-members')}</Button></Stack></Grid>
            <Grid item xs={3}/>
            <Grid item xs={2.5}><Stack direction="row" spacing={1}><Button id={'btn-all'} variant="info" onClick={handleFilterClick} disabled={true}>{t('u-all')}</Button><Button id={'btn-locked'} variant="primary" onClick={handleFilterClick}>{t('u-locked')}</Button><Button id={'btn-active'} variant="primary" onClick={handleFilterClick}>{t('u-active')}</Button><Button id={'btn-archive'} variant="primary" onClick={handleFilterClick}>{t('u-archive')}</Button></Stack></Grid>
        </StyledButtonConatiner>
    )
};

export default function Provider(){
    const { t } = useTranslation();
    const { providerDispatch, configDispatch, provider, devices, devicesDispatch, notificationsDispatch } =  useContext(GlobalContext);
    const removeUser = (isCoordinator: boolean) => (event: any)=>{
        if(window.confirm('Are you sure you want to archive this user?')) {
            console.log(event.target.dataset.id);
            let apiCall = API.kore.members.remove(event.target.dataset.id, Session.crackCookieToken('provider'));
            if (isCoordinator) {
                apiCall = API.kore.coordinator.remove(event.target.dataset.id, Session.crackCookieToken('provider'));
            }
            apiCall.then(removeRes=>{
                window.location.pathname = `/provider/${isCoordinator ? 'coordinator' : 'member'}s`
            })
        }
    };

    useEffect(()=>{
        API.kore.coordinator.getFullCoordList()
            .then(list=>{
                const filteredList = (list: any[])=>{
                    return list.map((item: any)=>{
                        return {
                            id: item.id,
                            email: item.email,
                            name: item.username,
                            phone: item.phone || '-',
                            role: item.role,
                            lastActivity: moment(item.updatedAt).format('MMM DD, YYYY HH:mm') || '-',
                            registerdat: moment(item.createdAt).format('MMM DD, YYYY') || '-',
                            actions: <Button dataId={item.id} variant="primary" onClick={removeUser(true)}>{t('u-archive')}</Button>
                        }
                    })
                };
                providerDispatch({type: 'UPDATE_COORD_LIST', payload:filteredList(list)})
            });
        API.kore.configurations.getConfigurations()
            .then(conf=>{
                configDispatch({type: 'OPTIONS_UPLOAD', payload: conf})
            })
        API.kore.coordinator.getFullMemberList('id,firstName,lastName,birthDate,program,condition,gender,language,height,units,group,site,status,locked,latestReading,email,phone')
            .then(list=>{
                console.log('Full members list', list);
            const filteredList = (list: KoreListInterface)=>{
                return list.items.map((item: any)=>{
                    return {
                        id: item.id,
                        email: item.email,
                        name: `${item.firstName} ${item.lastName}`,
                        phone: item.phone || '-',
                        lastactivity: moment(item.updatedAt).format('MMM DD, YYYY HH:mm') || '-',
                        registeredat: moment(item.createdAt).format('MMM DD, YYYY') || '-',
                        program: item.program,
                        gender: item.gender,
                        language: item.language,
                        group: item.group,
                        site: item.site,
                        status: item.status,
                        actions: <Button dataId={item.id} variant="primary" onClick={removeUser(false)}>{t('u-archive')}</Button>,
                    }
                })
            };
            providerDispatch({type: 'UPDATE_MEMBER_LIST', payload: filteredList(list)})
        })
          API.kore.devices.getAll(Session.crackCookieToken('provider'), '-1')
        .then(list=>{
            console.log('Full list of devices', list)
            devicesDispatch({type: 'UPDATE_DEVICES', payload: list})
        })
        API.kore.notifications.getList(Session.crackCookieToken('provider'))
        .then(notifications=>{
        console.log('Full list of notifications', notifications)
        notificationsDispatch({type: 'UPDATE_BREACHES', payload: notifications})
        }).catch(err=>console.log('notifications error'))
    }, []);
    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={10}>
                <StyledContainer>
                    <StyledHeader>{t('u-provider')}</StyledHeader>
                    <Routes>
                        <Route path="/*" element={<Users/>}/>
                        <Route path="managers" element={<Managers/>}/>
                        <Route path="managers/new" element={<Upsert type={'manager'}/>}/>
                        <Route path="reports" element={<Reports/>}/>
                        <Route path="statistics" element={<Statistics/>}/>
                        <Route path="configurations" element={<Configurations/>}/>
                        <Route path="orders" element={<Orders/>}/>
                    </Routes>
                </StyledContainer>
            </Grid>
            <Grid item xs={1}/>
        </Grid>
    )
}
