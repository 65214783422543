import React, {useContext, useEffect, useState} from 'react';
import { StyledContainer, StyledLastReadingSquare, StyledReadingText, StyledReadingInfoText } from './Vitals.styles';
import { useParams, useNavigate } from 'react-router-dom';
import API from "api";
import Session from "helpers/session";
import { formatReading } from 'helpers/variables';
import {MeasurementProp} from "components/shared/Timeline/Timeline";
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import { useTranslation } from 'react-i18next';
import {GlobalContext} from "context/Provider";

export default function Vitals(){
    const { config } = useContext(GlobalContext);
    const [patientReadings, setPatientReadings] = useState<MeasurementProp[]>([]);
    const { memberId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(()=>{
        API.kore.readings.getRecentReadings( memberId || '', Session.crackCookieToken('coordinator')).then(readings=>{
            console.log(readings)
            const displayReadings = [];
            for(let type in readings){
                //TODO: include activity in vitals
                if(readings[type] && type !== 'activity') {
                    displayReadings.push(formatReading(readings[type], config?.basicOptions.units))
                }
            };
            setPatientReadings(displayReadings);
        })
    }, []);

    const gotoReadings = (type: string)=>{
        if(type === 'Pulse Oxygen'){
            type = 'oxygensaturation'
        }
        navigate(`/patient/history/${type.toLowerCase().replace(' ', '')}/list/${memberId}`)
    };

    return (
        <StyledContainer>
            <EncounterTimer></EncounterTimer>
            {patientReadings.map(({id, type, date, value}: any)=>{
                return (
                    <StyledLastReadingSquare key={id} onClick={()=>{gotoReadings(type)}}>
                        <StyledReadingText>{t(`u-${type.toLowerCase().replace(" ","-")}`)}</StyledReadingText>
                        <StyledReadingInfoText>{value}</StyledReadingInfoText>
                        <p>{date}</p>
                    </StyledLastReadingSquare>
                )
            })}
        </StyledContainer>
    )
}
