import React, {useContext, useEffect, useState} from 'react';
import {GlobalContext} from "../../../context/Provider";
import {
    Box,
    Tabs,
    Tab,
    Typography,
    Stack
} from '@mui/material';
import { StyledItem, StyledAction } from '../Provider.styles';
import Profile from "../../PatientBoard/microViews/Profile/Profile";
import Details from "../../PatientBoard/microViews/Details/Details";
import Button from "components/shared/Button/Button";
import Session from "helpers/session";
import API from 'api';
import { RowInterface } from "context/initialState/Provider";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface AssignmentProps {
    header: string;
    list: RowInterface[];
    type: 'add' | 'subtract';
    action: (event: any, type: string)=> void;
}

interface EditProps {
    type: 'coordinator' | 'member'
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{width: '100%'}}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const AssignmentList = (props: AssignmentProps)=>{
    return (
        <div>
            <span>{props.header}</span>
            {props.list.map(patient=>{
                return <StyledItem key={patient.id}>{patient.name}<StyledAction id={patient.id} onClick={(event)=>{props.action(event, props.type)}}>{props.type === 'add' ? '+' : '-'}</StyledAction></StyledItem>
            })}
        </div>
    )
};

export default function Edit(props: EditProps){
    const { member, provider } = useContext(GlobalContext);
    const [value, setValue] = useState(0);
    const [assignedList, setAssignedList] = useState<RowInterface[]>([]);
    const [availableList, setAvailableList] = useState<RowInterface[]>([]);
    const [isDisabled, setDisabled] = useState<boolean>(true);
    let assignmentsAdded = [];
    let assignmentsRemoved = [];
    let coordDictionary = {};

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleCoordinatorToggle = (event: any, type: string)=>{
        provider?.coordinatorList.map((coord: RowInterface)=>{
            // @ts-ignore
            coordDictionary[coord.id] = coord;
        });
        // move from one column to another
        setDisabled(false);
        const targetCoordinator = event.target.id;
        if(type === 'add'){
            setAvailableList(availableList.filter(coord=>{
                return coord.id !== targetCoordinator
            }));
            // @ts-ignore
            setAssignedList(assignedList.concat([coordDictionary[targetCoordinator]]))
        }else{
            setAssignedList(assignedList.filter(coord=>{
                return coord.id !== targetCoordinator
            }));
            // @ts-ignore
            setAvailableList(availableList.concat([coordDictionary[targetCoordinator]]))
        }
    };

    const applyChanges = ()=>{
        if(props.type === 'coordinator'){
            console.log('lols')
        }else {
            const sendList = assignedList.map(item=>{
                return item.id
            });
            // console.log('New Coordinators: ', sendList)
            API.kore.members.updateAssociations(member?.current.id || '', sendList, Session.crackCookieToken('provider'))
                .then(update => {
                    console.log(update)
                    setDisabled(true)
                });
        }
    };

    useEffect(()=>{
        API.kore.members.getAssociations(member?.current.id || '', Session.crackCookieToken('provider'))
            .then(update => {
                let eliminate = {};
                let available: string[] = [];
                let assigned: string[] = update;
                provider?.coordinatorList.map((coord: RowInterface)=>{
                    // @ts-ignore
                    coordDictionary[coord.id] = coord;
                    available.push(coord.id)
                });
                // @ts-ignore
                const fullAssigned = assigned.map(coord=>{eliminate[coord] = true;return coordDictionary[coord];});
                const fullAvailable = available.map(coord=>{
                    // @ts-ignore
                    if(!eliminate[coord]) {
                        // @ts-ignore
                        return coordDictionary[coord]
                    }
                });
                setAssignedList(fullAssigned);
                setAvailableList(fullAvailable.filter(C=>{return C !== undefined}));
            })
    }, [props.type]);

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Profile" {...a11yProps(0)}/>
                <Tab label="Details" {...a11yProps(1)}/>
                {window.location.pathname.split('/')[2] === 'members' ? <Tab label="Manage Members" {...a11yProps(2)}/> : ''}
            </Tabs>
            <TabPanel value={value} index={0}>
                <Profile type={props.type} action="edit"/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Details type={props.type} action="edit"/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Stack direction="row" spacing={4} textAlign='left'>
                    <AssignmentList header="Currently Assigned" list={assignedList} action={handleCoordinatorToggle} type="subtract"/>
                    <AssignmentList header="Available Coordinators" list={availableList} action={handleCoordinatorToggle} type="add"/>
                </Stack>
                <Button variant="primary" onClick={applyChanges} disabled={isDisabled}>Apply</Button>
            </TabPanel>
        </Box>
    )
}
