import { styled } from '@mui/material/styles';
import { TableContainer, Grid, TableCell, Stack } from '@mui/material';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    justifyContent: 'left',
    minWidth: 530,
}));

export const StyledHeader = styled('div')(({theme})=>({
    justifyContent: 'center',
    width: '100%',
    height: '5.75%',
}));

export const StyledTableContainer = styled(TableContainer)(({theme})=>({
    border: `1px solid grey`,
}));

export const StyledLightGreyTableHeader = styled(Grid)(({theme})=>({
    background: theme.palette.common.background.light,
    borderTop: `1px solid grey`,
    borderBottom: `1px solid grey`,
    borderRight: `1px solid grey`,    
}));

export const StyledWhiteTableHeader = styled(Grid)(({theme})=>({
    background: theme.palette.common.background.white,
    borderBottom: `1px solid grey`,
    borderTop: `1px solid grey`,   
}));

export const StyledGreyTableCell = styled(TableCell)(({theme})=>({
    background: theme.palette.common.background.main,
    padding: theme.spacing(0),
           
}));

export const StyledWhiteTableCell = styled(TableCell)(({theme})=>({
    borderRight: `1px solid grey`,
    borderBottom: `1px solid grey`,
    padding: theme.spacing(0),
    paddingRight: theme.spacing(14), 
    background: theme.palette.common.background.white,   
   
}));

export const StyledGridChild = styled(Grid)(({theme})=>({
    borderTop: `1px solid grey`,
}));

export const StyledGridChildSeperator = styled(Grid)(({theme})=>({
    borderTop: `1px solid grey`,
    borderLeft: `1px solid grey`,
}));

export const StyledLightGreyCheck = styled(Grid) (({theme})=>({
    borderLeft: `1px solid grey`,
    background: theme.palette.common.background.light,
}));

export const StyledWhiteCheck = styled(Grid) (({theme})=>({
    borderLeft: `1px solid grey`,
}));

export const StyledBodyTableCell = styled(TableCell) (({theme})=>({
   padding: theme.spacing(0),
}));

export const StyledStack = styled(Stack)(({theme})=>({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    justifyContent: 'left',
    width: '100%',
}));