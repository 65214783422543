import React, {ChangeEvent, useState} from 'react';
import {
    StyledContainer,
    StyledHeader,
    StyledTableContainer,
    StyledDescription,
    StyledTableHeader,
    StyledGreyTableCell,
    StyledWhiteTableCell,
    StyledStack
} from '../Notifications/Notifications.styles';
import {
    Checkbox,
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    FormControlLabel,
    Alert,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'components/shared/Button/Button';

interface CheckboxProps extends HTMLElement {
    id: string,
    'data-indeterminate': boolean,
    class: string,
}

export default function Notifications(){
    const { t } = useTranslation();
    const [showSuccess, setSuccess] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);
    const [ checkboxState,  setCheckboxState ] = useState({
        'onm-notifications-all': false,
        'onm-email-all': false,
        'oet-email-all': false,
        'onm-notifications-blood-glucose': false,
        'onm-email-blood-glucose': false,
        'oet-email-blood-glucose': false,
        'onm-notifications-blood-pressure': false,
        'onm-email-blood-pressure': false,
        'oet-email-blood-pressure': false,
        'onm-notifications-heart-rate': false,
        'onm-email-heart-rate': false,
        'oet-email-heart-rate': false,
        'onm-notifications-respiration-rate': false,
        'onm-email-respiration-rate': false,
        'oet-email-respiration-rate': false,
        'onm-notifications-oxygen-saturation': false,
        'onm-email-oxygen-saturation': false,
        'oet-email-oxygen-saturation': false,
        'onm-notifications-spirometry': false,
        'onm-email-spirometry': false,
        'oet-email-spirometry': false,
        'onm-notifications-temperature': false,
        'onm-email-temperature': false,
        'oet-email-temperature': false,
        'onm-notifications-weight': false,
        'onm-email-weight': false,
        'oet-email-weight': false,
    });

    const tableRows = [t('u-blood-glucose'), t('u-blood-pressure'), t('u-heart-rate'), t('u-respiration-rate'), t('u-oxygen-saturation'), t('u-spirometry'), t('u-temperature'), t('u-weight')];

    const checkboxChange = (event: ChangeEvent<any>): void => {
        setCheckboxState({...checkboxState, [event.target.id]: event.target.checked})
        setSuccess('');
        setChanged(false);
    };

    const submitNotifications = () => {
        //TODO: API call to update notifications for member
        console.log('Apply Clicked');
        console.log(checkboxState);
        setSuccess('success');
        setChanged(true);
    };

    return (
        <StyledContainer>
             <Grid container>
                <Grid item sm={0} md={1.2} lg={.75}/>
                <Grid item sm={12} md={10.8} lg={11}>
                    <StyledHeader><h2>{t('u-notifications')}</h2></StyledHeader>
                    <StyledDescription>
                        <hr/>
                        <p>{t('p-notifications-head')}</p>
                    </StyledDescription>
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={3}><h4>{t('u-measurement-type')}</h4></TableCell>
                                    <StyledGreyTableCell align="center" colSpan={4}>
                                        <h4>{t('u-on-new-measurement')}</h4>
                                        <StyledTableHeader container>
                                            <Grid item xs={6}>
                                                <FormControlLabel control={<Checkbox id={'onm-notifications-all'} onChange={checkboxChange}/>} label={t('u-notifications')} />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormControlLabel control={<Checkbox id={'onm-email-all'} onChange={checkboxChange}/>} label={t('u-email')}/>
                                            </Grid>
                                        </StyledTableHeader>
                                    </StyledGreyTableCell>
                                    <StyledWhiteTableCell align="center" colSpan={5}>
                                        <h4>{t('u-on-exceeding-the-threshold')}</h4>
                                        <StyledTableHeader>
                                            <FormControlLabel control={<Checkbox id={'oet-email-all'} onChange={checkboxChange}/>} label={t('u-email')}/>
                                        </StyledTableHeader>
                                    </StyledWhiteTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableRows.map((row)=>{
                                    return <TableRow key={row.toLowerCase().replace(' ', '-')}>
                                        <TableCell align="left" colSpan={3}>{row}</TableCell>
                                        <StyledGreyTableCell align="center" colSpan={4}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <Checkbox id={`onm-notifications-${row.toLowerCase().replace(' ', '-')}`} onChange={checkboxChange}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Checkbox id={`onm-email-${row.toLowerCase().replace(' ', '-')}`} onChange={checkboxChange}/>
                                                </Grid>
                                            </Grid>
                                        </StyledGreyTableCell>
                                        <TableCell align="center" colSpan={5}><Checkbox id={`oet-email-${row.toLowerCase().replace(' ', '-')}`} onChange={checkboxChange}/></TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                    <StyledStack>
                        <Button variant="success" onClick={submitNotifications} disabled={isDisabled}>{t('u-apply')}</Button>
                        {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                    </StyledStack>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}
