import { styled } from '@mui/material/styles';
import { TableContainer, TableCell, Grid, Stack } from '@mui/material';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    justifyContent: 'left',
    minWidth: 567,
}));

export const StyledHeader = styled('div')(({theme})=>({
    width: '20%',
    paddingLeft: theme.spacing(2),
    textAlign: 'left', 
}));

export const StyledTableContainer = styled(TableContainer)(({theme})=>({
    margin: theme.spacing(0),
    border: `1px solid grey`,
}));

export const StyledDescription = styled('div')(({theme})=>({
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    textAlign: 'left',
}));

export const StyledTableHeader = styled(Grid)(({theme})=>({
    background: theme.palette.common.background.main,
    borderTop: `1px solid grey`,  
    marginBottom: theme.spacing(-2), 
}));

export const StyledGreyTableCell = styled(TableCell)(({theme})=>({
    borderLeft: `1px solid grey`,
    borderRight: `1px solid grey`,
    background: theme.palette.common.background.light,
    padding: theme.spacing(0),    
}));

export const StyledWhiteTableCell = styled(TableCell)(({theme})=>({
    paddingLeft: theme.spacing(0), 
    paddingRight: theme.spacing(0), 
}));

export const StyledStack = styled(Stack)(({theme})=>({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    justifyContent: 'left',
    width: '100%',
}));