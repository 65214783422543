import React, {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Autocomplete,
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import { StyledContainer, StyledTextField, StyledSubHeader } from './SmartDevices.styles';
import Button from 'components/shared/Button/Button';
import ErrorDialog from 'components/shared/ErrorDialog/ErrorDialog';
import {GlobalContext} from "context/Provider";
import Loader from 'components/shared/Loader/Loader';
import API from "api";
import Session from "helpers/session";

interface deviceInterface {
    serialnumber: string;
    user: string;
    type: string;
    assigned: boolean;
    orderDate: boolean;
    lot: string;
}

export default function Devices(){
    const [Global, setGlobal] = useState(GlobalContext);
    const { member, memberDispatch } = useContext(GlobalContext);
    const [isCleared , setIsCleared] = useState(true);
    const [actionMessage, setActionMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isValidImeI, setIsValidImeI] = useState(true);
    const [programs, setPrograms] = useState([]);
    const [ImeI, setImeI] = useState<string>('');
    const [value, setValue] = useState('program 1');
    const [availableDevices, setAvailableDevices] = useState([]);
    const [showSuccess, setSuccess] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);

    const submissionClicked = (e: any) => {
        e.preventDefault();
        setSuccess('success');
        setChanged(true);
        if(!isValidImeI) {
            setErrorMessage(`${<ErrorDialog title={'Information Error'} message={'IMEI number not found, please check number and try again'}/>}`)
        }else if(e.target.program.value.length < 2 && isCleared){
            setErrorMessage(`${<ErrorDialog title={'Missing Information'} message={'Please select a program'}/>}`)
        }else{
            setActionMessage('Job has been sent to Smart Device');
            if (isCleared) {
                // API.linkSmartDevice(e.target.imei.value, e.target.program.value, Global.clientId)
                //     .then(ret=>{
                //         //Creates member record in Sinope DB
                //         const memberBody = {
                //             client_id: Global.clientId,
                //             member_id: Global.memberId,
                //             member_email: Global.memberEmail,
                //             member_name: Global.memberName,
                //             imei: ImeI};
                //         API.createNewMember(memberBody)
                //             .then(mem=>{
                //                 setActionMessage('');
                //                 setIsCleared(false);
                //                 window.location.href = Global.home
                //             })
                //             .catch(err=>{
                //                 setErrorMessage(<ErrorDialog title={'Missing Information'} message={'Please ensure the patient has a valid email in their profile and try again'}/>)
                //             })
                //     }).catch(err=>{
                //         setErrorMessage(<ErrorDialog title={'Information Error'} message={'Please check the IMEI number and try again'}/>)
                //     });
            } else {
                // API.clearSmartDevice(Global.imei, Global.clientId)
                // .then(ret=>{
                //     setActionMessage('');
                //     setIsCleared(true);
                // }).catch(err=>console.log('Clear Error: ', err));
                // API.removeMember(Global.memberId)
                // .then(ret=>{
                //     window.location.href = Global.home
                // }).catch(err=>console.log(err))
            }
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> )=>{
        setValue(event.target.value);
        setSuccess('');
        setChanged(false);
    };

    const helperTextRender = ()=>{
        return isValidImeI ? '' : 'IMEI Number too short'
    };

    const checkImeI = ()=>{
        return ImeI.length > 13 ? true : false
    };

    const renderList = ()=>{
        if(programs){
            return programs.map((program)=>{
                // return <FormControlLabel key={program.id} value={program.id} control={<Radio color="default"/>} label={program.name} />
            })
        }else{
            return <Loader/>
        }
    };

    const disenrollHandler = ()=>{
        // if(confirm(`Disenrolling this member will permanently remove them from the portal and will schedule the device assigned to them for deactivation, this cannot be undone.  Are you sure you would like to proceed?`)) {
        //     API.clearSmartDevice(Global.imei, Global.clientId)
        //         .then(ret=>{
        //             setActionMessage('');
        //             setIsCleared(true);
        //         }).catch(err=>console.log('Clear Error: ', err));
        //     API.removeMember(Global.memberId).then(deletedMember=>{
        //         API.disenroll(Global.imei, Global.clientId, deletedMember.member_id)
        //             .then(()=>{
        //                 window.location.href = Global.ret.split('/en/')[0]
        //             }).catch(disErr=>console.log(disErr))
        //     }).catch(err=>console.log(err));
        // }
    };

    useEffect(()=>{
        API.kore.devices.getUnassignedInventory(Session.crackCookieToken('coordinator'), 'smart').then(deviceListRet=>{
            console.log(deviceListRet);
            const deviceList = deviceListRet.map((device: deviceInterface)=>{
                return device.serialnumber.toUpperCase()
            });
            setAvailableDevices(deviceList)
        }).catch(deviceErr=>console.log(deviceErr));
    },[]);

    return(
        <div>
            <Grid container>
                <Grid item xs={3}/>
                <Grid item xs={6}>
                    <StyledContainer>
                    <h1>{isCleared ? 'Configure' : 'Clear'} Smart Device</h1>
                    <form onSubmit={submissionClicked}>
                        <StyledSubHeader>Smart Device IMEI:</StyledSubHeader>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            renderInput={(params)=> <TextField {...params} id="imei" variant="outlined" label={'Add Device'} helperText={helperTextRender()} error={!isValidImeI} onChange={(e: any) => {setImeI(e.target.value); setIsValidImeI(checkImeI())}} fullWidth={true} value={ImeI}/>}
                            options={availableDevices}
                        />
                        <StyledSubHeader>Member ID:</StyledSubHeader>
                        <StyledTextField id="memberID" label={member?.current.id} variant="outlined" fullWidth={true} disabled/>
                        <StyledSubHeader>Member Name:</StyledSubHeader>
                        <StyledTextField id="memberName" label={`${member?.current.firstName} ${member?.current.lastName}`} variant="outlined" fullWidth={true} disabled/>
                        <br/>
                        <br/>
                        <br/>
                        {actionMessage ? <h2 className={'kardia-text-green life365-text'}>{actionMessage}</h2> : ''}
                        <br/>
                        <Grid container>
                            <Grid item xs={0}/>
                            <Grid item xs={3}>
                                <Button
                                variant={'primary'}
                                type={'submit'} disabled={isCleared ? isDisabled : false}>{isCleared ? 'Configure' : 'Clear'}
                                </Button>
                            </Grid>
                            <Grid item xs={2}/>
                            <Grid item xs={3}>
                                <Button
                                    variant={'primary'}
                                    // onClick={()=>{setGlobal({...Global, pageRender: 'mad'})}}
                                >Cancel</Button>
                            </Grid>
                            <Grid item xs={4}/>
                        </Grid>
                        {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                        {/* {Global.clientId === 'amr-nm' || Global.clientId === 'unmh' ? <Button className={'disenroll-button'} variant={'contained'} color={'warning'} onClick={disenrollHandler} disabled={isCleared}>Disenroll</Button> : ''} */}
                    </form>
                    </StyledContainer>
                </Grid>
                <Grid item xs={3}/>
            </Grid>
        </div>
        )
    }
