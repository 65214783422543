import React from 'react';

export default function Surveys(){
    return (
        <div>
            {/*<iframe src={'https://respiratory.prd.life365inc.com/#'} style={{width: '100%', height: '600px'}}/>*/}
            <iframe src={'https://phs.avixena.com'} style={{width: '100%', height: '600px'}}/>
        </div>
    )
}
