// @ts-nocheck
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {StyledContainer,
        StyledHeader,
        StyledTableContainer,
        StyledWhiteTableCell,
        StyledLightGreyTableHeader,
        StyledGreyTableCell,
        StyledWhiteTableHeader,
        StyledGridChild,
        StyledGridChildSeperator,
        StyledWhiteCheck,
        StyledLightGreyCheck,
        StyledBodyTableCell,
        StyledStack
    } from '../Options/Options.styles';
import { Alert, Checkbox, FormControlLabel, Grid, Table, TableBody,TableRow, TableHead} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'components/shared/Button/Button';
import { snakeToCamel } from 'helpers/variables';
import {GlobalContext} from "context/Provider";

export default function Options(){
    const { config, configDispatch } = useContext(GlobalContext);
    const { t } = useTranslation();
    const optionsRows = ['activity', 'blood-glucose', 'blood-pressure', 'heart-rate', 'respiration-rate', 'oxygen-saturation', 'spirometry', 'temperature', 'weight', 'member-notes'];
    const [ options, setOptions ] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [changes, setChanges] = useState(true);
    const [showSuccess, setSuccess] = useState<string>('');

    const checkboxChange = (event: ChangeEvent<any>): void => {
        setChanges(false);
        setSuccess('');
        setOptions({...options, [event.target.id]: event.target.checked})
    };

    const checkAllChange = (event: ChangeEvent<any>): void => {
        let finalAll = {}
        setChanges(false);
        setSuccess('');
        optionsRows.map(opt=>{
            finalAll[`${event.target.id}${snakeToCamel(opt)}`] = event.target.checked
        });
        setOptions({...options, ...finalAll})
    };

    const submitOptions = () => {
        let finalDisplay = {};
        let finalPreview = {};
        let finalChart = {};
        for(let key in options){
            const newKey = key.split('-');
            if(newKey[0] === 'menu') {
                finalDisplay[newKey[1]] = options[key]
            }
            if(newKey[0] === 'preview'){
                finalPreview[newKey[1]] = options[key]
            }
            if(newKey[0] === 'chart'){
                finalChart[newKey[1]] = options[key]
            }
        }
        configDispatch({type: 'UPDATE_DISPLAY', payload: finalDisplay});
        configDispatch({type: 'UPDATE_PREVIEW', payload: finalPreview});
        configDispatch({type: 'UPDATE_CHART', payload: finalChart});
        setChanges(true);
        setSuccess('success');
    };

    useEffect(()=>{
        let checkBoxObj = {};
        for(let key in config?.displayOptions){
            checkBoxObj[`menu-${key}`] = config?.displayOptions[key]
        }
        for(let key in config?.previewOptions){
            checkBoxObj[`preview-${key}`] = config?.previewOptions[key]
        }
        for(let key in config?.chartOptions){
            checkBoxObj[`chart-${key}`] = config?.chartOptions[key]
        }
        setOptions(checkBoxObj);
        setTimeout(()=>{
            setLoaded(true)
        }, 100)
    }, []);
    return (
        <StyledContainer>
            <Grid container>
                <Grid item sm={0} md={1.5} lg={0.5}/>
                <Grid item sm={12} md={10} lg={11}>
                    <StyledHeader><h2>{t('u-display-options')}</h2></StyledHeader>
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledWhiteTableCell align="center" colSpan={5}><h4>{t('u-measurement-type')}</h4></StyledWhiteTableCell>
                                    <StyledGreyTableCell align="center" colSpan={7}>
                                                <h4>{t('u-show-in')}</h4>
                                        <Grid container>
                                            <StyledLightGreyTableHeader item xs={3.02}>
                                                <FormControlLabel control={<Checkbox id={'menu-'} onChange={checkAllChange}/>} label={t('u-menu')} />
                                            </StyledLightGreyTableHeader>
                                            <StyledWhiteTableHeader item xs={8.98}>
                                                <h4>{t('u-dashboard')}</h4>
                                                <Grid container>
                                                    <StyledGridChild item xs={6.65}>
                                                        <FormControlLabel control={<Checkbox id={'preview-'} onChange={checkAllChange}/>} label={t('u-as-preview')}/>
                                                    </StyledGridChild>
                                                    <StyledGridChildSeperator item xs={5.35}>
                                                        <FormControlLabel control={<Checkbox id={'chart-'} onChange={checkAllChange}/>} label={t('u-as-chart')}/>
                                                    </StyledGridChildSeperator>
                                                </Grid>
                                            </StyledWhiteTableHeader>
                                        </Grid>
                                    </StyledGreyTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {optionsRows.map((row)=>{
                                    return <TableRow key={row.toLowerCase().replace(' ', '-')+ '-options'}>
                                        <StyledBodyTableCell align="left" colSpan={5} sx={{pl: '20px'}}>{t(`u-${row}`)}</StyledBodyTableCell>
                                        <StyledBodyTableCell align="center" colSpan={7}>
                                            <Grid container>
                                                <StyledLightGreyCheck item xs={3}>
                                                    {loaded && <Checkbox id={`menu-${snakeToCamel(row)}`} checked={options[`menu-${snakeToCamel(row)}`]} onChange={checkboxChange}/>}
                                                </StyledLightGreyCheck>
                                                <StyledWhiteCheck item xs={5}>
                                                    {loaded && <Checkbox id={`preview-${snakeToCamel(row)}`} checked={options[`preview-${snakeToCamel(row)}`]} onChange={checkboxChange}/>}
                                                </StyledWhiteCheck>
                                                <StyledWhiteCheck item xs={4}>
                                                    {loaded && <Checkbox id={`chart-${snakeToCamel(row)}`} checked={options[`chart-${snakeToCamel(row)}`]} onChange={checkboxChange}/>}
                                                </StyledWhiteCheck>
                                            </Grid>
                                        </StyledBodyTableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                    <StyledStack>
                        <Button variant="success" onClick={submitOptions} disabled={changes}>{t('u-apply')}</Button>
                        {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                    </StyledStack>
                </Grid>
                <Grid item xs={0.5}/>
            </Grid>
        </StyledContainer>
    )
}
