import { styled } from '@mui/material/styles'
import { TableRow, Stack } from "@mui/material";
import TableHead, { tableHeadClasses } from "@mui/material/TableHead"

export const StyledContainer = styled('div')(({theme})=>({
    border: `1px solid grey`,
}));

export const StyledRow = styled(TableRow)(({theme})=>({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.common.background.light,
    },
    '&:hover':{
        backgroundColor: `${theme.palette.common.background.main} !important`
    }
}));

export const StyledHeaderRow = styled(TableRow)(({theme})=>({
    border: `2px solid ${theme.palette.common.background.main}`
}));

export const StyledErrorContainer = styled(Stack)(({theme})=>({
    marginBottom: '10%',
    marginTop: '10%',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.common.checkbox.main
}));
