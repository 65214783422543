// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Button, Grid, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { GridColDef, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid';
import {
         StyledMainContainer,
         StyledHeader,
         StyledFont,
         StyledDataGrid,
         StyledLeftMemberToolTipGrid,
         StyledRightMemberToolTipGrid,
         StyledMemberToolTipContainer,
         StyledMemberToolTipText,
         StyledMeasurementToolTip,
         StyledDot
         } from './Main.styles';
import { GlobalContext } from "context/Provider";
import { memberObject } from 'context/initialState/Member';
import API from 'api';
import Session from "helpers/session";
import { toLocalTime } from 'helpers/variables';
import { useTranslation } from 'react-i18next';
import { formatReading } from 'helpers/variables';
import LocalHospitalOutlinedIcon from '@mui/icons-material/HealthAndSafety'; //import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { StyledReadingText, StyledReadingInfoText, StyledLastReadingSquare } from 'views/PatientBoard/microViews/Vitals/Vitals.styles';
import {host, socket_url} from "../../api/kore";
import io from "socket.io-client";


interface rowItem {
    id: string;
    member: string | JSX.Element;
    lastMeasurement: string | JSX.Element;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

export default function Main(){
    const socket = io(socket_url);
    const { memberDispatch, notificationsDispatch, configDispatch } = useContext(GlobalContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [memberRows, setMemberRows] = useState<memberObject[]>([]);
    const navigate = useNavigate();

    const { t } = useTranslation();
    // Text has been coded for automatic translation using i18nexus. Nameing conventions for this will be as follows:
    // u-(text)-(text) is for words that all start with a capital letter ie. u-happy-feelings = Happy Feelings
    // s-(text)-(text) is for a sentence or paragraph. The text will give context to what the paragraph is about ie. s-take-reading could translate into "And now it is time to take a reading"

    const callRefresh = ()=>{
        API.kore.members.getAll(Session.crackCookieToken('coordinator')).then(ret=>{
            memberDispatch({type: 'UPDATE_LIST', payload: ret.items});
            setMemberRows(ret.items);
            setLoading(false)
        });
        API.kore.notifications.getList(Session.crackCookieToken('coordinator'), 'acknowledged:false')
            .then(notices=>{
                notificationsDispatch({type: 'UPDATE_NOTIFICATIONS', payload: notices.items})
            });
        API.kore.configurations.getConfigurations()
            .then(conf=>{
                configDispatch({type: 'OPTIONS_UPLOAD', payload: conf})
            })
    };

    const gotoReadings = (type: string, id: string, name: string)=>{
        memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: {name: name, id: id}});
        memberDispatch({type: 'UPDATE_SECONDS', payload: 0});
        memberDispatch({type: 'UPDATE_START', payload: moment()});
        navigate(`/patient/history/${type.toLowerCase().replace(' ', '')}/list/${id}`)
    };

    const formatRows = (memberList: memberObject[]) => {
        const rows: rowItem[] = [];
        let count = 0;
        if(memberList) {
            memberList.map((item: memberObject) => {
                rows.push({
                    id: item.id,
                    member: item,
                    lastMeasurement: { readingsArray: item.latestReading ? [item.latestReading] : [], memberItem: item }
                });
                count++
            });
        }
        return rows;
    };
    const columns: GridColDef[] = [
        {field: 'admitted', headerName: <LocalHospitalOutlinedIcon/>, width: 100, flex: 100, sortable: false, filterable: false,
            renderCell: (item)=>{
            const { status } = item.row.member
                return (
                    <div>
                        {status === 'admitted' ? <HtmlTooltip title="The member is currently under hospital care"><LocalHospitalOutlinedIcon/></HtmlTooltip> : ''}
                    </div>
                )
            }
        },
        {field: 'member', headerName: t('u-members'), width: 150, flex: 500,
            renderCell: (item)=>{
            const { firstName, lastName, site, program, group, externId, hubIds } = item.row.member;
            return (
                <StyledFont>{
                    <HtmlTooltip title={
                        <React.Fragment>
                            <StyledMemberToolTipContainer>
                                <div>
                                    <Grid container>
                                        <StyledLeftMemberToolTipGrid item xs={4}>
                                            <StyledMemberToolTipText>Coordinator:</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>Program:</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>Group:</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>MRN:</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>Site:</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>IMEI:</StyledMemberToolTipText>
                                        </StyledLeftMemberToolTipGrid>
                                        <StyledRightMemberToolTipGrid item xs={6.7}>
                                            <StyledMemberToolTipText>Rory Thomas</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>{program ? program : '...'}</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>{group ? group : '...'}</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>{externId ? externId : '...'}</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>{site ? site : '...'}</StyledMemberToolTipText>
                                            <StyledMemberToolTipText>{hubIds && hubIds.length > 0 ? hubIds.toString().replace(',', ' & ').toUpperCase() : '...'}</StyledMemberToolTipText>
                                        </StyledRightMemberToolTipGrid>
                                    </Grid>
                                </div>
                                <div>
                                </div>
                            </StyledMemberToolTipContainer>
                        </React.Fragment>} placement="right-start"><Button>{`${firstName} ${lastName}`}</Button>
                    </HtmlTooltip>
                }</StyledFont>
            )},
            valueGetter: (item) => `${item.row.member.firstName} ${item.row.member.lastName} ${item.row.member.site} ${item.row.member.program} ${item.row.member.group} ${item.row.member.externId} ${item.row.member.hubIds && item.row.member.hubIds.length > 0 ? item.row.member.hubIds.toString() : ''}`
        },
        {field: 'lastMeasurement', headerName: t('u-last-measurement'), width: 260, flex: 500, filterable: false,
            renderCell: (params)=>{
            const { readingsArray, memberItem } = params.row.lastMeasurement;
            return (
                <StyledFont>
                    <StyledTooltip title={
                        <React.Fragment>
                            <StyledMeasurementToolTip>
                                {readingsArray.map((toolReading: any)=>{
                                    const {id, type, date, value} = formatReading(toolReading);
                                    return (
                                        <StyledLastReadingSquare key={id} onClick={()=>{gotoReadings(type, memberItem.id, `${memberItem.firstName} ${memberItem.lastName}`)}}>
                                            <StyledReadingText>{t(`u-${type.toLowerCase().replace(" ","-")}`)}</StyledReadingText>
                                            <StyledReadingInfoText>{value}</StyledReadingInfoText>
                                            <p>{date}</p>
                                        </StyledLastReadingSquare>
                                    )
                                })}
                            </StyledMeasurementToolTip>
                        </React.Fragment>} placement="right-start">
                        <div onClick={()=>{ console.log(toLocalTime(readingsArray[0].measuredAt))} }>
                            {readingsArray[0] ? `${readingsArray[0].type}, ${toLocalTime(readingsArray[0].measuredAt)}` : '...' }
                        </div>
                    </StyledTooltip>
                </StyledFont>
            )
            },
            valueGetter: (params) => params.row.lastMeasurement.readingsArray[0] ? moment(params.row.lastMeasurement.readingsArray[0].measuredAt) : moment('01-01-1900')
        },
        {field: 'breach', headerName: t('u-breach'), width: 100, flex: 500, sortable: false, filterable: false,
            renderCell: (item)=>{
            let breached = false;
            if(item.row.member.latestReading) {
                breached = item.row.member.latestReading.breached;
            }
            return (
                <div>
                    {breached ? <HtmlTooltip title="The most recent reading for this member has breached it's threshold" arrow><StyledDot/></HtmlTooltip> : ''}
                </div>
            )
            }
        }
        ];

    const goToMemberPage = (cell: any)=>{
        memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: {name: cell.row.member, id: cell.row.id}});
        memberDispatch({type: 'UPDATE_SECONDS', payload: 0});
        memberDispatch({type: 'UPDATE_START', payload: moment()});
        navigate('/patient/vitals/' + cell.row.id);
    };

    useEffect(()=>{
        callRefresh();
        // websocket listening for data updates
        socket.on(`${host[0]}-data-updates`, ()=>{
            callRefresh();
        });
    }, []);



    return (
        <Grid container>
            <Grid item xs={1}/>
            <Grid item xs={11}>
                <StyledHeader>{`${t('u-members')} ${t('u-list')}`}</StyledHeader>
                <StyledMainContainer>
                    <StyledDataGrid
                        rows={(memberRows) ? formatRows(memberRows) : []}
                        columns={columns}
                        components={{Toolbar: ()=>{
                                return <GridToolbarContainer style={{justifyContent: 'flex-end'}}><GridToolbarColumnsButton/><GridToolbarFilterButton/><GridToolbarDensitySelector/></GridToolbarContainer>
                            }}}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        onCellClick={goToMemberPage}
                        initialState={{
                            sorting: {
                                sortModel: [{field: 'lastMeasurement', sort: 'desc'}]
                            },
                            columns: {
                                columnVisibilityModel: {
                                    // Hide columns status and traderName, the other columns will remain visible
                                    breach: false,
                                },
                            },
                        }}
                        loading={loading}
                    />
                </StyledMainContainer>
            </Grid>
        </Grid>
    )
}
