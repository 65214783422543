import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

export const  ResourceLink = styled(Link)(({theme})=>({
    color: theme.palette.common.text.blue,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

export const  StyledDiv = styled('div')(({theme})=>({
    width: '85%',
    textAlign: "left",
    border: `1px solid grey`,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(10),
    background: theme.palette.common.background.main,
}));

