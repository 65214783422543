function thresholdReducer(state: any, {type, payload}: any){
    switch(type.toUpperCase()){
        case 'MODERATE_SYSTOLIC_LOW':
            return {...state, moderate:{...state.moderate, systolic:{...state.moderate.systolic, low: payload}}};
        case 'MODERATE_SYSTOLIC_HIGH':
            return {...state, moderate:{...state.moderate, systolic:{...state.moderate.systolic, high: payload}}};
        case 'MODERATE_DIASTOLIC_LOW':
            return {...state, moderate:{...state.moderate, diastolic:{...state.moderate.diastolic, low: payload}}};
        case 'MODERATE_DIASTOLIC_HIGH':
            return {...state, moderate:{...state.moderate, diastolic:{...state.moderate.diastolic, high: payload}}};
        case 'MODERATE_PULSE_LOW':
            return {...state, moderate:{...state.moderate, pulse:{...state.moderate.pulse, low: payload}}};
        case 'MODERATE_PULSE_HIGH':
            return {...state, moderate:{...state.moderate, pulse:{...state.moderate.pulse, high: payload}}};
        case 'MODERATE_GLUCOSE_LOW':
            return {...state, moderate:{...state.moderate, glucose:{...state.moderate.glucose, low: payload}}};
        case 'MODERATE_GLUCOSE_HIGH':
            return {...state, moderate:{...state.moderate, glucose:{...state.moderate.glucose, high: payload}}};
        case 'MODERATE_WEIGHT_LOW':
            return {...state, moderate:{...state.moderate, weight:{...state.moderate.weight, low: payload}}};
        case 'MODERATE_WEIGHT_HIGH':
            return {...state, moderate:{...state.moderate, weight:{...state.moderate.weight, high: payload}}};
        case 'MODERATE_OXYGEN_SATURATION_LOW':
            return {...state, moderate:{...state.moderate, spo2:{...state.moderate.spo2, low: payload}}};
        case 'MODERATE_OXYGEN_SATURATION_HIGH':
            return {...state, moderate:{...state.moderate, spo2:{...state.moderate.spo2, high: payload}}};
        case 'MODERATE_TEMPERATURE_LOW':
            return {...state, moderate:{...state.moderate, temp:{...state.moderate.temp, low: payload}}};
        case 'MODERATE_TEMPERATURE_HIGH':
            return {...state, moderate:{...state.moderate, temp:{...state.moderate.temp, high: payload}}};
        case 'HIGH_SYSTOLIC_LOW':
            return {...state, high:{...state.high, systolic:{...state.high.systolic, low: payload}}};
        case 'HIGH_SYSTOLIC_HIGH':
            return {...state, high:{...state.high, systolic:{...state.high.systolic, high: payload}}};
        case 'HIGH_DIASTOLIC_LOW':
            return {...state, high:{...state.high, diastolic:{...state.high.diastolic, low: payload}}};
        case 'HIGH_DIASTOLIC_HIGH':
            return {...state, high:{...state.high, diastolic:{...state.high.diastolic, high: payload}}};
        case 'HIGH_PULSE_LOW':
            return {...state, high:{...state.high, pulse:{...state.high.pulse, low: payload}}};
        case 'HIGH_PULSE_HIGH':
            return {...state, high:{...state.high, pulse:{...state.high.pulse, high: payload}}};
        case 'HIGH_GLUCOSE_LOW':
            return {...state, high:{...state.high, glucose:{...state.high.glucose, low: payload}}};
        case 'HIGH_GLUCOSE_HIGH':
            return {...state, high:{...state.high, glucose:{...state.high.glucose, high: payload}}};
        case 'HIGH_WEIGHT_LOW':
            return {...state, high:{...state.high, weight:{...state.high.weight, low: payload}}};
        case 'HIGH_WEIGHT_HIGH':
            return {...state, high:{...state.high, weight:{...state.high.weight, high: payload}}};
        case 'HIGH_OXYGEN_SATURATION_LOW':
            return {...state, high:{...state.high, spo2:{...state.high.spo2, low: payload}}};
        case 'HIGH_OXYGEN_SATURATION_HIGH':
            return {...state, high:{...state.high, spo2:{...state.high.spo2, high: payload}}};
        case 'HIGH_TEMPERATURE_LOW':
            return {...state, high:{...state.high, temp:{...state.high.temp, low: payload}}};
        case 'HIGH_TEMPERATURE_HIGH':
            return {...state, high:{...state.mhigh, temp:{...state.high.temp, high: payload}}};
        case 'UPDATE':
            return {...state, ...payload};
        default:
            return state;
    }
}

export default thresholdReducer;
