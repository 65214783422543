import { Grid, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StatisticsInfoContainer = styled('div')(({theme}) => ({
    height: theme.spacing(29.9),
    width: theme.spacing(20),
    marginLeft: theme.spacing(6),
    border: `1px solid lightgrey`,
    background: theme.palette.common.background.white,
}));

export const CellHeader = styled('div')(({theme}) => ({
    background: theme.palette.common.background.main,
    fontWeight: 'bold',
    fontSize: theme.spacing(1.8),
    padding: theme.spacing(1.4),
    textAlign: 'left',
}));

export const StyledTooltip = styled(Tooltip)(({theme}) => ({
    padding: theme.spacing(0),
    paddingTop: theme.spacing(3),
    height: theme.spacing(1),
    marginBottom: theme.spacing(0),
}));
export const CellLeftWhite = styled(Grid)(({theme}) => ({
    background: theme.palette.common.background.white,
    borderTop: `1px solid lightgrey`,
    borderRight: `1px solid lightgrey`,
    height: theme.spacing(5),
    textAlign: 'left',
    padding: theme.spacing(.6),
}));

export const CellRightWhite = styled(Grid)(({theme}) => ({
    background: theme.palette.common.background.white,
    borderTop: `1px solid lightgrey`,
    textAlign: 'left',
    padding: theme.spacing(.6),
    height: theme.spacing(5),
}));

export const CellLeftGrey = styled(Grid)(({theme}) => ({
    background: theme.palette.common.background.light,
    borderTop: `1px solid lightgrey`,
    borderRight: `1px solid lightgrey`,
    height: theme.spacing(5),
    textAlign: 'left',
    padding: theme.spacing(.6),
}));

export const CellRightGrey = styled(Grid)(({theme}) => ({
    background: theme.palette.common.background.light,
    borderTop: `1px solid lightgrey`,
    textAlign: 'left',
    padding: theme.spacing(.6),
    height: theme.spacing(5),
}));