import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, {useState} from 'react'


interface ErrorProps {
    title: string,
    message: string,
}
// Error Dialog that can be generated for any issue.  Takes 'title' and 'message' as props
export default function ErrorDialog(props: ErrorProps){
    const [open, setOpen] = useState(true);
    
    const handleClose = () => {
       
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    )
}