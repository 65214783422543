import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import SideNavBar from "views/PatientBoard/microViews/SideNavBar/SideNavBar";

  export default function SharedDrawer() {
    const [open, setState] = useState(false);
  
    const toggleDrawer = (open: boolean | ((prevState: boolean) => boolean)) => (event: any) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
      setState(open);
    };

    return (
      <AppBar position="static" sx={{width: '0%'}}>
        <Container maxWidth="lg" disableGutters={true} sx={{backgroundColor:'#ffffff', }}>
          <Toolbar>    
              <IconButton 
                edge="start" 
                color="info" 
                aria-label="open drawer" 
                onClick={toggleDrawer(true)}
                sx={{ 
                  display: {
                    xs: 'block',
                    md: 'none',
                  }
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
              >
                  <Box sx={{
                    p: 0,
                    height: 1,
                    backgroundColor: "#ffffff",
                  }}>
                    <IconButton sx={{mb: 2}}>
                      <CloseIcon onClick={toggleDrawer(false)} />
                    </IconButton> 
                    <Divider sx={{mb: 2}} /> 
                    <Box sx={{mb: 2}}>
                      <SideNavBar></SideNavBar>
                    </Box>
                    <Box sx={{
                      display: "flex", 
                      justifyContent:"center", 
                      position: "absolute", 
                      bottom: "0", 
                      left: "50%", 
                      transform: "translate(-50%, 0)"}}
                    >
                    </Box>
                  </Box>              
              </Drawer>
            </Toolbar>
        </Container>
      </AppBar>
    );
  }