// @ts-nocheck
import React, {ChangeEvent, useContext} from 'react'
import {LeftThresholdText, RightThresholdText, StyledDash, ThresholdLineGrid} from "./Thresholds.styles";
import {Stack, TextField} from "@mui/material";
import {GlobalContext} from "context/Provider";
import { Tvalues, ThresholdContextInterface, thresholdContext } from "context/initialState/Threshold";

interface LineProps {
    type: string;
    alertLevel: 'moderate' | 'high';
    units: string;
    isDisabled: boolean;
}

export default function ThresholdLine (props: LineProps) {
    const {type, alertLevel, units, isDisabled} = props;
    const { threshold, thresholdDispatch } = useContext(GlobalContext);

    const typeCast = (type: string)=>{
        if(type === 'Oxygen Saturation'){
            return 'spo2'
        } else if(type === 'Temperature'){
            return 'temp'
        }else{
            return type
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>)=>{
        thresholdDispatch({type: event.target.name, payload: event.target.value})
    };
    return (
        <ThresholdLineGrid container>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <LeftThresholdText>{type}</LeftThresholdText>
                <TextField name={`${alertLevel}_${type.replace(' ', '_')}_low`} onChange={handleChange} type="number" value={threshold[alertLevel as keyof ThresholdContextInterface][typeCast(type).toLowerCase() as keyof Tvalues] ? threshold[alertLevel as keyof ThresholdContextInterface][typeCast(type).toLowerCase() as keyof Tvalues].low : 1} disabled={isDisabled} size="small"/>
                <StyledDash/>
                <TextField name={`${alertLevel}_${type.replace(' ', '_')}_high`} onChange={handleChange} type="number" value={threshold[alertLevel as keyof ThresholdContextInterface][typeCast(type).toLowerCase() as keyof Tvalues] ? threshold[alertLevel as keyof ThresholdContextInterface][typeCast(type).toLowerCase() as keyof Tvalues].high : 1} disabled={isDisabled} size="small"/>
                <RightThresholdText>{units}</RightThresholdText>
            </Stack>
        </ThresholdLineGrid>
    )
};
