interface UnitsInterface {
    general: string;
    glucose: string;
}

interface HeightInterface {
    feet: number;
    inches: number;
}

export interface memberObject {
    id: string;
    email: string;
    externId: string;
    firstName: string;
    lastName: string;
    userName: string;
    program?: string;
    phone?: string;
    group?: string;
    site?: string;
    birthDate?: string;
    gender?: string;
    language?: string;
    condition?: string;
    height?: HeightInterface;
    weight?: number;
    status?: string;
    units?: UnitsInterface;
    locked?: boolean;
    latestReading?: string;
    mostRecentReading: object;
}

export interface memberContextInterface {
    cid?: string;
    current: memberObject;
    isCoordinator: boolean;
    url: string | null;
    list: memberObject[]
    items: memberObject[]
    encounterStart: string;
    secondsCounter: number;
}

export const memberContext = {
    current: {
        id: '',
        email: '',
        externId: '',
        firstName: '',
        lastName: '',
        userName: '',
    },
    isCoordinator: false,
    url: '',
    list: [],
    encounterStart: '00:00',
    secondsCounter: 0
};
