import { styled } from '@mui/material/styles'
import SimpleGrid from "../../components/shared/SimpleGrid/SimpleGrid";

export const StyledHeader = styled('div')(({theme})=>({
    height: theme.spacing(3),
    width: '100%',
    background: theme.palette.primary.light,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    paddingTop: theme.spacing(0.5),
}));

export const StyledContainer = styled('div')(({theme})=>({
    border: `1px solid grey`,
}));

export const StyledInnerContainer = styled('div')(({theme})=>({
    padding: theme.spacing(5)
}))

export const StyledHeaderText = styled('span')(({theme})=>({
    marginLeft: theme.spacing(1)
}));

export default { StyledContainer, StyledHeader, StyledHeaderText };
