import { styled } from '@mui/material/styles';
import { Stack, TextField, FormControlLabel } from '@mui/material';
import TagsInputer from "../../../../components/shared/TagsInput/TagsInputer";

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    justifyContent: 'left',
    width: '100%'
}));

export const StyledHeader = styled('div')(({theme})=>({
    justifyContent: 'left',
    width: '100%'
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    width: '80%',
    paddingLeft: theme.spacing(1)
}));

export const StyledTagField = styled(TagsInputer)(({theme})=>({
    width: '100%',
    paddingLeft: theme.spacing(1)
}));

export const StyledStack = styled(Stack)(({theme})=>({
    justifyContent: 'left',
    width: '100%'
}));

export const FormTagLabel = styled(FormControlLabel)(({theme})=>({
    // width: '100%',
    paddingLeft: '10%',
    // justifyContent: 'left',
}));

export default { StyledContainer, StyledHeader, StyledTextField, StyledStack };
