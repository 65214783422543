import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, TextField } from '@mui/material';
import { BootstrapDialog, StyledTextTimeStamp, StyledTextCoordinatorName, StyledDialogContent, StyledDialogActions, StyledTextDetailsLabel } from './DialogModel.styles';
import theme from 'helpers/mui-theme.helper';
export type CustomDialogProps= {
 coordinatorName: string;
 coordinatorId: string;
 patientName: string;
 patientId: string;
 date: string;
 content: string;
 isNote: boolean;
}
export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}
const CustomizedDialogs: React.FC<CustomDialogProps> = ({coordinatorName, coordinatorId, patientName, patientId, date, content, isNote})=>{
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button variant='text' size={'small'} onClick={handleClickOpen} sx={{color: "black", margin: 0, width: theme.spacing(!isNote ? 20 : 60), textAlign: 'left', wordBreak: 'break-all' }}>
      {isNote ? content : 
      <div>
        <StyledTextDetailsLabel>{coordinatorName}</StyledTextDetailsLabel>
        <StyledTextDetailsLabel>{date}</StyledTextDetailsLabel>
      </div>
      } 
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          {patientName}
        </BootstrapDialogTitle>
        <StyledDialogContent dividers>
            <Stack direction='row'>
              <b>Coordinator Name:</b>
              <StyledTextCoordinatorName>{coordinatorName}</StyledTextCoordinatorName>
            </Stack>
            <Stack direction='row'>
              <b>Time:</b>
              <StyledTextTimeStamp>{date}</StyledTextTimeStamp>     
            </Stack>
            <Stack>
              <h3>Note:</h3>
              <TextField disabled={true} value={content} multiline={true} variant={"outlined"}></TextField>
            </Stack>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button autoFocus onClick={handleClose}>
            Done
          </Button>
        </StyledDialogActions>
      </BootstrapDialog>
    </div>
  );
}
export default CustomizedDialogs;
