import { styled } from '@mui/material/styles';
import { Checkbox, TextField } from '@mui/material';

export const StyledLoginBox = styled('div')(({theme})=>({
    width: '100%',
    height: theme.spacing(40),
    border: `1px solid ${theme.palette.common.checkbox.main}`,
    background: theme.palette.common.background.light,
    textAlign: 'left',
}));

export const StyledHeader = styled('div')(({theme})=>({
    color: 'white',
    background: theme.palette.primary.light,
    width: '100%',
    height: theme.spacing(3),
    textAlign: 'center',
    fontWeight: 'bolder',
    paddingTop: theme.spacing(0.5),
}));

export const StyledInput = styled(TextField)(({theme})=>({
    marginTop: theme.spacing(2),
    width: '100%',
    background: 'white',
}));

export const StyledCheckbox = styled(Checkbox)(({theme})=>({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.common.checkbox.main,
}));

export default {StyledCheckbox, StyledHeader, StyledLoginBox, StyledInput};
