// @ts-nocheck
import React, {ChangeEvent, useContext, useState} from 'react';
import Button from "components/shared/Button/Button";
import {
    Alert,
    Checkbox,
    FormControlLabel,
    Stack,
    Tooltip,
    TextField,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { styled } from '@mui/material/styles'
import {StyledTextField} from "../Admin.styled";
import RadioGenerator from "components/shared/RadioGenerator/RadioGenerator";
import {GlobalContext} from "context/Provider";
import {usStates} from "helpers/variables";
import API from 'api';

interface RadioSelectInterface {
    bulk: string;
    ticket: string;
}

interface itemSubInterface {
    name: string;
    partNum: string;
}

interface minorSubInterface {
    bpm: itemSubInterface;
    bgm: itemSubInterface;
    scale: itemSubInterface;
    pulseOx: itemSubInterface;
    thermometer: itemSubInterface;
    spirometer: itemSubInterface;
}

type NewMember = 'production' | 'demo' | 'none';

const StyledButton = styled(Button)(({theme})=>({
    marginLeft: theme.spacing(10)
}));

function LineBreak(){
    return (
        <div style={{width: '100%'}}>
            <hr/>
        </div>
    )
}

const determineTicketType = (type: string | undefined)=>{
    switch(type){
        case 'production':
            return 'production kit ticket';
        case 'demo':
            return 'demo kit ticket';
        case 'production kit ticket':
            return 'production';
        case 'demo kit ticket':
            return 'demo';
        case 'none':
            return 'no ticket';
        default:
            return 'none';
    }
};

export default function Ordering(){
    const { config, configDispatch } = useContext(GlobalContext);
    const [showSuccess, setSuccess] = useState<string>('');
    const [custom, setCustom] = useState<boolean>(config?.orderOptions.customOrder || false);
    const [radioSelections, setRadoiSelections] = useState<RadioSelectInterface>({bulk: config?.orderOptions.bulkUploads ? 'yes' : 'no', ticket: determineTicketType(config?.orderOptions.newMember)});
    const [accessories, setAccessories] = useState<string[]>([]);
    const [documents, setDocuments] = useState<string[]>([]);
    const [accessName, setAccessName] = useState<string>('');
    const [docName, setDocName] = useState<string>('');
    const [bulkAddress, setBulkAddress] = useState<string>({
        address: config?.orderOptions.bulkDestination.address || '',
        city: config?.orderOptions.bulkDestination.city || '',
        state: config?.orderOptions.bulkDestination.state || '',
        zip: config?.orderOptions.bulkDestination.zip || '',
        phone: config?.orderOptions.bulkDestination.phone || '',
        email: config?.orderOptions.bulkDestination.email || ''
    });
    const [devices, setDevices] = useState<minorSubInterface>({
        bpm: config?.orderOptions.orderDetails.bpm || '',
        bgm: config?.orderOptions.orderDetails.bgm || '',
        scale: config?.orderOptions.orderDetails.scale || '',
        pulseOx: config?.orderOptions.orderDetails.pulseOx || '',
        thermometer: config?.orderOptions.orderDetails.thermometer || '',
        spirometer: config?.orderOptions.orderDetails.spirometer || '',
    });

    const addDocument = ()=>{
        console.log('Name: ', docName);
        setDocuments(documents.concat([docName]));
        setDocName('')
    };
    const addAccessory = ()=>{
        setAccessories(accessories.concat([accessName]));
        setAccessName('')
    };

    const updateAccessoryName = (event: ChangeEvent<HTMLInputElement>)=>{
        setAccessName(event.target.value)
    };

    const updateDocName = (event: ChangeEvent<HTMLInputElement>)=>{
        setDocName(event.target.value)
    };

    const handleSelectChange = (event: SelectChangeEvent)=>{
        setBulkAddress({...bulkAddress, state: event.target.value})
    };
    const handleDeviceChange = (event: ChangeEvent<HTMLInputElement>)=>{
        const title = event.target.name.split('-');
        let value = {};
        if(title[1] === 'name'){
            value = {name: event.target.value, partNum: devices[title[0]].partNum}
        }else{
            value = {name: devices[title[0]].name, partNum: event.target.value}
        }
        setDevices({...devices, [title[0]]: value});
        // props.submit({...devices, [event.target.name]: event.target.value})
    };
    const handleAddressChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setBulkAddress({...bulkAddress, [event.target.name]: event.target.value})
    };

    const handleSubmission = (event: any) => {
        const { bulk, ticket, custom, address, city, state, zip, phone } = event.target;
        event.preventDefault();
        setSuccess('success');
        const formBody = {
            bulkUploads: bulk.value === 'yes' ? true : false,
            newMember: determineTicketType(ticket.value) as NewMember,
            customOrder: custom.checked,
            orderDetails: devices,
            bulkDestination: {
                address: address ? address.value : '',
                city: city ? city.value : '',
                state: state? state.value : '',
                zip: zip ? zip.value : '',
                phone: phone ? phone.value : ''
            },
            // shippingFacility:{
            //     address: '',
            //     city: '',
            //     state: '',
            //     zip: ''
            // }
        };

        API.kore.configurations.updateOrderingConfig({...config?.orderOptions, ...formBody})
            .then(configs=>{
                configDispatch({type: 'UPDATE_ORDERS', payload: {...config?.orderOptions, ...formBody}})
            }).catch(err=>console.log(err))
    };

    const toggleCustom = ()=>{
        setCustom(!custom)
    };

    const handleRadioChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setRadoiSelections({...radioSelections, [event.target.name]: event.target.value})
    };
    return (
        <form onSubmit={handleSubmission}>
            <Stack alignItems="flex-start" spacing={2} ml={15}>
                <br/>
                <FormControlLabel labelPlacement="start" label="Allow for bulk orders" control={<RadioGenerator name="bulk" onChange={handleRadioChange} inputArray={['Yes', 'No']} value={radioSelections.bulk}/>}/>
                {radioSelections.bulk === 'yes' && (<Stack direction="row" spacing={2}>
                    <TextField name="address" label="address" type="text" value={bulkAddress.address} onChange={handleAddressChange}/>
                    <TextField name="city" label="city" type="text" value={bulkAddress.city} onChange={handleAddressChange}/>
                    {/*<TextField name="state" label="state" type="text"/>*/}
                    <Select name="state" label="State" sx={{height: '55px'}} onChange={handleSelectChange} value={bulkAddress.state} variant="outlined" required>
                        {usStates.map(S=>{
                            return <MenuItem value={S}>{S}</MenuItem>
                        })}
                    </Select>
                    <TextField name="zip" label="zip" type="text" value={bulkAddress.zip} onChange={handleAddressChange}/>
                    <TextField name="phone" label="phone" type="text" value={bulkAddress.phone} onChange={handleAddressChange}/>
                    <TextField name="email" label="notification email" type="text" value={bulkAddress.email} onChange={handleAddressChange}/>
                </Stack>)}
                <Tooltip title="Select type of freshdesk ticket to be created upon addition of a new member " placement="right" arrow>
                    <FormControlLabel labelPlacement="start" label="New Member Ticket" control={<RadioGenerator name="ticket" onChange={handleRadioChange} inputArray={['Demo Kit ticket', 'Production Kit ticket', 'No ticket']} value={radioSelections.ticket}/>}/>
                </Tooltip>
                <Tooltip title="Orders will default to a basic ordering package with pre-selected devices.  Customizing the ordering allows you to define the specific part numbers of devices that are sent out for this client." placement="right" arrow>
                    <FormControlLabel labelPlacement="start" label="Customize Ordering" control={<Checkbox name="custom" value={custom} defaultChecked={custom} onChange={toggleCustom}/>}/>
                </Tooltip>
                {custom && (<Stack alignItems="flex-start" spacing={3} style={{width: '100%'}}>
                    <LineBreak/>
                    <h4>Item Part Numbers:</h4>
                    <FormControlLabel labelPlacement="start" label="Blood Pressure Monitor" control={<div><StyledTextField name="bpm-name" type="text" label="part name" placeholder="name" size="small" value={devices.bpm.name} onChange={handleDeviceChange}/><StyledTextField name="bpm-#" type="text" label="part number" placeholder="Item part #" size="small" value={devices.bpm.partNum} onChange={handleDeviceChange}/></div>}/>
                    <FormControlLabel labelPlacement="start" label="Weight Scale" control={<div><StyledTextField name="scale-name" type="text" label="part name" placeholder="name" size="small" value={devices.scale.name} onChange={handleDeviceChange}/><StyledTextField name="scale-#" type="text" label="part number" placeholder="Item part #" size="small" value={devices.scale.partNum} onChange={handleDeviceChange}/></div>}/>
                    <FormControlLabel labelPlacement="start" label="Pulse Oxygen Sensor" control={<div><StyledTextField name="pulseOx-name" type="text" label="part name" placeholder="name" size="small" value={devices.pulseOx.name} onChange={handleDeviceChange}/><StyledTextField name="pulseOx-#" type="text" label="part number" placeholder="Item part #" size="small" value={devices.pulseOx.partNum} onChange={handleDeviceChange}/></div>}/>
                    <FormControlLabel labelPlacement="start" label="Blood Glucose Monitor" control={<div><StyledTextField name="bgm-name" type="text" label="part name" placeholder="name" size="small" value={devices.bgm.name} onChange={handleDeviceChange}/><StyledTextField name="bgm-#" type="text" label="part number" placeholder="Item part #" size="small" value={devices.bgm.partNum} onChange={handleDeviceChange}/></div>}/>
                    <FormControlLabel labelPlacement="start" label="Thermometer" control={<div><StyledTextField name="thermometer-name" type="text" label="part name" placeholder="name" size="small" value={devices.thermometer.name} onChange={handleDeviceChange}/><StyledTextField name="thermometer-#" label="part number" type="text" placeholder="Item part #" size="small" value={devices.thermometer.partNum} onChange={handleDeviceChange}/></div>}/>
                    <FormControlLabel labelPlacement="start" label="Spirometer" control={<div><StyledTextField name="spirometer-name" type="text" label="part name" placeholder="name" size="small" value={devices.spirometer.name} onChange={handleDeviceChange}/><StyledTextField name="spirometer-#" type="text" label="part number" placeholder="Item part #" size="small" value={devices.spirometer.partNum} onChange={handleDeviceChange}/></div>}/>
                    <br/>
                    <h4>Accessory Part Numbers:</h4>
                    {accessories.map(accessory=>{
                        return <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label={accessory} control={<StyledTextField name={accessory.replace(' ', '-')} type="text" placeholder="Accessory part #" size="small"/>}/><Button variant={'warning'} onClick={addAccessory}>-</Button></Stack>
                    })}
                    <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label="Add Accessory" control={<StyledTextField onChange={updateAccessoryName} value={accessName} type="text" placeholder="Accessory name" size="small"/>}/><Button variant={'primary'} onClick={addAccessory}>+</Button></Stack>
                    <br/>
                    <h4>Documentation:</h4>
                    {documents.map(document=>{
                        return <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label={document} control={<StyledTextField name={document.replace(' ', '-')} type="text" placeholder="Accessory part #" size="small"/>}/><Button variant={'warning'} onClick={addAccessory}>-</Button></Stack>
                    })}
                    <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label="Add Document" control={<StyledTextField onChange={updateDocName} value={docName} type="text" placeholder="Document name" size="small"/>}/><Button variant={'primary'} onClick={addDocument}>+</Button></Stack>
                    {/*<Select name="emails" label="Additional contacts for orders" sx={{height: '55px'}} onChange={handleSelectChange} value={CC} variant="outlined" required>*/}
                    {/*    {usStates.map(S=>{*/}
                    {/*        return <MenuItem value={S}>{S}</MenuItem>*/}
                    {/*    })}*/}
                    {/*</Select>*/}
                    <LineBreak/>
                </Stack>)}
                <Button variant="success" type="submit">Apply</Button>
                {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
            </Stack>
        </form>
    )
}
