import { Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PieChartContainer = styled(Stack)(({theme})=>({
    border: `1px solid lightGrey`,
    width: "320px",
}));

export const PieChartText = styled('p')(({theme})=>({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    fontSize: theme.spacing(1.5),
}));
export const PieChartBoldText = styled('h5')(({theme})=>({
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    marginTop: theme.spacing(0.5),
}));
