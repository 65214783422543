import { styled } from '@mui/material/styles';
import { Button, Link, Stack } from '@mui/material';


export const StyledSideBar = styled('div')(({theme})=>({
    backgroundColor: theme.palette.common.background.light,
    maxHeight: theme.spacing(80),
    overflow: "scroll",
}));

export const StyledReportContainer = styled(Stack)(({theme})=>({
flexWrap: "wrap",
justifyContent: "center", 
border: `1px solid lightGrey`,
margin: theme.spacing(1),
}));

export const StyledChartTabContainer = styled('div')(({theme})=>({
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
})); 

export const StyledButton = styled(Button)(({theme})=>({
    position: 'relative',
    zIndex: '2',
    color: theme.palette.common.text.offBlack,
    textDecoration: 'none',
    height: theme.spacing(5),
    width: theme.spacing(30),
    border: `1px solid ${theme.palette.common.checkbox.main}`,
    paddingTop: '0%',
    paddingBottom: '0%',
    fontWeight: 'bold',
    fontFamily: 'Helvetica',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    overflow: 'auto',
    justifyContent: 'center',
    '&:hover':{
        background: theme.palette.common.background.main,
    }
}));

export const StyledSelectRadio = styled('div')(({theme})=>({
    background: theme.palette.common.background.light,
    border: `1px solid lightGrey`,
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(.5),
}));

export default { StyledSelectRadio, StyledButton, StyledReportContainer, StyledSideBar, StyledChartTabContainer };