import { Grid, Stack, TextField } from "@mui/material";
import Button from "../Button/Button";
import { useTranslation } from 'react-i18next';
import { EncounterText, EncounterGrid, EncounterTimerContainer, EncounterTitleText, StyledPlayIcon, StyledPauseIcon, EncounterTextField } from '../EncounterTimer/EnounterTimer.styles';
import {useContext, useEffect, useState} from "react";
import {GlobalContext} from "../../../context/Provider";
import moment from 'moment';
import { useTheme } from '@mui/material/styles';

export let hideNote = 'none';
export default function EncounterTimer(){
    const theme = useTheme();
    const { member, memberDispatch } = useContext(GlobalContext);
    const [pause, setPause] = useState<boolean>(false);
    const [note, setNote] = useState<boolean>(false);
    const [seconds, setSeconds] = useState<number>(member?.secondsCounter || 0);
    const { t } = useTranslation();
    

    const HandlePauseResume = ()=>{
        setPause(!pause)
    };

    const HandleNoteOpen = ()=>{
        setNote(!note)
    };

    useEffect(()=>{
        !pause && setTimeout(()=>{
            setSeconds(seconds + 1);
            memberDispatch({type: 'UPDATE_SECONDS', payload: seconds + 1})
        }, 1000)
    }, [seconds, pause]);

    return(
        <Grid container>
            <Grid item sm={0} md={1.3} lg={0.7}/>
            <Grid item sm={12} md={10.5} lg={11}>
                <EncounterTimerContainer>
                    <EncounterGrid container>
                        <Grid item xs={3}  md={4.9} lg={6}>
                            <EncounterTitleText>{t('u-current-encounter')}</EncounterTitleText>
                        </Grid>
                        <Grid item xs={9}  md={7.1} lg={5}>
                            <Stack spacing={.8} direction="row">
                                <EncounterText>{t('u-starts-at')} {moment(member?.encounterStart).format('HH:mm') || '00:00'}</EncounterText>
                                <EncounterText>{t('u-time')} {Math.floor(seconds / 60)}:{(seconds - Math.floor(seconds / 60) * 60).toLocaleString('en-US', {minimumIntegerDigits: 2})}</EncounterText>
                                <Button variant="primary" height={theme.spacing(6)} onClick={HandlePauseResume}>{pause ? t('u-resume') : t('u-pause')}</Button>
                                <Button variant="primary" height={theme.spacing(6)} width="26%" onClick={HandleNoteOpen}>{t('u-add-note')}</Button>
                            </Stack>
                        </Grid>
                    </EncounterGrid>
                        <EncounterTextField fullWidth multiline rows={3} sx={{ display: `${note ? 'block' : 'none'}` }}></EncounterTextField>
                </EncounterTimerContainer>
            </Grid>
            <Grid item xs={0}/>
        </Grid>
    )
}
