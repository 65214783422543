import { Stack } from "@mui/material";
import {styled} from "@mui/material/styles";

export const StatisticsContainer = styled('div')(({theme}) => ({
    background: theme.palette.common.background.light,
    height: theme.spacing(45),
}));

export const StatisticsHeaderText = styled('h1')(({theme}) => ({
    fontSize: theme.spacing(5),
    margin: theme.spacing(1),
}));

export const StyledStack = styled(Stack)(({theme}) => ({
    marginLeft: theme.spacing(33),
    height: theme.spacing(40),
    width: theme.spacing(90),
}));