import { Tvalues } from "./Threshold";

export interface notificationsBodyInterface {
    _id: string;
    memberId: string;
    username: string;
    phone: number;
    email: string;
    receivedAt: string;
    acknowledged: boolean;
    clearedAt?: string;
    flagged: boolean;
    linkedReading: string;
    linkedNote?: string;
    respondents?: string[];
    breachType: string;
    readingType: 'bp' | 'bgl' | 'spo2' | 'temp' | 'spir' | 'wt' | 'hr' | 'kk',
    readingValue: Tvalues,
    threshold: Tvalues,
    organization?: string
}

export interface notificationsContextInterface {
    items?:notificationsBodyInterface[];
    list: notificationsBodyInterface[];
    currentNotification?: notificationsBodyInterface;
    cid?: string;
    pages: number;
    currentPage: number;
}

export const notificationsContext = {
   pages: 0,
   currentPage: 0,
}
