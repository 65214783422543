export interface DeviceObject {
    assigned: boolean;
    id: string;
    orderDate: string;
    serialnumber: string;
    type: string;
    user?: string;
}
export interface DeviceContextInterface {
    items: DeviceObject[];
}
export interface ItemsontextInterface {
    list: DeviceContextInterface;
}

export let devicesContext = {
   
 }