function configReducer(state: any, action: any){
    const {type, payload} = action;
    switch (type) {
        case 'SAVE_TOKEN':
            return {...state, authToken: payload};
        case 'RERENDER':
            return {...state, pageRender: payload};
        case 'COORD_IN':
            return {...state, coordLogin: payload};
        case 'PROVIDER_IN':
            return {...state, providerLogin: payload};
        case 'UPDATE_DISPLAY':
            return {...state, displayOptions: payload};
        case 'UPDATE_PREVIEW':
            return {...state, previewOptions: payload};
        case 'UPDATE_CHART':
            return {...state, chartOptions: payload};
        case 'FOOTER_LINK':
            return {...state, footerTab: payload};
        case 'UPDATE_BASIC':
            const basicUpdates = {
                expirationDate: payload.expirationDate,
                units: payload.units,
                glucoseUnits: payload.glucoseUnits,
                supportURL: payload.supportURL,
                pushURL: payload.pushURL || '',
                surveys: payload.surveys,
                cellDevices: payload.cellDevices,
                smartDevices: payload.smartDevices,
                emailNotifications: payload.emailNotifications,
                selfRegistration: payload.selfRegistration,
                messaging: payload.messaging,
                notes: payload.notes
            };
            return {...state, basicOptions: basicUpdates,};
        case 'UPDATE_BRANDING':
            const brandingUpdates = {
                clientLogo: payload.clientLogo,
                favicon: payload.favicon
            };
            return {...state, brandingOptions: brandingUpdates};
        case 'UPDATE_ORDERS':
            const orderUpdates = {
                bulkUploads: payload.bulkUploads,
                newMember: payload.newMember,
                customOrder: payload.customOrder,
                orderDetails: payload.orderDetails,
                bulkDestination: payload.bulkDestination,
                shippingFacility: payload.shippingFacility,
                pleaseInclude: payload.pleaseInclude
            };
            return {...state, orderOptions: orderUpdates};
        case 'UPDATE_REGS':
            const regUpdates = {
                programs: payload.programs,
                groups: payload.groups
            };
            return {...state, regOptions: regUpdates};
        case 'OPTIONS_UPLOAD':
            const basic = {
                expirationDate: payload.basicOptions.expirationDate,
                units: payload.basicOptions.units,
                glucoseUnits: payload.basicOptions.glucoseUnits,
                supportURL: payload.basicOptions.supportURL,
                pushURL: payload.basicOptions.pushURL || '',
                surveys: payload.basicOptions.surveys,
                cellDevices: payload.basicOptions.cellDevices,
                smartDevices: payload.basicOptions.smartDevices,
                emailNotifications: payload.basicOptions.emailNotifications,
                selfRegistration: payload.basicOptions.selfRegistration,
                messaging: payload.basicOptions.messaging,
                notes: payload.basicOptions.notes
            };
            const orders = {
                bulkUploads: payload.orderOptions.bulkUploads,
                newMember: payload.orderOptions.newMember,
                customOrder: payload.orderOptions.customOrder,
                orderDetails: payload.orderOptions.orderDetails,
                bulkDestination: payload.orderOptions.bulkDestination,
                shippingFacility: payload.orderOptions.shippingFacility,
                pleaseInclude: payload.orderOptions.pleaseInclude
            };
            const branding = {
                clientLogo: payload.brandingOptions.clientLogo,
                favicon: payload.brandingOptions.favicon
            };
            const regs = {
                programs: payload.regOptions.programs,
                groups: payload.regOptions.groups
            };
            return {...state, basicOptions: basic, orderOptions: orders, brandingOptions: branding, regOptions: regs};
        default:
            console.log('DEFAULT');
            return state;
    }
}

export default configReducer;
