import React, { ChangeEvent, useState, useEffect } from 'react';
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Autocomplete, Alert } from '@mui/material'
import API from 'api';
import Loader from "components/shared/Loader/Loader";
import Button from "components/shared/Button/Button";
import Session from "helpers/session";
import { useTranslation } from 'react-i18next';

interface deviceInterface {
    serialnumber: string;
    user: string;
    type: string;
    assigned: boolean;
    orderDate: boolean;
    lot: string;
}

export default function Devices(){
    const memberID = window.location.pathname.split('/').pop();
    const [value, setValue] = useState<string>();
    const [devices, setDevices] = useState<deviceInterface[]>([]);
    const [waiting, setWaiting] = useState<boolean>(true);
    const [ImeI, setImeI] = useState<string>('');
    const [isValidImeI, setIsValidImeI] = useState(true);
    const [alertMessage, setAlertMessage] = useState<string>();
    const [availableDevices, setAvailableDevices] = useState([]);
    const [showSuccess, setSuccess] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);
    const { t } = useTranslation();

    const handleChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setValue(event.target.value);
        setSuccess('');
        setChanged(false);
    };

    const handleSelection = (event: any, value: any)=>{
        console.log(value);
        setImeI(value);
        setIsValidImeI(checkImeI());
        setSuccess('');
        setChanged(false);
    };

    const helperTextRender = ()=>{
        return isValidImeI ? '' : t('s-p-devices-message-1')
    };

    const checkImeI = ()=>{
        return ImeI.length > 8 ? true : false
    };

    const getDeviceList = ()=>{
        API.kore.devices.getAssignedInventory(Session.crackCookieToken('coordinator'), 'cellular', memberID || '').then(D=>{
            console.log(D);
            setWaiting(false);
            if(D){
                setDevices(D.items)
            }else {
                setDevices([])
            }
        }).catch(deviceErr=>console.log(deviceErr));
        API.kore.devices.getUnassignedInventory(Session.crackCookieToken('coordinator'), 'cellular').then(deviceListRet=>{
            // console.log(deviceListRet);
            const deviceList = deviceListRet.items.map((device: deviceInterface)=>{
                return device.serialnumber.toUpperCase()
            });
            setAvailableDevices(deviceList)
        }).catch(deviceErr=>console.log(deviceErr));
    };

    const renderList = ()=>{
        if(devices){
            return devices.map((device)=>{
                return <FormControlLabel key={device.serialnumber + '7'} value={device.serialnumber} control={<Radio color="default"/>} label={device ? device.serialnumber.toUpperCase() : ''} />
            })
        }else{
            return <Loader/>
        }
    };

    const submissionClicked = ()=>{
        console.log(value);
        console.log(ImeI);
        setSuccess('success');
        setChanged(true);
        if(ImeI && value){
            // call for both addition and removal of device
            if(window.confirm(`${t('s-p-devices-message-2')} ${ImeI} ${t('s-p-devices-message-3')} ${value}?`)){
                API.kore.devices.addCellDevice(Session.crackCookieToken('coordinator'), ImeI.toLowerCase(),memberID || '').then((cellularRes)=>{
                    if(cellularRes.request.status === 409){
                        const { assignee, portal } = JSON.parse(cellularRes.response.data.response.text);
                        setAlertMessage(`${t('s-p-devices-message-4')}${assignee}${t('s-p-devices-message-5')}${portal}${t('s-p-devices-message-6')}`)
                    }else if(cellularRes.status === 500){
                        setAlertMessage(t('s-p-devices-message-7'))
                    }else {
                        setImeI('');
                        setValue('');
                        setWaiting(false)
                    }
                });
                API.kore.devices.removeCellDevice(Session.crackCookieToken('coordinator'), value.toLowerCase(), memberID || '');
            }
        }else if(ImeI){
            if(window.confirm(`${t('s-p-devices-message-8')} ${ImeI}?`)){
                API.kore.devices.addCellDevice(Session.crackCookieToken('coordinator'), ImeI.toLowerCase(),memberID || '').then((cellularRes)=>{
                    console.log(cellularRes);
                    if(cellularRes.request.status === 409){
                        const { assignee, portal } = JSON.parse(cellularRes.response.data.response.text);
                        setAlertMessage(`${t('s-p-devices-message-9')} ${assignee} ${t('s-p-devices-message-10')} ${portal} ${t('s-p-devices-message-11')}`)
                    }else if(cellularRes.status === 500){
                        setAlertMessage(t(`s-p-devices-message-12`))
                    }else {
                        setImeI('');
                        setWaiting(false)
                    }
                }).catch(err=>console.log('Something went device wrong'));
            }
        }else if(value){
            // call for removal of device
            if(window.confirm(`${t('s-p-devices-message-13')} ${value}?`)){
                API.kore.devices.removeCellDevice(Session.crackCookieToken('coordinator'), value.toLowerCase(), memberID || '');
                setValue('');
                setWaiting(false)
            }
        }else{
            window.confirm(t('s-p-devices-message-14'))
        }
        setTimeout(()=>{
            getDeviceList()
        }, 500)
    };

    useEffect(()=>{
        getDeviceList()
    }, []);

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <h1>{t('u-connected-cellular-devices')}</h1>
                </Grid>
                <br/>
                <br/>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{t('u-device-by-imei')}</FormLabel>
                        {alertMessage ? <h3 style={{ fontFamily: 'helvetica neue', color: 'white', backgroundColor: 'red', borderRadius: '3px', padding: '12px 100px'}}>{alertMessage}</h3> : ''}
                        <RadioGroup aria-label="gender" name="device" value={value} onChange={handleChange}>
                            {waiting ? <Loader/> : devices.length > 0 ? renderList() : <div><br/><br/><p style={{color:'red', border: '2px solid red', borderRadius: '6px', padding: '6px'}}>{t('s-no-cellulare-devices')}</p><br/><br/></div>}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <br/>
                <br/>
                <Grid item xs={12}>
                    <br/><br/>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        //TODO: choose one or the other vvvvvvv
                        // renderInput={(params)=> <TextField {...params} sx={{ width: 190}} id="imei" variant="outlined" label={'Add Device'} helperText={helperTextRender()} error={!isValidImeI} fullWidth={false} value={ImeI}/>}
                        renderInput={(params)=> <TextField {...params} sx={{ width: 190}} id="imei" variant="outlined" label={t('u-add-device')} helperText={helperTextRender()} error={!isValidImeI} onChange={(e: any) => {setImeI(e.target.value); setIsValidImeI(checkImeI())}} fullWidth={false} value={ImeI}/>}
                        options={availableDevices}
                        onChange={handleSelection}
                    />
                    <br/>
                    <br/>
                    <Button variant={'primary'} onClick={submissionClicked} disabled={isDisabled}>{t('u-update-device-list')}</Button>
                    {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                </Grid>
            </Grid>
        </div>
    )
}
