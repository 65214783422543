import axios from 'axios';
import { kore_url, mel_url } from './index';
import { ThresholdInterface } from '../../context/initialState/Threshold'

export default {
    getAll: async (type?: string, count?: boolean ) => {
        // TODO: remove fix for issue with coordinator permissions
        const queries = ()=>{
            return type ? `?type=${type}` : ''
        };
        const countFilter = ()=>{
            return count ? `/count` : ''
        };
        return await axios.get(mel_url + `/api/coordinator/readings${countFilter()}${queries()}`)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getLatest: async (token: string) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return await axios.get(kore_url + '/api/v2/readings', config)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getForOne: async (memberID: string, token?: string, readingType?: string, param?: string) => {
        // TODO: remove fix for issue with coordinator permissions
        return await axios.get(mel_url + `/api/coordinator/readings?filter=memberId:${memberID}${readingType ? '&type=' + readingType : ''}${param ? param : ''}`)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getCountForOne: async (memberID: string, token?: string, readingType?: string, param?: string) => {
        // TODO: remove fix for issue with coordinator permissions
        return await axios.get(mel_url + `/api/coordinator/readings?filter=memberId:${memberID}${readingType ? '&type=' + readingType : ''}${param ? param : ''}`)
            .then(readingList=>{
                console.log(readingList)
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getCount: async (memberID: string, token: string, readingType?: string, param?: string) => {
        // TODO: remove fix for issue with coordinator permissions
        return await axios.get(mel_url + `/api/coordinator/readings/count?filter=memberId:${memberID}${readingType ? '&type=' + readingType : ''}${param ? param : ''}`)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getRecentReadings: async (memberID: string, token: string) => {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        return await axios.get(`${kore_url}/api/v2/members/recents/${memberID}`, config)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getSingleReading: async (readingID: string, token: string) => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios.get(`${kore_url}/api/v2/readings/${readingID}`, config)
            .then(readingList=>{
                return readingList.data
            }).catch(err=>console.log(err))
    },
    getThreshold: async (memberID: string, thresholdType: string | undefined, token: string, param?: string) => {
        const config = {
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios.get(`${kore_url}/api/v4/thresholds/${memberID}?type=${thresholdType}&${param ? param : ''}`, config)
            .then(thresholdList=>{
                return thresholdList.data
            }).catch(err=>{return err})
    },
    setThreshold: async (memberID: string, threshold: ThresholdInterface, token: string) => {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
            data: threshold,
            url: `${kore_url}/api/v4/thresholds`,
            method: 'POST'
        };
        return await axios(config)
            .then(newThreshold=>{
                return newThreshold.data
            }).catch(err=>console.log(err))
    },
    removeThreshold: async (memberID: string, token: string) => {
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        return await axios.delete(`${kore_url}/api/v4/thresholds/${memberID}`, config)
            .then(newThreshold=>{
                return newThreshold.data
            }).catch(err=>console.log(err))
    },
}
