import members from './members';
import readings from './readings';
import coordinator from './coordinator';
import notifications from './notifications';
import devices from './devices';
import configurations from './configurations';
import orders from './orders';
import encounters from './encounters';
import notes from './notes';

export const host = window.location.hostname.split('.');
export const mel_url = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_MEL_URL || 'http://localhost:9000' : 'https://security.life365inc.info';
export const socket_url = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_SOCKET_URL || 'http://localhost:4000' : `https://${host[0]}.${host[1]}.life365inc.com`;
export const kore_url = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_KORE_URL || 'https://l365.sbx.life365inc.com' : `https://${host[0]}.${host[1]}.life365inc.com`;
export const sinope_url = process.env.REACT_APP_NODE_ENV === 'development' ? process.env.REACT_APP_SINOPE_URL || 'https://dbx.sbx.life365inc.com' : `https://dbx.${host[1]}.life365inc.com`;

export default { coordinator, readings, members, notifications, kore_url, devices, configurations, orders, encounters, notes };
