import axios from 'axios';
import { kore_url } from './index';
import { notificationsBodyInterface } from 'context/initialState/Notifications';

export default {
    getList: async (token: string, filter?: string, param?: string) => {
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const paginationParameters = ()=>{
            let paginationQuery = '';
            if (!filter){
                paginationQuery = `?${param ? param : ''}`;
            } else {
                paginationQuery = `&${param ? param : ''}`;
            };
            return paginationQuery
        };
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/notifications${queries()}${paginationParameters()}`,
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios(config)
            .then(readingList => {
                return readingList.data
            }).catch(err => console.log(err))
    },
    getCSV: async ( token: string, filter?: string, userEmail?: string )=>{
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const config = {
            method: 'GET',
            url: kore_url + `/api/v4/notifications${queries()}`,
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios(config)
            .then(readingList => {
                return readingList.data
            }).catch(err => console.log(err))
    },
    getSingle: async (id: string, token: string)=>{
        const config = {
            method: 'GET',
            url: kore_url + '/api/v4/notifications/' + id,
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios(config)
            .then(readingList => {
                return readingList.data
            }).catch(err => console.log(err))
    },
    create: async (notification: notificationsBodyInterface, token: string)=>{
        const config = {
            method: 'POST',
            url: kore_url + '/api/v4/notifications',
            data: notification,
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios(config)
            .then(readingList => {
                return readingList.data
            }).catch(err => console.log(err))
    },
    update: async (id: string, token: string, changes: object)=>{
        const config = {
            method: 'PUT',
            url: kore_url + '/api/v4/notifications/' + id,
            data: changes,
            headers: {Authorization: `Bearer ${token}`}
        };
        return await axios(config)
            .then(readingList => {
                return readingList.data
            }).catch(err => console.log(err))
    }
};
