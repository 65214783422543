import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme})=>({
    textAlign: 'left',
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.common.text.blue,
}));

export const StyledSubHeader = styled("h4")(({theme})=>({
    marginTop: theme.spacing(0.1),
    marginBottom: theme.spacing(0.1),
}));

export default {
    StyledContainer,
    StyledTextField,
    StyledSubHeader,
}

