import { Stack } from '@mui/material';
import React from 'react';
import { Pie, PieChart } from 'recharts';
import { PieChartBoldText, PieChartContainer, PieChartText } from '../ChartStyles.styled';

export interface PieChartInterface {
    name: string;
    value: number | Date | undefined;
}
export type CustomPieChartProps= {
   data: PieChartInterface[];
   label: string;
}
export const StyledPieChart: React.FC<CustomPieChartProps> = ({data, label})=>{

    return (
        <PieChartContainer direction="row">
            <Stack>
                <PieChartBoldText>{label}</PieChartBoldText>
                {data.map(res => {return(<PieChartText>{`${res.name}: ${res.value}`}</PieChartText>)})}
            </Stack>
            <PieChart width={200} height={200}>
                <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={5} outerRadius={45} fill="#8884d8" label/> 
            </PieChart>      
        </PieChartContainer>
    )
}