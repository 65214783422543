interface LowHigh {
    low: number,
    high: number
}

export interface Tvalues {
    systolic?: LowHigh,
    diastolic?: LowHigh,
    pulse?: LowHigh,
    glucose?: LowHigh,
    spo2?: LowHigh,
    weight?: LowHigh,
    temp?: LowHigh
}

export interface ThresholdInterface {
    _id?: string;
    breachType: 'high' | 'moderate';
    readingType: string;
    memberId: string;
    coordinator: string;
    value: Tvalues
}

export interface ThresholdContextInterface {
    high: Tvalues;
    moderate: Tvalues
}

export const thresholdContext: ThresholdContextInterface = {
    high: {
        systolic: {low: 0, high: 0},
        diastolic: {low: 0, high: 0},
        pulse: {low: 0, high: 0},
        glucose: {low: 0, high: 0},
        spo2: {low: 0, high: 0},
        weight: {low: 0, high: 0},
        temp: {low: 0, high: 0}
    },
    moderate: {
        systolic: {low: 0, high: 0},
        diastolic: {low: 0, high: 0},
        pulse: {low: 0, high: 0},
        glucose: {low: 0, high: 0},
        spo2: {low: 0, high: 0},
        weight: {low: 0, high: 0},
        temp: {low: 0, high: 0}
    }
};
