import React, { useContext } from 'react';
import { StyledContainer, StyledHeader } from "../Monitoring/Monitoring.styles";
import { Grid } from "@mui/material";
import SimpleGrid, { CellProps, BodyProps} from "components/shared/SimpleGrid/SimpleGrid";
import EncounterTimer from 'components/shared/EncounterTimer/EncounterTimer';
import { useTranslation } from 'react-i18next';

export default function Monitoring(){
    const { t } = useTranslation();
    const TableHeaders: CellProps[] = [{content: t('u-month'), align: 'center'}, {content: `${t('u-monitoring')} ${t('u-days')}`, align: 'center'}, {content: `RPM ${t('u-time')}`, align: 'center'}];
    const testTableBody: BodyProps[] = [
        { cells: [{content: 'June 2022', align: 'center'}, {content: '3', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'May 2022', align: 'center'}, {content: '1', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'April 2022', align: 'center'}, {content: '1', align: 'center'}, {content: '00:00:00', align: 'center'}]},
        { cells: [{content: 'March 2022', align: 'center'}, {content: '14', align: 'center'}, {content: '00:00:00', align: 'center'}]},
    ];
    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{

    }
    return (
        <StyledContainer>

            <Grid container>
                <Grid item xs={0}/>
                <Grid item xs={11.4}>
                    <EncounterTimer></EncounterTimer>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={1} md={1.5} lg={1}/>
                <Grid item sm={10.5} md={10} lg={10}>
                <StyledHeader><h2>{t('u-monitoring')}</h2></StyledHeader>
                    <SimpleGrid headers={TableHeaders} body={testTableBody} paginationAction={paginationAction}/>
                </Grid>
                <Grid item xs={1}/>
            </Grid>
        </StyledContainer>
    )
}
