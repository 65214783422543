import { StyledDiv } from "views/Admin/subviews/FooterLinks/FooterLinks.styles";

export default function Contact(){
    return(
        <StyledDiv>
            <p>Life365, Inc.<br/><br/>
            1475 N Scottsdale Rd, #200<br />
            Scottsdale, AZ 85257</p>
            <a href="tel:888-818-2322">888-818-2322</a><br />
            <a href="mailto:support@life365.health">support@life365.health</a>
        </StyledDiv>
    )
}