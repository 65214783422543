interface displayOptionsInterface {
    activity: boolean;
    bloodGlucose: boolean;
    bloodPressure: boolean;
    heartRate: boolean;
    respirationRate: boolean;
    oxygenSaturation: boolean;
    spirometry: boolean;
    temperature: boolean;
    weight: boolean;
    memberNotes: boolean;
}

export interface brandingOptionsInterface {
    clientLogo: string;
    favicon: string;
}

export interface basicOptionsInterface {
    expirationDate: string;
    units: string;
    glucoseUnits: string;
    supportURL?: string;
    pushURL?: string;
    surveys: boolean;
    cellDevices: boolean;
    smartDevices: boolean;
    emailNotifications: boolean;
    selfRegistration: boolean;
    messaging: boolean;
    notes: boolean;
}

export interface orderingOptionsInterface {
    bulkUploads: boolean;
    newMember: 'demo' | 'production' | 'none';
    customOrder: boolean;
    orderDetails: object | undefined;
    bulkDestination: {
        name?: string,
        address: string,
        city: string,
        state: string,
        zip: string,
        phone?: string,
        email?: string
    };
    shippingFacility: {
        address: string,
        city: string,
        state: string,
        zip: string
    };
    pleaseInclude: [{
        _id: string,
        email: string
    }]
}

export interface regOptionsInterface {
    programs: [{name: string, mdmID: string}];
    groups: [string];
}

export interface configInterface {
    authToken: string;
    pageRender: string;
    coordLogin?: boolean;
    providerLogin?: boolean;
    footerTab: number;
    displayOptions: displayOptionsInterface;
    chartOptions: displayOptionsInterface;
    previewOptions: displayOptionsInterface;
    brandingOptions: brandingOptionsInterface;
    basicOptions: basicOptionsInterface;
    orderOptions: orderingOptionsInterface;
    regOptions: regOptionsInterface;
}

export const configContext: configInterface = {
    pageRender: 'home',
    authToken: '',
    footerTab: 0,
    displayOptions: {
        activity: false,
        bloodGlucose: true,
        bloodPressure: true,
        heartRate: false,
        respirationRate: false,
        oxygenSaturation: true,
        spirometry: false,
        temperature: true,
        weight: true,
        memberNotes: false
    },
    chartOptions: {
        activity: false,
        bloodGlucose: true,
        bloodPressure: true,
        heartRate: false,
        respirationRate: false,
        oxygenSaturation: true,
        spirometry: false,
        temperature: true,
        weight: true,
        memberNotes: false
    },
    previewOptions: {
        activity: false,
        bloodGlucose: true,
        bloodPressure: true,
        heartRate: false,
        respirationRate: false,
        oxygenSaturation: true,
        spirometry: false,
        temperature: true,
        weight: true,
        memberNotes: false
    },
    brandingOptions: {
        clientLogo: '',
        favicon: ''
    },
    basicOptions: {
        expirationDate: '12/01/2030',
        units: 'us',
        glucoseUnits: 'mg/dL',
        surveys: true,
        cellDevices: true,
        smartDevices: true,
        emailNotifications: true,
        selfRegistration: false,
        messaging: true,
        notes: true
    },
    orderOptions: {
        bulkUploads: true,
        newMember: 'demo',
        customOrder: false,
        orderDetails: {},
        bulkDestination: {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: ''
        },
        shippingFacility: {
            address: '',
            city: '',
            state: '',
            zip: ''
        },
        pleaseInclude: [{_id: '', email: ''}]
    },
    regOptions: {
        programs: [{name: 'test', mdmID: ''}],
        groups: ['test']
    }
};
