import React, {ChangeEvent} from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";
import { styled } from '@mui/material/styles'

interface checkboxProps {
    itemArray: string[];
    onChange?: (event: any)=> void
}

const StyledUL = styled('div')(({theme})=>({
    [theme.breakpoints.up("lg")]: {
        columnCount: 2,
    },
    textAlign: 'left',
    paddingLeft: '16px'
}));

export default function CheckboxGroup (props: checkboxProps){
    const { itemArray, onChange: change } = props;

    const handleChange = (e: ChangeEvent<HTMLElement>)=>{
        if(change){
            change(e)
        }
    };
    return (
        <StyledUL>
            {itemArray.map((item: string): JSX.Element=>{
                return <FormControlLabel label={item} control={<Checkbox id={`checkbox-group-${item}`} name={item} onChange={handleChange}/>}/>
            })}
        </StyledUL>
    )
}
