// @ts-nocheck
import React, {ChangeEvent, useState} from "react";
import { Chip, Select, MenuItem } from "@mui/material";

export default function TagsInputer({ ...props }) {
    const { selectedTags, placeholder, tags, ...other } = props;
    const [inputValue, setInputValue] = useState("");
    const [selectedItem, setSelectedItem] = useState(['cat']);
    const [availableTags, setAvalalbeTags] = useState(['I', 'me', 'my']);

    function handleChange(item) {
        let newSelectedItem = [...selectedItem];
        if (newSelectedItem.indexOf(item.target.value) === -1) {
            newSelectedItem = [...newSelectedItem, item.target.value];
        }
        setInputValue("");
        setSelectedItem(newSelectedItem);
    }

    const handleDelete = (item) => () => {
        const newSelectedItem = [...selectedItem];
        newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
        setSelectedItem(newSelectedItem);
    };

    function handleInputChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setInputValue(event.target.value);
    }
    return (
        <Select
            startAdornment={selectedItem.map(item => (
                <Chip
                    key={item}
                    tabIndex={-1}
                    label={item}
                    onDelete={handleDelete(item)}
                />
            ))}
            onChange={event => {
                handleChange(event);
                handleInputChange(event);
            }}
            placeholder="add a tag"
            fullWidth
        >
            {availableTags.map(tag=>{
                return <MenuItem value={tag}>{tag}</MenuItem>
            })}
        </Select>
    );
}
