import React from 'react';
import { Grid } from '@mui/material';
import {StyledHeadTypography} from "../../../views/PatientBoard/microViews/Details/Details.styles";

interface MenuLineItemProps {
    title: string,
    input: JSX.Element | JSX.Element[]
}

export default function MenuLineItem(props: MenuLineItemProps){
    return (
        <Grid container>
            <Grid item xs={4}>
                <StyledHeadTypography>{props.title}</StyledHeadTypography>
            </Grid>
            <Grid item xs={8}>
                {props.input}
            </Grid>
        </Grid>
    )
}
