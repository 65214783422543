import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { StyledDataContainer } from "../Provider.styles";
import { ButtonArray } from '../Provider';
import { useNavigate } from 'react-router-dom';
import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import { GlobalContext } from "context/Provider";
import Edit from "./Edit";
import Upsert from "./Upsert";
import API from 'api';
import Session from "helpers/session";
import { useTranslation } from 'react-i18next';

interface ListProps {
    columns: GridColDef[];
    rows: any[] | undefined;
    action: (event: any)=>void;
}



const List = (props: ListProps)=>{
    const { columns, rows, action } = props;
    return (
        <div>
            <ButtonArray/>
            <StyledDataContainer>
                <DataGrid
                    columns={columns}
                    rows={rows || []}
                    disableSelectionOnClick
                    rowsPerPageOptions={[5]}
                    onCellClick={action}
                />
            </StyledDataContainer>
        </div>
    )
};

export default function Users(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { provider, memberDispatch } =  useContext(GlobalContext);
    // GridEventListener<"cellClick">
    const coordinatorHeaders: GridColDef[] = [
        { field: 'name', headerName: t('u-name'), width: 125, flex: 500},
        { field: 'email', headerName: t('u-email'), width: 125, flex: 500 },
        { field: 'phone', headerName: t('u-phone'), width: 125, flex: 500 },
        { field: 'assignments', headerName: t('u-assignments'), width: 155, flex: 500 },
        { field: 'lastactivity', headerName: t('u-last-activity'), width: 145, flex: 500 },
        { field: 'registeredat', headerName: t('u-registered-at'), width: 155, flex: 500 },
        { field: 'actions', headerName: t('u-actions'), width: 120, flex: 500, renderCell: (params: GridRenderCellParams<string>)=>{return <>{params.value}</>} },
    ];
    const memberHeaders: GridColDef[] = [
        { field: 'name', headerName: t('u-name'), editable: true },
        { field: 'email', headerName: t('u-email'), editable: true },
        { field: 'phone', headerName: t('u-phone'), editable: true },
        { field: 'lastactivity', headerName: t('u-last-activity'), editable: true },
        { field: 'registeredat', headerName: t('u-registered-at'), editable: true },
        { field: 'actions', headerName: t('u-actions'), renderCell: (params: GridRenderCellParams<string>)=>{return <>{params.value}</>} },
    ];
    const editUser = (isCoordinator: boolean) => (event: any)=>{
        const { id } = event.row;
        let userCall = API.kore.members.getOne(id, Session.crackCookieToken('provider'));
        if(isCoordinator){
            userCall = API.kore.coordinator.getSingle(id, Session.crackCookieToken('provider'));
        }
        userCall.then(user=>{
            console.log('user: ', user);
            memberDispatch({type: 'CHANGE_TARGET_MEMBER', payload: user});
            navigate(`${window.location.pathname}/edit`)
        });
    };
    return (
        <Routes>
            <Route path="coordinators" element={<List columns={coordinatorHeaders} rows={provider?.coordinatorList} action={editUser(true)}/>}/>
            <Route path="members" element={<List columns={memberHeaders} rows={provider?.memberList} action={editUser(false)}/>}/>
            <Route path="coordinators/edit" element={<Edit type={'coordinator'}/>}/>
            <Route path="members/edit" element={<Edit type={'member'}/>}/>
            <Route path="coordinators/new" element={<Upsert type="coordinator"/>}/>
            <Route path="members/new" element={<Upsert type="member"/>}/>
        </Routes>
    )
}
