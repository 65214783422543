import request, {AxiosResponse} from 'axios';
import { kore_url } from './index';
import { EncounterInterface } from 'context/initialState/Encounter';

export default {
    getList: ()=>{
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/encounters`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return request(config)
            .then((encounters: AxiosResponse)=>{
                return encounters.data.items
            }).catch(encounterErr=>{throw encounterErr})
    },
    getCSV: ( token: string, filter?: string, userEmail?: string )=>{
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/encounters${queries()}`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
            }
        };
        return request(config)
            .then((newEncounter: AxiosResponse)=>{
                return newEncounter.data
            }).catch(encounterErr=>{throw encounterErr})
    },
    getSingle: (encounterID: string)=>{
        const config = {
            method: 'GET',
            url: `${kore_url}/api/v4/encounters/${encounterID}`,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return request(config)
            .then((encounter: AxiosResponse)=>{
                return encounter
            }).catch(encounterErr=>{throw encounterErr})
    },
    add: (encounter: EncounterInterface)=>{
        const config = {
            method: 'POST',
            url: `${kore_url}/api/v4/encounters`,
            data: encounter,
            headers: {
                "Access-Control-Allow-Origin": "*"
            }
        };
        return request(config)
            .then((newEncounter: AxiosResponse)=>{
                return newEncounter
            }).catch(encounterErr=>{throw encounterErr})
    }
}
