import API from 'api';
import Session from "../../../../helpers/session";
import {exportLink} from "../../../../helpers/variables";
import {associateReadingType} from 'helpers/variables';

export interface includesInterface {
    type: 'csv' | 'pdf';
    delivery: 'download' | 'email';
    emailDestination?: string;
    notifications: boolean;
    notes: boolean;
    encounters: boolean;
    bgl: boolean;
    bp: boolean;
    hrt: boolean;
    spo2: boolean;
    spir: boolean;
    temp: boolean;
    wt: boolean;
    oneCSV: boolean;
}

export const submitExport = (includes: includesInterface, start: number | string | Date | null, end: number | string | Date | null, token: string, memberId: string)=>{
    const vitals = ['bp', 'bgl', 'spo2', 'wt', 'temp', 'spir', 'hrt'];
    if(includes.oneCSV){
        // TODO: one CSV call here
        console.log('CREATING SINGLE CSV')
    }else{
        vitals.map(vital=>{
            if(includes[vital as keyof includesInterface]){
                API.kore.readings.getForOne(memberId, token, vital, `&format=${includes.type}`)
                    .then(csv=>{
                        exportLink(csv, `${associateReadingType(vital)}-export.csv`);
                    })
            }
        })
    }
    if(includes.notes){
        API.kore.notes.getCSV(Session.crackCookieToken('coordinator'), `${memberId}&format=csv`)
            .then(csv=>{
                exportLink(csv, 'notes-export.csv');
            })}
    if(includes.encounters){
        API.kore.encounters.getCSV(Session.crackCookieToken('coordinator'), `${memberId}&measured_between=${start},${end}&format=csv`)
            .then(csv=>{
                exportLink(csv, 'encounters-export.csv');
            })}
    if(includes.notifications){
        API.kore.notifications.getCSV(Session.crackCookieToken('coordinator'), `${memberId}&measured_between=${start},${end}&format=csv`)
            .then(csv=>{
                exportLink(csv, `notifications-export.csv`);
            })}
    // if(){}
};
