import React, {useContext, useEffect} from 'react';
import Button from "components/shared/Button/Button";
import SimpleGrid, {BodyProps, CellProps} from "components/shared/SimpleGrid/SimpleGrid";
import { useTranslation } from 'react-i18next';
import {GlobalContext} from "context/Provider";
import {RowInterface} from "context/initialState/Provider";
import {useNavigate} from "react-router";
import {StyledButtonConatiner} from "../Provider.styles";
import {Grid, Stack} from "@mui/material";

// TODO: needs translation
const ButtonArray = ()=>{
    const navigate = useNavigate();
    return (
        <StyledButtonConatiner container>
            <Grid item xs={1.5}><Button variant="primary" onClick={()=>{navigate(`${window.location.pathname}/new`)}}>{'+New Manager'}</Button></Grid>
            <Grid item xs={10.5}/>
        </StyledButtonConatiner>
    )
};

export default function Managers(){
    const { provider } = useContext(GlobalContext);
    const { t } = useTranslation();
    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{

    }
    const header: CellProps[] = [
        {content: t('u-email')},
        {content: 'Coordinator Access'},
        {content: t('u-actions')}
    ];

    const formatCoordinators = (): BodyProps[] =>{
        let managerList: RowInterface[] = [];
        if(provider) {
            managerList = provider.coordinatorList.filter(coord => {
                return coord.role === 'Client,Owner'
            });
        }
        if(managerList) {
            return managerList.map((coord) => {
                return {cells: [{content: coord.email || ''}, {content: coord.role === 'Client,Owner' ? '√' : ''}, {content: '√'}]}
            })
        }else{
            return [];
        }
    };
    return (
        <div>
            <ButtonArray/>
            <SimpleGrid headers={header} body={formatCoordinators()} paginationAction={paginationAction}/>
        </div>
    )
}
