import {createTheme, Theme} from '@mui/material/styles';
import { grey } from '@mui/material/colors'

// declare module '@mui/styles' {
    interface Default extends Theme {}
// }

declare module "@mui/material/styles/createPalette" {
    interface CommonColors {
        layout: {
            offBlack: string;
            offWhite: string;
        },
        button: {
            disabled: string;
        },
        checkbox: {
            main: string;
        },
        background: {
            light: string;
            main: string;
            white: string;
            green: string;
            yellow: string;
            red: string;
        },
        text: {
            offBlack: string;
            offGrey: string;
            blue: string;
        },
        barFill: {
            blue: string;
            green: string;
            yellow: string;
            orange: string;
            pink: string;
            red: string;
        },
    }
}

const theme: Theme = createTheme({
    typography: {
        fontFamily: "Helvetica Neue",
    },
    palette: {
        common: {
            layout: {
                offBlack: "#14142B",
                offWhite: "#FCFCFC",
            },
            // button color when disabled to match style
            button: {
                disabled: '#c8eac8',
            },
            checkbox: {
                main: 'lightGrey'
            },
            // background greys
            background: {
                light: '#F5F5F5',
                main: '#E7E7E7',
                white: '#ffffff',
                green: '#4BB543',
                yellow: '#FFA500',
                red: '#FF0000',
            },
            text: {
                offBlack: '#212121',
                offGrey: '#D0D0D0',
                blue: '#2179D2',
            },
            barFill: {
                blue: '#A9CDF2',
                green: '#7BF082',
                yellow: '#FEE15C',
                orange: '#F6BA69',
                pink: '#F1A193',
                red: '#ED6D49',
            }
        }
    }
});

export default theme;
