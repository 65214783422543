import { styled } from '@mui/material/styles'
import { Link, Stack } from '@mui/material'

export const  PortalInfoContainer = styled('div')(({theme})=>({
    width: '67%'
}));

export const  LinksContainer = styled('div')(({theme})=>({
    paddingTop: theme.spacing(1),
}));

export const  WebLink = styled(Link)(({theme})=>({
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

export const  StyledVersion = styled('p')(({theme})=>({
    margin: theme.spacing(0),
    marginBottom: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.common.text.offGrey,
}));

export const  StyledFooterText = styled('h5')(({theme})=>({
    padding: theme.spacing(0),
    paddingTop: theme.spacing(3.3),
    margin: theme.spacing(0),
    color: theme.palette.common.text.offGrey,
}));

export const  StyledStack = styled(Stack)(({theme})=>({
    marginLeft: '8%',
    marginRight: '6.5%',
}));
