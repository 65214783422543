import moment from "moment";
import { ReadingContextInterface } from 'context/initialState/Readings';
import { MeasurementProp } from "components/shared/Timeline/Timeline";
import { NotesObject } from "context/initialState/Notes";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export const usStates = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY' ];

export const dayArr = ["Day"];

export const monthArr = ["Month",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"];

const dayValue = 31;
const yearValue = 2022;

export const yearArr = ["Year"];

for (let i = dayValue; i >= 1; i--) {
    dayArr.push(`${i}`)
};

for (let i = yearValue; i >= 1900; i--) {
    yearArr.push(`${i}`)
};

export const toLocalTime = (timeStamp: string | undefined): string=>{
    if(timeStamp) {
        return moment(timeStamp).local().format('MM/DD/YYYY HH:mm');
    }else{
        return '-'
    }
};

export const associateReadingType = (readingType: string): string=>{
    switch(readingType){
        case 'bp':
            return 'Blood Pressure';
        case 'Blood Pressure':
            return 'bp';
        case 'bgl':
            return 'Blood Glucose';
        case 'Blood Glucose':
            return 'bgl';
        case 'hr':
            return 'Heart Rate';
        case 'hrt':
            return 'Heart Rate';
        case 'Heart Rate':
            return 'hrt';
        case 'spo2':
            return 'Oxygen Saturation';
        case 'Oxygen Saturation':
            return 'spo2';
        case 'temp':
            return 'Temperature';
        case 'Temperature':
            return 'temp';
        case 'wt':
            return 'Weight';
        case 'Weight':
            return 'wt';
        case 'rr':
            return 'Respiration Rate';
        case 'Respiration Rate':
            return 'rr';
        case 'spir':
            return 'Spirometry';
        case 'Spirometry':
            return 'spir';
        case 'mn':
            return 'Member Note';
        case 'Member Note':
            return 'mn';
        default:
            return 'bp'
    }
};

export const formatReading = (rawReading: ReadingContextInterface, tempUnits?: string): MeasurementProp => {
    const { metrics } = rawReading;
    switch(rawReading.type){
        case 'bp':
            return {id: rawReading.id, type: 'Blood Pressure', value: `${metrics?.systolic}/${metrics?.diastolic} mmHg\n${metrics?.pulse} bpm`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        case 'bgl':
            return {id: rawReading.id, type: 'Blood Glucose', value: `${metrics?.glucose} mg/dL`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        case 'spo2':
            return {id: rawReading.id, type: 'Oxygen Saturation', value: `${metrics?.spo2}%\n${metrics?.pulse} bpm`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        case 'temp':
            if(tempUnits === 'us' && metrics){
                metrics.units = 'F';
                metrics.temp = convertToFahrenheit(metrics.temp || 1)
            }
            return {id: rawReading.id, type: 'Temperature', value: `${metrics?.temp} ˚${metrics?.units}`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        case 'wt':
            return {id: rawReading.id, type: 'Weight', value: `${metrics?.weight} lbs`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        case 'spir':
            return {id: rawReading.id, type: 'Spirometry', value: `${metrics?.type}`, date: moment(rawReading.measuredAt).format('MMM DD, HH:mm'), year: moment(rawReading.measuredAt).format('YYYY')};
        default:
            return {id: '', type: '', value: '', date: '', year: ''}
    };
};

export const idIncrement =  (id: string) =>{
    return id.slice(0, 19) + (parseInt(id.slice(19, 24), 16) + 1).toString(16);
};

export const idDecrement =  (id: string) =>{
    return id.slice(0, 19) + (parseInt(id.slice(19, 24), 16) + 1).toString(16);
};

export const snakeToCamel = (str: string)=>{
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const convertToCelcius = (num: number)=>{
    if(num < 70){
        return num
    }else {
        return Math.round(((num - 32) * (5 / 9)) * 10) / 10
    }
};

export const convertToFahrenheit = (num: number)=>{
    if(num > 70){
        return num
    }else {
        return Math.round(((num * (9 / 5)) + 32) * 10) / 10
    }
};

export const tempConversion = (num: number, units: string | undefined)=>{
    if(units === 'metric'){
        return convertToCelcius(num)
    }else{
        return convertToFahrenheit(num)
    }
};

export const exportGenerator = (option: string, containerId: any, name: string)=>{
    // Possibly a switch statement instead of an if statement.
    if (option === "toCSV") {
    //  functionToCSV();
    } else if (option === "toPDF") {
        const input = document.getElementById(containerId)!;
        html2canvas(input).then(canvas => {
            const imgData = canvas.toDataURL('img/png')
            const pdf = new jsPDF()
            pdf.addImage(imgData, 'JPEG', 15, 12, 180, 180)
            pdf.save(`${name}.pdf`)
        })
    } else {
        console.log("Unable to export")
    }
};

export const exportLink = (csv: string, fileName: string)=>{
    const blob = new Blob([csv], {type: 'text/csv'});
    const target = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = target;
    a.download = fileName;
    const clickHandler = ()=>{
        setTimeout(()=>{
            URL.revokeObjectURL(target);
            // @ts-ignore
            this.removeEventListener('click', clickHandler);
        }, 150)
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    console.log('action');
    return a
};

export const isValidEmail = (email: string)=>{
    const match = String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    if(match !== null){
        return true
    }else{
        return false
    }
};
