import { styled } from '@mui/material/styles';
import HelpIcon from '@mui/icons-material/Help';

export const StyledRadio = styled('div')(({theme})=>({
    background: theme.palette.common.background.light,
    border: `1px solid lightGrey`,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(.5),
}));

export const  StyledHelpIcon = styled(HelpIcon)(({theme})=>({
    paddingBottom: theme.spacing(3.5),
}));
