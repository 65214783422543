import { styled } from '@mui/material/styles';

export const StyledLastReadingSquare = styled('div')(({theme})=>({
    textAlign: 'center',
    width: theme.spacing(29),
    height: theme.spacing(20),
    background: theme.palette.common.background.light,
    border: `2px dashed ${theme.palette.common.background.main}`,
    '&:hover': {
        background: theme.palette.common.background.main
    }
}));

export const StyledReadingText = styled('h2')(({theme})=>({
    fontWeight: 'bold'
}));

export const StyledReadingInfoText = styled('h3')(({theme})=>({
    color: theme.palette.primary.light
}));

export const StyledContainer = styled('div')(({theme})=>({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: theme.spacing(3),
    justifyContent: 'right'
}));

export default { StyledContainer, StyledLastReadingSquare, StyledReadingText, StyledReadingInfoText };
