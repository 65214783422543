import axios, { AxiosResponse } from 'axios';
import {kore_url, mel_url} from './index';

interface MemberInterface {
    email?: string;
    firstName?: string;
    lastName?: string;
    program?: string;
    phone?: string;
    group?: string;
    site?: string;
    MRN?: string;
}

interface memberResponse {
    items: MemberInterface[]
}

export default {
    stuff: 'stuff',
    getAll: async (token: string)=>{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        return await axios.get(kore_url + '/api/v2/members?select=id,email,username,phone,createdAt,updatedAt,firstName,lastName,group,site,externId,program,birthDate,hubIds,latestReading,status', config)
            .then(memberList=>{
                return memberList.data
            })
    },
    getOne: async (memberId: string, token: string)=>{
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        const selectFields = 'id,firstName,lastName,email,externId,username,program,phone,group,site,birthDate,gender,language,condition,height,weight,status,units,locked';
        return await axios.get(kore_url + '/api/v2/members/' + memberId + '?select=' + selectFields, config)
            .then(memberList=>{
                return memberList.data
            })
    },
    upsert: (action: string, body: object, token: string, memberID?: string)=> {
        const config = {
            method: action === 'create' ? 'POST' : 'PUT',
            headers: { Authorization: `Bearer ${token}` },
            url: `${kore_url}/api/v2/members${action === 'create' ? '' : `/${memberID}`}`,
            data: body
        };
        return axios(config)
            .then((newMember: AxiosResponse)=>{
                return newMember.data
            })
    },
    getAssociations: (memberId: string, token: string)=>{
        const config = {
            method: 'GET',
            headers: { Authorization: `Bearer ${token}` },
            url: `${kore_url}/api/v2/members/${memberId}/coordinators`
        };
        return axios(config)
            .then((coordList: AxiosResponse)=>{
                return coordList.data
            })
    },
    updateAssociations: (memberId: string, coordinatorList: string[], token: string)=>{
        const config = {
            method: 'PATCH',
            headers: { Authorization: `Bearer ${token}` },
            url: `${kore_url}/api/v2/members/${memberId}/coordinators`,
            data: coordinatorList
        };
        return axios(config)
            .then((coordList: AxiosResponse)=>{
                console.log(coordList)
                return coordList.data
            })
    },
    remove: (userId: string, token: string)=>{
        const config = {
            method: 'DELETE',
            url: `${kore_url}/api/v2/members/${userId}`,
            headers: { Authorization: `Bearer ${token}` }
        };
        return axios(config)
            .then((newCoordinator: AxiosResponse)=>{
                return newCoordinator.data
            })
    }
}
