function encounterReducer(state: any, {type, payload}: any){
    switch (type) {
        case 'CHANGE_TARGET_ENCOUNTER':
            return {...state, current: payload};
        case 'UPDATE_LIST':
            return {...state, list: payload};
        default:
            return state;
    }
}

export default encounterReducer;