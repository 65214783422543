import { StyledDiv } from "views/Admin/subviews/FooterLinks/FooterLinks.styles";
import { useTranslation } from 'react-i18next';

export default function Terms(){
    const { t } = useTranslation();
    // Text has been coded for automatic translation using i18nexus. Nameing conventions for this will be as follows:
    // u-(text)-(text) is for words that all start with a capital letter ie. u-happy-feelings = Happy Feelings
    // s-(text)-(text)-(number) is for a sentence or paragraph that needs indexing. On this page the naming convention is different because there are multiple paragraphs one after another that need an index.
    // The naming convention can be read as follows: s(sentence/paragraph)-h(heading) or p(paragraph)-terms(terms and conditions)-1(index)

    return(
        <StyledDiv>
            <h4>{t('s-h-terms-1')}</h4>
            <p>{t('s-p-terms-1')}</p>
            <p>{t('s-p-terms-2')}</p>
            <p>{t('s-p-terms-3')}</p>
            <h2>{t('s-h-terms-2')}</h2>
            <p>{t('s-p-terms-4')}</p>
            <h2>{t('s-h-terms-3')}</h2>
            <p>{t('s-p-terms-5')}</p>
            <p>{t('s-p-terms-6')}</p>
            <p>{t('s-p-terms-7')}</p>
            <h2>{t('s-h-terms-4')}</h2>
            <p>{t('s-p-terms-8')}</p>
            <h2>{t('s-h-terms-5')}</h2>
            <p>{t('s-p-terms-9')}</p>
            <h2>{t('s-h-terms-6')}</h2>
            <p>{t('s-p-terms-10')}</p>
            <p>{t('s-p-terms-11')}</p>
            <p>{t('s-p-terms-12')}</p>
            <p>{t('s-p-terms-13')}</p>
            <h2>{t('s-h-terms-7')}</h2>
            <p>{t('s-p-terms-14')}</p>
            <p>{t('s-p-terms-15')}</p>
            <h2>{t('s-h-terms-8')}</h2>
            <p>{t('s-p-terms-16')}</p>
            <p>{t('s-p-terms-17')}</p>
            <p>{t('s-p-terms-18')}</p>
            <p>{t('s-p-terms-19')}</p>
            <p>{t('s-p-terms-20')}</p>
            <h2>{t('s-h-terms-9')}</h2>
            <p>{t('s-p-terms-21')}</p>
            <h2>{t('s-h-terms-10')}</h2>
            <p>{t('s-p-terms-22')}</p>
            <h2>{t('s-h-terms-11')}</h2>
            <p>{t('s-p-terms-23')}</p>
            <h2>{t('s-h-terms-12')}</h2>
            <p>{t('s-p-terms-24')}</p>
            <p>{t('s-p-terms-25')}</p>
            <p>{t('s-p-terms-26')}</p>
            <p>{t('s-p-terms-27')}</p>
            <p>{t('s-p-terms-28')}</p>
            <p>{t('s-p-terms-29')}</p>
            <p>{t('s-p-terms-30')}</p>
            <h2>{t('s-h-terms-13')}</h2>
            <p>{t('s-p-terms-31')}</p>
            <h2>{t('s-h-terms-14')}</h2>
            <p>{t('s-p-terms-32')}</p>
            <h2>{t('s-h-terms-15')}</h2>
            <p>{t('s-p-terms-33')}</p>
            <p>{t('s-p-terms-34')}</p>
            <p>{t('s-p-terms-35')}</p>
            <h2>{t('s-h-terms-16')}</h2>
            <p>{t('s-p-terms-36')}</p>
            <p>Life365, Inc.<br />
            1475 N Scottsdale Rd, #200<br />
            Scottsdale, AZ 85257<br />
            <a href="mailto:support@life365.health">support@life365.health</a></p>
        </StyledDiv>
    )
}
