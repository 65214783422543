import request from 'axios';
import {sinope_url, kore_url} from './index';

export default {
    getAll: (token: string, limit: string, filter?: string)=>{
        const queries = ()=>{
            return filter ? `?filter=${filter}` : ''
        };
        const limiter = ()=>{
            let limitQuery = '';
            if (!filter){
                limitQuery = limit ? `?limit=${limit}` : '';
            } else {
                limitQuery = limit ? `&limit=${limit}`: '';
            };
            return limitQuery
        };
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        return request
            .get(`${kore_url}/api/v4/devices${queries()}${limiter()}`, config)
            .then(ret=>{return ret.data})
            .catch(err=>{return err})
    },
    getAssignedInventory: (token: string, type: string, memberId: string)=>{
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        return request
            .get(`${kore_url}/api/v4/devices?filter=assigned:true,type:${type},user:${memberId}`, config)
            .then(ret=>{return ret.data})
            .catch(err=>{return err})
    },
    addCellDevice: (token: string, deviceId: string, memberId: string)=>{
        const config = {
            method: 'POST',
            url: `${kore_url}/api/v4/devices/register`,
            data: {target: memberId, serialnumber: deviceId},
            headers: {Authorization: `Bearer ${token}`},
        };
        return request(config)
            .then(ret=>{return ret.data})
            .catch(err=>{return err})
    },
    removeCellDevice: (token: string, deviceId: string, memberId: string)=>{
        const config = {
            method: 'POST',
            url: `${kore_url}/api/v4/devices/unregister`,
            data: {target: memberId, serialnumber: deviceId},
            headers: {Authorization: `Bearer ${token}`},
        };
        return request(config)
            .then(ret=>{return ret.data})
            .catch(err=>{return err})
    },
    getUnassignedInventory: (token: string, type: string)=>{
        const config = {
            headers: {Authorization: `Bearer ${token}`},
        };
        return request
            .get(`${kore_url}/api/v4/devices?filter=assigned:false,type:${type}`, config)
            .then(ret=>{return ret.data})
            .catch(err=>{return err})
    }
};
