import React, { useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { StyledButton, StyledMiniButton, StyledMiniHeader, StyledContainer, StyledIcon, StyledHoverContainer } from './SideNavBar.styles';
import { GlobalContext } from "context/Provider";
import { useTranslation } from 'react-i18next';


export default function SideNavBar(){
    const { t } = useTranslation();
    const [showSubmenu, setShowSubmenu] = useState(window.location.pathname.split('/')[2] === 'history');
    const { member, config } = useContext(GlobalContext);

    const HoverList = () => {
        return (
            <StyledHoverContainer>
                <Stack>
                    <StyledMiniHeader>{t('u-history')}</StyledMiniHeader>
                    <StyledMiniButton to={`/patient/history/timeline/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-timeline')}</StyledMiniButton>
                    {config?.displayOptions.bloodPressure && <StyledMiniButton to={`/patient/history/bloodpressure/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-blood-pressure')}</StyledMiniButton>}
                    {config?.displayOptions.bloodGlucose && <StyledMiniButton to={`/patient/history/bloodglucose/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-blood-glucose')}</StyledMiniButton>}
                    {config?.displayOptions.heartRate && <StyledMiniButton to={`/patient/history/heartrate/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-heart-rate')}</StyledMiniButton>}
                    {config?.displayOptions.oxygenSaturation && <StyledMiniButton to={`/patient/history/oxygensaturation/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-oxygen-saturation')}</StyledMiniButton>}
                    {config?.displayOptions.temperature && <StyledMiniButton to={`/patient/history/temperature/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-temperature')}</StyledMiniButton>}
                    {config?.displayOptions.weight && <StyledMiniButton to={`/patient/history/weight/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-weight')}</StyledMiniButton>}
                    {config?.displayOptions.respirationRate && <StyledMiniButton to={`/patient/history/respirationrate/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-respiration-rate')}</StyledMiniButton>}
                    {config?.displayOptions.memberNotes && <StyledMiniButton to={`/patient/history/membernote/list/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}>{t('u-member-notes')}</StyledMiniButton>}
                </Stack>
            </StyledHoverContainer>
        )
    };

    const showText = (text: string) => {
        return !showSubmenu ? text : ''
    };
    return (
        <StyledContainer>
            {showSubmenu ? <HoverList/> : ''}
                <Stack>
                    <StyledButton to={`/patient/profile/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>person</StyledIcon>{showText(t('u-profile'))}</StyledButton>
                    <StyledButton to={`/patient/details/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>account_box</StyledIcon>{showText(t('u-management-details'))}</StyledButton>
                    {config?.basicOptions.notes ? <StyledButton to={`/patient/notes/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>menu_book</StyledIcon>{showText(t('u-staff-notes'))}</StyledButton> : ''}
                    <StyledButton to={`/patient/vitals/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>home</StyledIcon>{showText(t('u-vitals'))}</StyledButton>
                    <StyledButton to={`/patient/history/timeline/${member?.current.id}`} onClick={()=>{setShowSubmenu(true)}}><StyledIcon>list_alt</StyledIcon>{showText(t('u-history'))}</StyledButton>
                    {config?.basicOptions.surveys ? <StyledButton to={`/patient/surveys/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>poll</StyledIcon>{showText('Surveys')}</StyledButton> : ''}
                    <StyledButton to={`/patient/export/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>open_in_new</StyledIcon>{showText(t('u-export'))}</StyledButton>
                    <StyledButton to={`/patient/notifications/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>notifications</StyledIcon>{showText(t('u-notifications'))}</StyledButton>
                    <StyledButton to={`/patient/encounters/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>contact_page</StyledIcon>{showText(t('u-encounters'))}</StyledButton>
                    <StyledButton to={`/patient/monitoring/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>contact_page</StyledIcon>{showText(t('u-monitoring'))}</StyledButton>
                    <StyledButton to={`/patient/devices/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>mobile_friendly</StyledIcon>{showText('Devices')}</StyledButton>
                    <StyledButton to={'/'} onClick={()=>{window.open('https://life365inc.vsee.me/providers/login', '_black')}}><StyledIcon>video_camera_front_icon</StyledIcon>{showText('Video Chat')}</StyledButton>
                    {config?.basicOptions.messaging ? <StyledButton to={`/patient/messaging/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>message</StyledIcon>{showText('Messaging')}</StyledButton> : ''}
                    <StyledButton to={`/patient/options/${member?.current.id}`} onClick={()=>{setShowSubmenu(false)}}><StyledIcon>settings</StyledIcon>{showText(t('u-display-options'))}</StyledButton>
                </Stack>
        </StyledContainer>
    )
}
