import { styled } from '@mui/material/styles'
import {TextField} from "@mui/material";

export const StyledTextField = styled(TextField)(({theme})=>({
    paddingLeft: theme.spacing(1),
    width: theme.spacing(40)
}));

export const StyledHTMLField = styled(TextField)(({theme})=>({
    width: theme.spacing(100)
}));

export const StyledInput = styled('input')(({theme})=>({
    display: 'none'
}));
