import React, {ChangeEvent, useContext, useState} from 'react';
import {Alert, FormControlLabel, Grid, Input, Stack, Switch} from "@mui/material";
import moment from 'moment';
import {StyledHTMLField, StyledTextField} from "../Admin.styled";
import RadioGenerator from "components/shared/RadioGenerator/RadioGenerator";
import Button from "components/shared/Button/Button";
import {GlobalContext} from "context/Provider";
import API from 'api';
import {basicOptionsInterface, regOptionsInterface} from "context/initialState/Config";

interface programInterface {
    name: string;
    mdmID: string
}

export default function Basic(){
    const { config, configDispatch } = useContext(GlobalContext);
    const [showSuccess, setSuccess] = useState<string>('');
    const [groupName, setGroupName] = useState<string>('');
    const [groups, setGroups] = useState<string[]>(config?.regOptions.groups || []);
    const [programName, setProgramName] = useState<string>('');
    const [programSN, setProgramSN] = useState<string>('');
    const [programs, setPrograms] = useState<programInterface[]>(config?.regOptions.programs || []);
    const [ basicState, setBasicState ] = useState<basicOptionsInterface | undefined>(config?.basicOptions);
    console.log(config?.regOptions.programs)

    const handleSubmission = (event: any) => {
        const { expiration, locale, general_units, support_url, push_url, logo_file, favicon_file, terms, privacy, contact_info, toggle_cellular, toggle_messaging, toggle_smart, toggle_email_notifications, toggle_surveys, toggle_registration, toggle_notes } = event.target;
        event.preventDefault();
        setSuccess('success');
        const formBody = {
            expirationDate: expiration.value,
            locale: locale.value,
            units: general_units.value,
            glucoseUnits: 'mg/dL',
            supportURL: support_url.value,
            pushURL: push_url.value,
            logoFile: logo_file.value,
            faviconFile: favicon_file.value,
            terms: terms.value,
            privacy: privacy.value,
            contactInfo: contact_info.value,
            cellDevices: toggle_cellular.checked,
            smartDevices: toggle_smart.checked,
            emailNotifications: toggle_email_notifications.checked,
            surveys: toggle_surveys.checked,
            selfRegistration: toggle_registration.checked,
            messaging: toggle_messaging.checked,
            notes: toggle_notes.checked
        };
        API.kore.configurations.updateBasicConfig(formBody)
            .then(configRet=>{
                configDispatch({type: 'UPDATE_BASIC', payload: formBody})
            }).catch(configErr=>console.log(configErr));
        API.kore.configurations.updateRegConfig({programs: programs, groups: groups} as regOptionsInterface)
            .then(regConf=>{
                configDispatch({type: 'UPDATE_REGS', payload: {programs: programs, groups: groups}})
            }).catch(regErr=>console.log(regErr));
    };

    const handleChange = (event: ChangeEvent<HTMLElement>)=>{
        // @ts-ignore
        setBasicState({...basicState, [event.target.id]: event.target.value });
        setSuccess('')
    };

    const addGroup = ()=>{
        setGroups(groups.concat([groupName]));
        setGroupName('')
    };

    const updateGroupName = (event: ChangeEvent<HTMLInputElement>)=>{
        setGroupName(event.target.value)
    };

    const addProgram = ()=>{
        setPrograms(programs.concat([{name: programName, mdmID: programSN}]));
        setProgramName('');
        setProgramSN('');
    };

    const updateProgramName = (event: ChangeEvent<HTMLInputElement>)=>{
        setProgramName(event.target.value)
    };

    const updateProgramSN = (event: ChangeEvent<HTMLInputElement>)=>{
        setProgramSN(event.target.value)
    };

    return (
        <form onSubmit={handleSubmission}>
            <Grid container>
                <Grid item xs={8}>
                    <Stack alignItems="flex-start" spacing={2} ml={15}>
                        <br/>
                        <FormControlLabel labelPlacement="start" label="Expiration Date" control={<StyledTextField id="expirationDate" name="expiration" type="text" placeholder="MM/DD/YYYY" size="small" value={moment(basicState?.expirationDate).format('MM/DD/YYYY')} onChange={handleChange}/>}/>
                        <FormControlLabel labelPlacement="start" label="Language preset" control={<RadioGenerator name="locale" inputArray={['en', 'es', 'fr', 'it', 'ru']} value="en"/>}/>
                        <FormControlLabel labelPlacement="start" label="Units" control={<RadioGenerator id="units" name="general_units" inputArray={['metric', 'US']} value={basicState?.units} onChange={handleChange}/>}/>
                        <FormControlLabel labelPlacement="start" label="Glucose Units" control={<RadioGenerator id="glucoseUnits" name="glucose_units" inputArray={['mg/dL', 'mmol']} value={basicState?.glucoseUnits} onChange={handleChange}/>}/>
                        <FormControlLabel labelPlacement="start" label="Support URL" control={<StyledTextField id="supportURL" name="support_url" type="text" placeholder="http://example.com" size="small" value={basicState?.supportURL} onChange={handleChange}/>}/>
                        <FormControlLabel labelPlacement="start" label="Push URL" control={<StyledTextField id="pushURL" name="push_url" type="text" placeholder="http://example.com/push-endpoint" size="small" value={basicState?.pushURL} onChange={handleChange}/>}/>
                        <FormControlLabel labelPlacement="start" label="Client Logo" control={<Input name="logo_file" type="file"/>}/>
                        <FormControlLabel labelPlacement="start" label="Favicon" control={<Input name="favicon_file" type="file"/>}/>
                        <h4>Groups list:</h4>
                        {groups.map(group=>{
                            return <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label={group} control={<></>}/><Button variant={'warning'} onClick={addGroup}>-</Button></Stack>
                        })}
                        <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label="Add Group" control={<StyledTextField onChange={updateGroupName} value={groupName} type="text" placeholder="Group name" size="small"/>}/><Button variant={'primary'} onClick={addGroup}>+</Button></Stack>
                        <br/>
                        <h4>Programs list:</h4>
                        {programs.map(program=>{
                            return <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label={program.name} control={<StyledTextField name={program.name.replace(' ', '-')} type="text" placeholder="Program MDM #" size="small" value={program.mdmID}/>}/><Button variant={'warning'} onClick={addProgram}>-</Button></Stack>
                        })}
                        <Stack direction="row" spacing={3} alignItems="center"><FormControlLabel labelPlacement="start" label="Add Program" control={<><StyledTextField onChange={updateProgramSN} value={programSN} type="text" placeholder="Program MDM #" size="small"/><StyledTextField onChange={updateProgramName} value={programName} type="text" placeholder="Program name" size="small"/></>}/><Button variant={'primary'} onClick={addProgram}>+</Button></Stack>
                        <FormControlLabel labelPlacement="top" label="Terms" control={<StyledHTMLField name="terms" type="text" placeholder="HTML for Terms here" size="small" multiline minRows={10} maxRows={10}/>}/>
                        <FormControlLabel labelPlacement="top" label="Privacy" control={<StyledHTMLField name="privacy" type="text" placeholder="HTML for Privacy here" size="small" multiline minRows={10} maxRows={10}/>}/>
                        <FormControlLabel labelPlacement="top" label="Contact Info" control={<StyledHTMLField name="contact_info" type="text" placeholder="HTML for Contact Info here" size="small" multiline minRows={10} maxRows={10}/>}/>
                        <Button variant="success" type="submit">Apply</Button>
                        {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                        <br/>
                        <br/>
                        <br/>
                    </Stack>
                </Grid>
                <Grid item xs={4}>
                    <Stack alignItems="flex-start">
                        <FormControlLabel labelPlacement="start" label="Email Notifications" control={<Switch id="toggle-email-notifications" name="toggle_email_notifications" defaultChecked={basicState?.emailNotifications}/>}/>
                        {/*<FormControlLabel labelPlacement="start" label="Language Bar" control={<Switch id="toggle-language-bar" defaultChecked={true}/>}/>*/}
                        <FormControlLabel labelPlacement="start" label="Self registration" control={<Switch id="toggle-registration" name="toggle_registration" defaultChecked={basicState?.selfRegistration}/>}/>
                        <FormControlLabel labelPlacement="start" label="Messaging" control={<Switch id="toggle-messaging" name="toggle_messaging" defaultChecked={basicState?.messaging}/>}/>
                        <FormControlLabel labelPlacement="start" label="Cellular Devices" control={<Switch id="toggle-cellular" name="toggle_cellular" defaultChecked={basicState?.cellDevices}/>}/>
                        <FormControlLabel labelPlacement="start" label="Smart Devices" control={<Switch id="toggle-smart" name="toggle_smart" defaultChecked={basicState?.smartDevices}/>}/>
                        <FormControlLabel labelPlacement="start" label="Surveys" control={<Switch id="toggle-surveys" name="toggle_surveys" defaultChecked={basicState?.surveys}/>}/>
                        <FormControlLabel labelPlacement="start" label="Staff Notes" control={<Switch id="toggle-notes" name="toggle_notes" defaultChecked={basicState?.notes}/>}/>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    )
}
