import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {
    TextField,
    Stack,
    Grid,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    SelectChangeEvent,
    Autocomplete
} from '@mui/material';
import RadioGenerator from "components/shared/RadioGenerator/RadioGenerator";
import Button from "components/shared/Button/Button";
import API from 'api';
import { StyledContainer } from "../../PatientBoard/microViews/Details/Details.styles";
import MenuLineItem from "components/shared/MenuLineItem/MenuLineItem";
import Session from "helpers/session";
import CheckboxGroup from "components/shared/CheckboxGroup/CheckboxGroup";
import {GlobalContext} from "context/Provider";
import { usStates, isValidEmail } from 'helpers/variables';
import { useTranslation } from 'react-i18next';

interface UpsertProps {
    type: 'coordinator' | 'member' | 'manager';
}

interface deviceInterface {
    serialnumber: string;
    user: string;
    type: string;
    assigned: boolean;
    orderDate: boolean;
    lot: string;
}

interface FormInterface {
    email: string;
    firstname: string;
    lastname: string;
    password: string;
    phone: string;
    info: string;
    group: string;
    program: string;
    site: string;
    gender: string;
    dob: string;
    height: string;
    units: string;
    glucose: string;
    pushURL: string;
    delivery: string;
    state: string;
    'Blood Glucose Monitor': boolean;
    'Blood Pressure Monitor': boolean;
    'Pulse Oxygen Monitor': boolean;
    'Weight Scale': boolean;
    'Spirometer': boolean;
    'Thermometer': boolean;
    'Cellular Hub': boolean;
    'Smart Hub': boolean;
    imeiValue?: string;
}

export default function Upsert(props: UpsertProps){
    const [requiredDeviceArray, setRequiredDeviceArray,] = useState<Array<string>>(['Blood Glucose Monitor', 'Blood Pressure Monitor', 'Pulse Oxygen Monitor', 'Weight Scale', 'Spirometer', 'Thermometer', 'Cellular Hub', 'Smart Hub']);
    const [availableDevices, setAvailableDevices] = useState([]);
    const [type, setType] = useState(props.type);
    const { config } = useContext(GlobalContext);
    const [availablePrograms, setAvailablePrograms] = useState(config?.regOptions.programs || []);
    const [availableGroups, setAvailableGroups] = useState(config?.regOptions.groups || []);
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [ImeI, setImeI] = useState<string>('');
    const [isValidImeI, setIsValidImeI] = useState(true);
    const { t } = useTranslation();
    const [form, setForm] = useState<FormInterface>({
        email: '',
        firstname: '',
        lastname: '',
        password: '',
        phone: '',
        info: '',
        group: '',
        program: '',
        site: '',
        gender: 'other',
        dob: '',
        height: '',
        units: '',
        glucose: '',
        pushURL: '',
        delivery: 'Given to patient',
        state: 'AZ',
        'Blood Glucose Monitor': false,
        'Blood Pressure Monitor': false,
        'Pulse Oxygen Monitor': false,
        'Weight Scale': false,
        'Spirometer': false,
        'Thermometer': false,
        'Cellular Hub': false,
        'Smart Hub': false
    });

    const handleDeviceChange = (event: any) => {
        // setChanged(false);
    };

    const checkImeI = ()=>{
        return ImeI.length > 8 ? true : false
    };

    const helperTextRender = ()=>{
        return isValidImeI ? '' : t('s-p-devicesMessage-1')
    };

    const handleSelection = (event: any, value: any)=>{
        console.log(event.target.id);
        setForm({...form, [event.target.id]: value})
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>)=>{
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setForm({...form, [target.name]: value});
    };

    const handleSelectChange = (event: SelectChangeEvent)=>{
        setForm({...form, [event.target.name]: event.target.value});
    };

    const validateEmail = (email: string) => {
        if(isValidEmail(email)){
            setValidEmail(false);
            return true
        }else{
            setValidEmail(true);
            window.scrollTo(0, 0);
            return false
        }
    };

    // @ts-ignore
    const handleSubmission = (e)=>{
        e.preventDefault();
        const { email, firstname, lastname, password, phone, info, group, site, gender, dob, height, units, glucose, pushURL } = form;
        const memberBody = {
            email: email || '',
            firstName: firstname || '',
            lastName: lastname || '',
            username: `${firstname || ''} ${lastname || ''}`,
            password: password || 'simplepasswordl365',
            birthDate: dob || '',
            program: group || '',
            units: {
                general: units.toLowerCase() || 'imperial',
                glucose: glucose.split('/')[0] || 'mg'
            }
        };
        const orderBody = {
            organization: 'test',
            delivery: 'Ground',
            issuedBy: 'Current Coordinator',
            issuedEmail: 'currentcoord@here.com',
            sendersAddress: {
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: ''
            },
            recipient: {
                name: '',
                address: '',
                city: '',
                state: '',
                zip: '',
                phone: ''
            },
            manifest: {
                devices: {
                    bpm: 2,
                    bgm: 1,
                    scale: 1,
                    pulseOx: 1,
                    thermometer: 1,
                    spirometer: 1
                },
                hubs: {
                    stel: 1,
                    smart: 1
                }
            }
        };
        const coordinatorBody = {
            email: email || '',
            username: `${firstname || ''} ${lastname || ''}`,
            password: password || 'simplepasswordl365',
            confidential: true,
            redirectUris: [pushURL || 'http://place.holder'],
            locked: false,
            access: type === 'manager' ? 'Provider' : 'Owner',
            sources: [{
                name: 'medm',
                autoLink: true,
                pushURL: pushURL || 'http://place.holder'
            }]
        };
        if(validateEmail(email)) {
            const upsertCall = type === 'coordinator' ? API.kore.coordinator.upsert : API.kore.members.upsert;
            upsertCall('create', type === 'coordinator' ? coordinatorBody : memberBody, Session.crackCookieToken('provider')).then((upsertResponse: string)=>{
                if(type === 'member' && form.delivery.toLowerCase() !== 'given to patient'){
                    API.kore.orders.create(orderBody).then(orderRet=>{
                        window.location.pathname = `/provider/${type}s`;
                    }).catch(orderErr=>console.log(orderErr))
                    // TODO: Assign device to member if cell or smart device is selected
                }else{
                    window.location.pathname = `/provider/${type}s`;
                }
            });
        }
    };
    const getDeviceList = ()=>{
        API.kore.devices.getUnassignedInventory(Session.crackCookieToken('coordinator'), 'cellular').then(deviceListRet=>{
            const deviceList = deviceListRet.items.map((device: deviceInterface)=>{
                return device.serialnumber.toUpperCase()
            });
            setAvailableDevices(deviceList)
        }).catch(deviceErr=>console.log(deviceErr));
    };

    useEffect(()=>{
        getDeviceList()
    }, []);
    return (
        <StyledContainer>
            <Grid container>
                <Grid item xs={1}/>
                <Grid item xs={7}>
                    <form onSubmit={handleSubmission}>
                        <Stack spacing={1}>
                            <MenuLineItem title={'Email'} input={<TextField name="email" placeholder="demo@test.com" onChange={handleInputChange} fullWidth required helperText="(Required)" error={validEmail}/>}/>
                            <MenuLineItem title={'First Name'} input={<TextField name="firstname" placeholder="Bob" onChange={handleInputChange} fullWidth required helperText="(Required)"/>}/>
                            <MenuLineItem title={'Last Name'} input={<TextField name="lastname" placeholder="Dill" onChange={handleInputChange} fullWidth required helperText="(Required)"/>}/>
                            <MenuLineItem title={'Phone'} input={<TextField name="phone" placeholder="xxx-xxx-xxxx" onChange={handleInputChange} fullWidth required={form.delivery === 'ship to home'} helperText={form.delivery === 'ship to home' ? "(Required)" : ''}/>}/>
                            <MenuLineItem title={'Report contact information'} input={<TextField name="info" placeholder="info" multiline maxRows={3} minRows={3} onChange={handleInputChange} fullWidth/>}/>
                            {/*<MenuLineItem title={'Group'} input={<TextField name="group" placeholder="Cardiac" onChange={handleInputChange} fullWidth/>}/>*/}
                            <MenuLineItem title={'Group'} input={<Autocomplete
                                id="group"
                                renderInput={(params)=> <TextField {...params} name="group" placeholder="group-1" onChange={handleInputChange} fullWidth/>}
                                options={availableGroups}
                                onChange={handleSelection}
                                fullWidth
                            />}/>
                            <MenuLineItem title={'Program'} input={<Autocomplete
                                id="program"
                                renderInput={(params)=> <TextField {...params} name="program" placeholder="Cardiac" onChange={handleInputChange} fullWidth/>}
                                options={availablePrograms.map(name=>{return name.name})}
                                onChange={handleSelection}
                                fullWidth
                            />}/>
                            <MenuLineItem title={'Site'} input={<TextField name="site" placeholder="main" onChange={handleInputChange} fullWidth/>}/>
                            <MenuLineItem title={'Gender'} input={<RadioGenerator name="gender" inputArray={['Female', 'Male', 'Other']} onChange={handleInputChange} value={form.gender}/>}/>
                            <MenuLineItem title={'Date of Birth'} input={<TextField name="dob" placeholder="MM-DD-YYYY" onChange={handleInputChange} fullWidth/>}/>
                            <MenuLineItem title={'Height'} input={<TextField name="height" placeholder="5'10" onChange={handleInputChange} fullWidth/>}/>
                            <MenuLineItem title={'Units'} input={<RadioGenerator name="units" inputArray={['Metric', 'US']} onChange={handleInputChange} value={config?.basicOptions.units} required/>}/>
                            <MenuLineItem title={'Glucose'} input={<RadioGenerator name="glucose" inputArray={['mg/dL', 'mmol/L']} onChange={handleInputChange} value={config?.basicOptions.glucoseUnits} required/>}/>
                            {(type !== 'coordinator' && config?.orderOptions.newMember !== 'none') && <MenuLineItem title={'Delivery'} input={<RadioGenerator name="delivery" inputArray={['Ship to home', 'Given to patient']} onChange={handleInputChange} value={form.delivery}/>}/>}
                            {form.delivery === 'ship to home' && <Stack direction="row" spacing={2}>
                                <TextField name="address" label="address" type="text" required helperText="(Required)"/>
                                <TextField name="city" label="city" type="text" required helperText="(Required)"/>
                                <Select name="state" label="state" sx={{height: '55px'}} onChange={handleSelectChange} value={form.state} variant="outlined" required>
                                    {usStates.map(S=>{
                                        return <MenuItem value={S}>{S}</MenuItem>
                                    })}
                                </Select>
                                <TextField name="zip" label="zip" type="number" required helperText="(Required)"/>
                            </Stack>}
                            {(type !== 'coordinator' && config?.orderOptions.newMember !== 'none') && <MenuLineItem title={'Required Devices'} input={<CheckboxGroup itemArray={requiredDeviceArray} onChange={handleInputChange}/>}/>}
                            {(form["Cellular Hub"] || form["Smart Hub"]) && form.delivery.toLowerCase() === 'given to patient' ? (
                                <Autocomplete
                                    disablePortal
                                    id="imeiValue"
                                    //TODO: choose one or the other vvvvvvv
                                    // renderInput={(params)=> <TextField {...params} sx={{ width: 190}} id="imei" variant="outlined" label={'Add Device'} helperText={helperTextRender()} error={!isValidImeI} fullWidth={false} value={ImeI}/>}
                                    renderInput={(params)=> <TextField {...params} sx={{ width: 190}} id="imei" variant="outlined" label={t('u-add-device')} helperText={helperTextRender()} error={!isValidImeI} onChange={(e: any) => {setImeI(e.target.value); setIsValidImeI(checkImeI())}} fullWidth={false} value={ImeI}/>}
                                    options={availableDevices}
                                    onChange={handleSelection}
                                />
                            ) : ''}
                            <br/>
                        </Stack>
                        <Button variant="success" type="submit">Create</Button>
                    </form>
                </Grid>
                <Grid item xs={4}/>
            </Grid>
        </StyledContainer>
    )
}
