import React, { useContext, useState } from 'react';
import { StyledContainer, StyledHeader } from "../Encounters/Encounters.styles";
import { Grid } from "@mui/material";
import SimpleGrid, { CellProps, BodyProps } from "components/shared/SimpleGrid/SimpleGrid";
import { useTranslation } from 'react-i18next';
import { EncounterInterface, encounterContext } from 'context/initialState/Encounter';
import { GlobalContext } from 'context/Provider';

export default function Encounters(){
    const { encounters } = useContext(GlobalContext);
    const { t } = useTranslation();
    const TableHeaders: CellProps[] = [ {content: t('u-starts-at'), align: 'left'}, {content: t('u-all'), align: 'left'}, {content: 'Note', align: 'left'}, {content: t('u-actor'), align: 'left'} ];

    const [totalEncounterList, setTotalEncounterList]=useState<number>(0)
    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{

    }
    const generateList = (rawList: EncounterInterface[]): BodyProps[]=>{
        return rawList.map(item=>{
            return { cells: [
                {content: <div>{item.touchPoints.interactions.actions.timestamp}</div> , align: 'left'},
                {content: <div>{item.touchPoints.totalDuration}</div>, align: 'left'},
                {content: <div>{item.touchPoints.interactions.actions.noteContent || ''}</div>, align: 'left'},
                {content: <div>{item.touchPoints.interactions.coordinatorName}</div>, align: 'left'}
            ]}
        })
    };

    return (
        <StyledContainer>
            <Grid container>
                <Grid item md={1.5} lg={1}/>
                <Grid item md={10.5} lg={11}>
                <StyledHeader><h2>{t('u-encounters')}</h2></StyledHeader>
                        <SimpleGrid headers={TableHeaders} body={generateList(encounters?.list || [])} paginationAction={paginationAction} totalList={totalEncounterList}/>
                </Grid>
            </Grid>
        </StyledContainer>
    )
}
