import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material';

export const StyledContainer = styled('div')(({theme})=>({
    backgroundColor: theme.palette.common.background.light,
    border: '1px solid grey'
}));

export const StyledHeader = styled('div')(({theme})=>({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.layout.offWhite,
    width: '100%'
}));

export const StyledButtonConatiner = styled(Grid)(({theme})=>({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
}));

export const StyledDataContainer = styled('div')(({theme})=>({
    height: theme.spacing(50),
    width: '100%'
}));

export const StyledItem = styled('div')(({theme})=>({
    backgroundColor: theme.palette.common.background.main,
    width: '450px',
    margin: theme.spacing(0.5),
    paddingLeft: theme.spacing(1)
}));

export const StyledAction = styled('div')(({theme})=>({
    float: 'right',
    marginRight: theme.spacing(1),
    cursor: 'pointer'
}));
