import {styled} from '@mui/material/styles';
import { Grid, Link, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import Button from 'components/shared/Button/Button';

export const StyledMainContainer = styled('div')(({theme})=>({
    height: 400,
    width: '90%',

}));

export const StyledHeader = styled('h2')(({theme})=>({
    display: 'flex',
    flex: 'left',
}));

export const StyledFont = styled('h4')(({theme})=>({
    color: theme.palette.primary.light,
    fontFamily: 'Helvetica Neue',
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    '&:hover': {
        textDecoration: 'underline',
    }
}));

export const StyledDataGrid = styled(DataGrid)(({theme})=>({
    '& .MuiDataGrid-columnHeader': {
        color: 'white',
        background: theme.palette.primary.light,
        fontSize: theme.spacing(3),
        width: '100%',
    },
}));

export const StyledButton = styled(Button)(({theme})=>({

}));

export const StyledMemberToolTipContainer = styled('div')(({theme})=>({
    width: theme.spacing(24)
}));

export const StyledMemberToolTipText = styled('h5')(({theme})=>({
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingBottom: theme.spacing(0.4),
    margin: theme.spacing(0),
}));

export const StyledLeftMemberToolTipGrid = styled(Grid)(({theme})=>({
    textAlign: 'left',
}));

export const StyledRightMemberToolTipGrid = styled(Grid)(({theme})=>({
    paddingLeft: theme.spacing(1),
    textAlign: 'left',
}));

export const StyledMeasurementToolTip = styled('div')(({theme})=>({
    width: theme.spacing(0),
    height: theme.spacing(0)
}));

export const StyledDot = styled('span')(({theme})=>({
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: '#f8a120',
    borderRadius: '50%',
    display: 'inline-block'
}));

export default { StyledMainContainer, StyledHeader, StyledFont, StyledButton, StyledLeftMemberToolTipGrid, StyledRightMemberToolTipGrid, StyledMemberToolTipContainer, StyledMemberToolTipText, StyledDot };
