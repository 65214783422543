import { FormControlLabel, Input, OutlinedInput, Stack, TextField, Typography, InputAdornment, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContainer = styled('div')(({theme})=>({
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(8),
    justifyContent: 'left',
    width: '100%',
    minWidth: theme.spacing(60),
}));

export const StyledHeader = styled('div')(({theme})=>({
    justifyContent: 'left',
    width: '100%'
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    width: '80%',
    paddingLeft: theme.spacing(1),
}));

export const StyledStack = styled(Stack)(({theme})=>({
    justifyContent: 'left',
    width: '100%',
}));

export const StyledAdornment = styled(InputAdornment)(({theme})=>({
    // TODO: fix styled adornment background
    color: 'red !important',
    '.MuiInputAdornment-root': {
        color: 'red !important'
    }
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({theme})=>({
    justifyContent: 'left',
    margin: 0,
    padding: 0,
}));

export const StyledTypography = styled(Typography)(({theme})=>({
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    margin: theme.spacing(2),
}));

export const StyledHeadTypography = styled(Typography)(({theme})=>({
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    margin: theme.spacing(0),
}));

export const StyledMultiLineTypography = styled(Typography)(({theme})=>({
    textAlign: 'right',
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(9),
    margin: theme.spacing(0),
}));

export const StyledTypographyIcon = styled(Typography)(({theme})=>({
    textAlign: 'right',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    margin: theme.spacing(2),
    justifyContent: 'right',
    display: 'flex',
}));

export const StyledOutlinedInput = styled(OutlinedInput)(({theme})=>({
    width: '45.5%',
}));

export default { StyledContainer,
                 StyledHeader,
                 StyledTextField,
                 StyledStack,
                 StyledAdornment,
                 StyledFormControlLabel,
                 StyledTypography,
                 StyledHeadTypography,
                 StyledMultiLineTypography,
                 StyledOutlinedInput,
                 StyledTypographyIcon,
                  };
