import { Grid, IconButton } from "@mui/material";
import { CellHeader, 
         CellLeftGrey, 
         CellLeftWhite, 
         CellRightGrey, 
         CellRightWhite, 
         StatisticsInfoContainer, 
         StyledTooltip } from "./StatisticsGrid.styles";
import { StyledHelpIcon } from "components/shared/SharedStyles/SharedStyles";
import { useTranslation } from 'react-i18next';

interface StatisticsGridProps {
    header: HeadProps;
    body: BodyProps;
}

export interface HeadProps {
    title: string;
    content: string;
}

export interface BodyProps {
    total: number;
    year: number;
    month: number;
    week: number;
    day: number;
}

export default function StatisticsGrid(props: StatisticsGridProps){
    const { t } = useTranslation();
    const { header, body } = props;
   return( 
    <StatisticsInfoContainer>
            <CellHeader>{header.title}                    
                <StyledTooltip title={header.content} arrow>
                    <IconButton>
                        <StyledHelpIcon fontSize="small"/>
                    </IconButton>
                </StyledTooltip></CellHeader>
            <Grid container>
                <Grid item xs={7}>
                    <CellLeftWhite>{t('u-total')}</CellLeftWhite>
                    <CellLeftGrey>{t('u-year')}</CellLeftGrey>
                    <CellLeftWhite>{t('u-month')}</CellLeftWhite>
                    <CellLeftGrey>{t('u-week')}</CellLeftGrey>
                    <CellLeftWhite>{t('u-days')}</CellLeftWhite>
                </Grid>
                <Grid item xs={5}>
                    <CellRightWhite>{body.total}</CellRightWhite>
                    <CellRightGrey>{body.year}</CellRightGrey>
                    <CellRightWhite>{body.month}</CellRightWhite>
                    <CellRightGrey>{body.week}</CellRightGrey>
                    <CellRightWhite>{body.day}</CellRightWhite>
                </Grid>
            </Grid>
        </StatisticsInfoContainer>
   )
}