import { Stack } from '@mui/material';
import { useContext } from 'react';
import { ResourceLink } from 'views/Admin/subviews/FooterLinks/FooterLinks.styles';
import { LinksContainer, PortalInfoContainer, WebLink, StyledVersion, StyledFooterText, StyledStack } from './PageFooter.styles';
import { GlobalContext } from 'context/Provider';
import { useTranslation } from 'react-i18next';

export default function PageFooter(){
    const {configDispatch} = useContext(GlobalContext)
    const { t } = useTranslation();

    const footerTabSelect = (tabId: number) => {
        configDispatch({type: 'FOOTER_LINK', payload: tabId});
        return undefined
      };
    return(
        <div>
            <StyledStack direction={'row'}>
                <PortalInfoContainer>
                        <div>
                        <Stack direction={'row'}>
                            <StyledFooterText>{`© 2022 Life365 | ${t('s-footer-rights-reserved')}`}</StyledFooterText>
                            <WebLink href={'https://www.life365.health/en/'} underline={'none'}>{`${t('s-powered-by')}, Inc.`}</WebLink>
                        </Stack>
                        <StyledVersion>v0.1.5</StyledVersion>
                        </div>
                </PortalInfoContainer>
                <LinksContainer>
                        <Stack direction={'row'}>
                            <ResourceLink to="/information" onClick={()=>{footerTabSelect(0)}}>{t("u-terms-of-service")}</ResourceLink>
                            <p>|</p>
                            <ResourceLink to="/information" onClick={()=>{footerTabSelect(1)}}>{t('u-contact-us')}</ResourceLink>
                            <p>|</p>
                            <ResourceLink to="/information" onClick={()=>{footerTabSelect(2)}}>{t('u-privacy-policy')}</ResourceLink>
                        </Stack>
                </LinksContainer>
            </StyledStack>
        </div>
    )
}




