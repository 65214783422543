import React, { useContext, useEffect } from 'react';
import {Routes, Route, useNavigate, Navigate} from "react-router-dom";
import io from 'socket.io-client';
import {ThemeProvider} from "@mui/material/styles";
import Login from './views/Login/Login';
import Main from './views/Main/Main';
import NavBar from './components/NavBar/NavBar';
import PatientBoard from './views/PatientBoard/PatientBoard';
import theme from './helpers/mui-theme.helper';
import logo from './logo.svg';
import './App.css';
import Admin from "./views/Admin/Admin";
import Notifications from "./views/Notifications/Notifications";
import Provider from "./views/Provider/Provider";
import FooterLinks from './views/Admin/subviews/FooterLinks/FooterLinks';
import PageFooter from 'components/shared/PageFooter/PageFooter';
import Password from "./views/password/Password";

function App() {
  const checkSession = (component: JSX.Element) => {
    let decodedCookie = decodeURIComponent(document.cookie);
    const coordinatorCookie = decodedCookie.split('; ').find(cookie=>{
      return cookie.split('=')[0] === 'life365_session'
    });
    if(coordinatorCookie?.split('=')[0] === 'life365_session'){
      return component
    } else if(process.env.REACT_APP_NODE_ENV === 'development'){
      return component
    } else {
      return <Login target="coordinator"/>
    }
  };

  const checkProviderSession = (component: JSX.Element) => {
    let decodedCookie = decodeURIComponent(document.cookie);
    const providerCookie = decodedCookie.split('; ').find(cookie=>{
      return cookie.split('=')[0] === 'life365_provider'
    });
    if(providerCookie?.split('=')[0] === 'life365_provider'){
      return component
    }else{
      return <Login target="provider"/>
    }
  };

  return (
    <div className="App">
        <ThemeProvider theme={theme}>
          <NavBar/>
          <Routes>
            <Route path="/" element={checkSession(<Main/>)} />
            <Route path="patient" element={<Login target="patient"/>} />
            <Route path="login" element={<Login target="coordinator"/>} />
            <Route path="patient/*" element={checkSession(<PatientBoard/>)} />
            <Route path="admin" element={<Admin/>} />
            <Route path="notifications" element={checkSession(<Notifications/>)} />
            <Route path="provider/" element={checkProviderSession(<Navigate to={'/provider/coordinators'}/>)} />
            <Route path="provider/*" element={checkProviderSession(<Provider/>)} />
            <Route path="information" element={<FooterLinks />} />
            <Route path="password/reset/*" element={<Password />} />
          </Routes>
          <PageFooter/>
        </ThemeProvider>
    </div>
  );
}

export default App;
