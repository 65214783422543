import React, {ChangeEvent, useEffect, useState, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';
import Button from 'components/shared/Button/Button';
import Basic from "./subviews/Basic";
import Ordering from "./subviews/Ordering";
import Branding from "./subviews/Branding";
import { useTranslation } from 'react-i18next';
import API from 'api';
import {GlobalContext} from "context/Provider";
import Loader from "components/shared/Loader/Loader";

interface loginInterface {
    name: string;
    password: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Admin(){
    const { t } = useTranslation();
    const { config, configDispatch } = useContext(GlobalContext);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [login, setlogin] = useState<loginInterface>({name: '', password: ''});
    const [tabValue, setTabValue] = useState(0);
    const navigate = useNavigate();

    const handleLogin = () => {
        API.kore.configurations.adminLogin(login.password).then(access=>{
            if(access.data){
                setIsAuthenticated(true);
            }else{
                alert('Password is incorrect, please try again.')
            }
        }).catch(err=>{
            alert('Authentication issue')
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if(event.target.id === 'name'){
            setlogin({...login, name: event.target.value})
        }else{
            setlogin({...login, password: event.target.value})
        }

    };

    const isSubmission = (event: React.KeyboardEvent<HTMLDivElement>)=>{
        if(event.key === 'Enter'){
            handleLogin()
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };


    const handleCancel = () => {
        navigate('/')
    };

    useEffect(()=>{
        API.kore.configurations.getConfigurations()
            .then((configs)=>{
                delete configs.url;
                configDispatch({type: 'OPTIONS_UPLOAD', payload: configs});
                setTimeout(()=>{
                    setContentLoaded(true);
                }, 500)
            }).catch(configErrs=>console.log(configErrs));
    }, []);

    return (
        <div>
            <Dialog open={!isAuthenticated} fullScreen>
                <DialogTitle>{t('u-admin-login')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('s-admin-login')}</DialogContentText>
                    <TextField
                        autoFocus
                        onChange={handleChange}
                        onKeyUp={isSubmission}
                        margin="dense"
                        id="name"
                        label={t('u-email-address')}
                        type="email"
                        fullWidth
                        variant="standard"
                    />
                    <TextField
                        autoFocus
                        onChange={handleChange}
                        onKeyUp={isSubmission}
                        margin="dense"
                        id="password"
                        label={t('u-password')}
                        type="password"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="primary" onClick={handleCancel}>{t('u-cancel')}</Button>
                    <Button variant="primary" onClick={handleLogin}>{t('u-login')}</Button>
                </DialogActions>
            </Dialog>

            {contentLoaded ? (<div><h1>{t('u-admin-console')}</h1>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Basic" {...a11yProps(0)} />
                        <Tab label="Ordering" {...a11yProps(1)} />
                        <Tab label="Branding" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={tabValue} index={0}>
                    <Basic/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Ordering/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Branding/>
                </TabPanel>
            </Box></div>):(<Loader/>)}
        </div>
    )
}
