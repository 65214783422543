import React from "react";
import { styled } from '@mui/material/styles';
import { Grid, FormControlLabel, TextField, Stack } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';

let BMIWidth = 6.336;

export const  StyledLeftGrid = styled(Grid)(({theme})=>({
    // [theme.breakpoints.down('md')]: {
    //     borderRight: `0px solid lightgrey`,
    // },
    // [theme.breakpoints.up('lg')]: {        
    //     borderRight: `1px solid lightgrey`,
    // },
}));

export const  StyledRightGrid = styled(Grid)(({theme})=>({
    borderTop: `1px solid lightgrey`,
}));

export const  FlagGrid = styled(Grid)(({theme})=>({
    justifyContent: 'right',
    padding: theme.spacing(0),
    marginBottom: theme.spacing(4),
    border: `1px solid lightGrey`,
}));

export const  FooterGrid = styled(Grid)(({theme})=>({
    paddingBottom: theme.spacing(10),
}));

export const  BasicDetails = styled('div')(({theme})=>({
    border: `1px solid lightGrey`,
    width: '96%',
    textAlign: 'left',
    background: theme.palette.common.background.light,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export const  UniqueDetails = styled('div')(({theme})=>({
    border: `1px solid lightGrey`,
    width: '99%',
    textAlign: 'left',
    background: theme.palette.common.background.light,   
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down('lg')]: {        
        width: '100%',
    }, 
}));

export const  FlaggedContainer = styled('div')(({theme})=>({
    border: `1px solid lightGrey`,
    width: '100%',
    background: theme.palette.common.background.green,
    paddingTop: theme.spacing(0),
}));

export const  FlaggedRadioContainer = styled('div')(({theme})=>({
    border: `1px solid lightGrey`,
    width: '100%',
    paddingTop: theme.spacing(0),
}));

export const  MeasurementHead = styled('h4')(({theme})=>({
    textAlign: 'left',
}));

export const  BoldText = styled('h4')(({theme})=>({
    textAlign: 'left',
    paddingBottom: theme.spacing(.2),
    margin: theme.spacing(0),
}));

export const  BoldDetailsText = styled('h4')(({theme})=>({
    textAlign: 'left',
    paddingBottom: theme.spacing(.2),
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0),
}));

export const  BasicText = styled('p')(({theme})=>({
    margin: theme.spacing(0),
    textAlign: 'left',
    paddingBottom: theme.spacing(.2),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

export const  StyledText = styled('p')(({theme})=>({
    margin: theme.spacing(0),
    color: theme.palette.common.text.offGrey,
}));

export const StyledFormControlLabel = styled(FormControlLabel)(({theme})=>({
    justifyContent: 'left',
    marginTop: theme.spacing(3),
    width: '97%',
    padding: theme.spacing(0),
}));

export const StyledTextField = styled(TextField)(({theme})=>({
    width: '100%',
    marginBottom: theme.spacing(5),
}));

export const StyledFlagOutlinedIcon = styled(FlagIcon)(({theme})=>({
    fontSize: theme.spacing(4),
    paddingTop: theme.spacing(1),
    color: theme.palette.common.background.white,
}));

export const IconImage = styled('img')(({theme})=>({
    width: theme.spacing(3),
}));

export const BMIContainer = styled('div')(({theme})=>({
    width: '99%',
    height: theme.spacing(25),
    borderTop: `1px solid lightGrey`,
    borderBottom: `1px solid lightGrey`,
    marginBottom: theme.spacing(7),
}));

export const BMISliderContainer = styled('div')(({theme})=>({
    height: theme.spacing(5),
}));
export const BMISliderLabel = styled('div')(({theme})=>({
    height: theme.spacing(5),
    width: '16.66%',
    textAlign: 'right',
    borderRight: `1px solid lightGrey`,
}));

export const BMISliderBarBlue = styled('div')(({theme})=>({
    height: theme.spacing(2),
    width: '11.66%',
    borderRadius: ``,
    background: theme.palette.common.barFill.blue,
}));

export const BMISliderBarGreen = styled('div')(({theme})=>({
    width: '21.76%',
    background: theme.palette.common.barFill.green,
}));

export const BMISliderBarYellow = styled('div')(({theme})=>({
    width: '16.66%',
    background: theme.palette.common.barFill.yellow,
}));

export const BMISliderBarOrange = styled('div')(({theme})=>({
    width: '16.66%',
    background: theme.palette.common.barFill.orange,
}));

export const BMISliderBarPink = styled('div')(({theme})=>({
    width: '16.66%',
    background: theme.palette.common.barFill.pink,
}));

export const BMISliderBarRed = styled('div')(({theme})=>({
    width: '16.76%',
    borderRadius: ``,
    background: theme.palette.common.barFill.red,
}));

export const BMISliderSpacer = styled('div')(({theme})=>({
    width: `${BMIWidth}%`,
    paddingRight: theme.spacing(0.4),
}));

export const BMISliderSelectorStack = styled(Stack)(({theme})=>({
    paddingLeft: theme.spacing(1),
}));

export const  BMIGrid = styled(Grid)(({theme})=>({
    marginTop: theme.spacing(2),
}));