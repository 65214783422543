import { styled } from '@mui/material/styles';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';
import { Box } from "@mui/material";

export const StyledContainer = styled('div')(({theme})=>({
    height: '100%',
    background: theme.palette.common.background.light,
    position: 'relative',
    zIndex: '2',
    width: theme.spacing(25.8),
}));

export const StyledButton = styled(Link)(({theme})=>({
    position: 'relative',
    zIndex: '2',
    color: theme.palette.common.text.offBlack,
    textDecoration: 'none',
    height: theme.spacing(3),
    width: theme.spacing(25.5),
    border: `1px solid ${theme.palette.common.checkbox.main}`,
    paddingTop: '5%',
    paddingBottom: '5%',
    fontWeight: 'bold',
    fontFamily: 'Helvetica',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    '&:hover':{
        background: theme.palette.common.background.main,
    }

}));

export const StyledMiniButton = styled(Link)(({theme})=>({
    position: 'relative',
    zIndex: '21',
    color: theme.palette.common.text.offBlack,
    textDecoration: 'none',
    height: theme.spacing(4),
    width: theme.spacing(20),
    border: `1px solid ${theme.palette.common.checkbox.main}`,
    paddingRight: theme.spacing(1),
    float: 'right',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '&:hover':{
        background: theme.palette.common.background.main,
    }
}));

export const StyledMiniHeader = styled('div')(({theme})=>({
    position: 'relative',
    zIndex: '21',
    color: 'white',
    backgroundColor: theme.palette.common.text.offBlack,
    textDecoration: 'none',
    height: theme.spacing(4.25),
    width: theme.spacing(20),
    border: `1px solid ${theme.palette.common.text.offBlack}`,
    paddingRight: theme.spacing(1),
    float: 'right',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledIcon = styled(Icon)(({theme})=>({
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontSize: theme.spacing(2.75)
}));

export const StyledHoverContainer = styled(Box)(({theme})=>({
    position: 'absolute',
    zIndex: '20',
    backgroundColor: theme.palette.common.background.light,
    display: 'flex',
    justifyContent: 'end',
    right: '0',
}));

export default { StyledButton, StyledContainer };
