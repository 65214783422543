import React, {ChangeEvent, FormEvent, useContext, useEffect, useState} from 'react';
import SimpleGrid, {BodyProps, CellProps} from "components/shared/SimpleGrid/SimpleGrid";
import Button from "components/shared/Button/Button";
import { toLocalTime } from 'helpers/variables';
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControlLabel,
    Grid,
    Stack,
    TextField,
    Tooltip, MenuItem, Select, SelectChangeEvent, Alert
} from "@mui/material";
import {usStates} from "helpers/variables";
import { orderInterface } from 'api/kore/orders'
import API from 'api';
import {GlobalContext} from "context/Provider";

interface CheckboxInterface {
    bpm: boolean;
    spo2: boolean;
    bgm: boolean;
    scale: boolean;
    therm: boolean;
    spir: boolean;
    stel: boolean;
    smart: boolean;
}

export default function Orders(){
    const { config } = useContext(GlobalContext);
    const [loading, setLoading] = useState<boolean>(false);
    const [orderOpen, setOrderOpen] = useState<boolean>(false);
    const [singleOrderOpen, setSingleOrderOpen] = useState<boolean>(false);
    const [historicalOrders, setHistoricalOrders] = useState<orderInterface[]>([]);
    const [historicalOrdersTotal, setHistoricalOrdersTotal] = useState<number>(0);
    const [showSuccess, setSuccess] = useState<string>('');
    const [isDisabled, setChanged] =useState<boolean>(true);
    const [checkboxObj, setCheckboxObj] = useState<CheckboxInterface>({
        bpm: false,
        spo2: false,
        bgm: false,
        scale: false,
        therm: false,
        spir: false,
        stel: false,
        smart: false
    });
    const [order, setOrder] = useState<orderInterface>({
        organization: 'TEST',
        delivery: 'Ground',
        issuedBy: 'Test Coordinator',
        issuedEmail: 'test@cord.com',
        sendersAddress: {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: ''
        },
        recipient: {
            name: config?.orderOptions.bulkDestination.name || '',
            address: config?.orderOptions.bulkDestination.address || '',
            city: config?.orderOptions.bulkDestination.city || '',
            state: config?.orderOptions.bulkDestination.state || '',
            zip: config?.orderOptions.bulkDestination.zip || '',
            phone: config?.orderOptions.bulkDestination.phone || ''
        },
        manifest: {}
    });

    const orderHeaders: CellProps[] = [{content: 'ID', align: 'left'}, {content: 'Ordered on', align: 'left'}, {content: 'Shipped on', align: 'left'}, {content: 'Delivered', align: 'left'}, {content: 'Organization', align: 'left'}, {content: 'Requested by', align: 'left'}, {content: 'Total items', align: 'left'}];

    const formatBody = (): BodyProps[] =>{
        return historicalOrders.map((order): BodyProps=>{
            return {cells: [{content: order._id || '', align: 'left'}, {content: toLocalTime(order.issuedOn || ''), align: 'left'}, {content: order.shippedOn || '', align: 'left'}, {content: order.fulfilled ? '√' : '' || '', align: 'left'}, {content: order.organization, align: 'left'}, {content: order.issuedBy || '', align: 'left'}, {content: `${order.manifest.totalItems}` || '0', align: 'left'}]}
        }).reverse()
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setCheckboxObj({...checkboxObj, [event.target.id]: event.target.checked})
        setSuccess('');
        setChanged(false);
    };

    const handleOrderItemChange = (event: ChangeEvent<HTMLInputElement>)=>{
        const category = event.target.name.split('_')[0];
        const name = event.target.name.split('_')[1];
        // @ts-ignore
        setOrder({...order, manifest: {...order.manifest, [category]: {...order.manifest[category], [name]: event.target.value}}})
        setSuccess('');
        setChanged(false);
    };

    const handleOrderAddressChange = (event: ChangeEvent<HTMLInputElement>)=>{
        setOrder({...order, recipient: {...order.recipient, [event.target.name]: event.target.value}})
        setSuccess('');
        setChanged(false);
    };
    const handleStateChange = (event: ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>)=>{
        setOrder({...order, recipient: {...order.recipient, state: event.target.value}})
        setSuccess('');
        setChanged(false);
    };

    const handleClose = ()=>{
        setOrderOpen(false)
    };

    const handleSubmit = ()=>{
        setLoading(true);
        setOrderOpen(false);
        setSuccess('success');
        API.kore.orders.create(order, true).then(issued=>{
            console.log(issued);
            window.location.href = '/provider/orders';
        }).catch(err=>console.log(err))
    };

    useEffect(()=>{
        API.kore.orders.getAll().then(orders=>{
            // console.log(orders)
            setHistoricalOrdersTotal(orders.length)
            paginationAction(5, 0)
        })
    }, []);

    const paginationAction = (itemsPerPage?: number, currentPage?: number)=>{
        API.kore.orders.getAll(`limit=${itemsPerPage}&page=${currentPage}`)
        .then(orders=>{
            setHistoricalOrders(orders)
        })
    };
    return (
        <div>
            <Dialog open={orderOpen} fullScreen>
                <DialogTitle>Bulk Order</DialogTitle>
                <DialogContent>
                    <DialogContentText>Please complete the form and select device quantities for your drop shipment</DialogContentText>
                    <br/>
                    <Grid container>
                        <Grid item xs={4}>
                            <Stack>
                                <span>
                                    <FormControlLabel control={<Checkbox id="bpm" onChange={handleCheckboxChange}/>} label="Blood Pressure Monitor"/>
                                    <TextField name="devices_bpm" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.bpm ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.bpm}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="spo2" onChange={handleCheckboxChange}/>} label="Pulse Oximeter"/>
                                    <TextField name="devices_pulseOx" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.spo2 ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.pulseOx}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="bgm" onChange={handleCheckboxChange}/>} label="Blood Glucose Monitor"/>
                                    <TextField name="devices_bgm" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.bgm ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.bgm}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="scale" onChange={handleCheckboxChange}/>} label="Weight Scale"/>
                                    <TextField name="devices_scale" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.scale ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.scale}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="therm" onChange={handleCheckboxChange}/>} label="Thermometer"/>
                                    <TextField name="devices_thermometer" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.therm ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.thermometer}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="spir" onChange={handleCheckboxChange}/>} label="Spirometer"/>
                                    <TextField name="devices_spirometer" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.spir ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.devices?.spirometer}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="stel" onChange={handleCheckboxChange}/>} label="Cellular Hub"/>
                                    <TextField name="hubs_stel" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.stel ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.hubs?.stel}/>
                                </span>
                                <span>
                                    <FormControlLabel control={<Checkbox id="smart" onChange={handleCheckboxChange}/>} label="Smart Hub"/>
                                    <TextField name="hubs_smart" label="Quantity" variant="outlined" type="number" sx={{ m: 1, width: '10ch', display: checkboxObj.smart ? '' : 'none' }} onChange={handleOrderItemChange} value={order.manifest.hubs?.smart}/>
                                </span>
                                <br/>
                            </Stack>
                        </Grid>
                        <Grid item xs={8}>
                            <Stack spacing={2}>
                                <TextField name="name" label="Attention to" variant="outlined" type="text" required onChange={handleOrderAddressChange} value={order.recipient.name}/>
                                <TextField name="address" label="Address" variant="outlined" type="text" required onChange={handleOrderAddressChange} value={order.recipient.address}/>
                                <TextField name="phone" label="Phone Number" variant="outlined" type="text" required onChange={handleOrderAddressChange} value={order.recipient.phone}/>
                                <TextField name="city" label="City" variant="outlined" type="text" required onChange={handleOrderAddressChange} value={order.recipient.city}/>
                                <Select name="state" label="state" sx={{height: '55px'}} defaultValue={order.recipient.state} variant="outlined" required onChange={handleStateChange}>
                                    {usStates.map(S=>{
                                        return <MenuItem value={S}>{S}</MenuItem>
                                    })}
                                </Select>
                                <TextField name="zip" label="Zip" variant="outlined" type="number" required onChange={handleOrderAddressChange} value={order.recipient.zip}/>
                                {showSuccess === 'success' ? <Alert severity="success">Your configuration changes have been saved</Alert> : ''}
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center'}}>
                    <Button variant='primary' onClick={handleClose}>Cancel</Button>
                    <Button variant='primary' onClick={handleSubmit} disabled={isDisabled}>Submit Order</Button>
                </DialogActions>
            </Dialog>

            <h3>Order History</h3>
            {loading ? <CircularProgress size={60}/> : <SimpleGrid headers={orderHeaders} body={formatBody()} paginationAction={paginationAction} totalList={historicalOrdersTotal}/>}
            <br/>
            <br/>
            <Stack direction='row' spacing={2} sx={{justifyContent: 'center'}}>
                <Tooltip title="Submit bulk orders and have inventory delivered to your organization" placement="top" arrow>
                    <div>
                    {config?.orderOptions.bulkUploads ? <Button variant='primary' onClick={()=>{setOrderOpen(true)}}>Place a Bulk Order</Button> : <></>}
                    </div>
                </Tooltip>
                <Tooltip title="Submit single order and have inventory delivered to a specific patient" placement="top" arrow>
                    <div>
                    <Button variant='primary' onClick={()=>{setSingleOrderOpen(true)}}>Place a Individual's Order</Button>
                    </div>
                </Tooltip>
            </Stack>
            <br/>
            <br/>
        </div>
    )
}
