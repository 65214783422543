interface metricsInterface {
    systolic?: number;
    diastolic?: number;
    pulse?: number;
    glucose?: number;
    units?: string;
    weight?: number;
    spo2?: number;
    temp?: number;
    type?: string;
    meal?: string;
    unit?: string;
    description?: string;
    imei?: string;
    macAddress?: string;
}

export interface ReadingContextInterface {
    id: string;
    type: string;
    metrics?: metricsInterface;
    measuredAt?: string;
    updatedAt?: string;
    value?: string;
    date?: string;
}

export const readingContext = {
    id: '',
    type: '',
    metrics: {},
    measuredAt: '',
};
